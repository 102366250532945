import {Fragment, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import {Button} from 'primereact/button';
import {ToastService, useDataTable, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {IssueService as Service} from './IssueService';
import {IssueTypeService} from './IssueTypeService';

import {IssueTypes} from './IssueTypes';

interface Props {
  me: any
  beCreate?: boolean
}

const statuses = [
  {value: '1', label: 'Mới tạo'},
  {value: '2', label: 'Đang xử lý'},
  {value: '3', label: 'Đã xử lý'},
  {value: '4', label: 'Đã từ chối'}
];

export const Issue = (props: Props) => {

  const header = 'Issue';
  const dataKey = '_id';

  const {status} = useParams();

  const [requiredParams, setRequiredParams] = useState(null);

  const [issueTypes, setIssueTypes] = useState(null);

  const [displayIssueTypes, setDisplayIssueTypes] = useState(false);

  useEffect(() => {
    if (props.beCreate) {
      create();
    }
  }, [props.beCreate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (status) {
      setRequiredParams({status: {value: status.split(','), matchMode: 'in'}});
    } else {
      setRequiredParams({});
    }
  }, [status]);

  useEffect(() => {
    IssueTypeService.getList('{}').then(data => {
      setIssueTypes(data.listData.map(item => {
        return {value: item._id, label: item.name}
      }));
    })
  }, [displayIssueTypes]);

  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    dataKey,
    columns: [
      {field: 'pseudoId', header: `Mã ${header}`, width: 150, matchMode: 'contains'},
      {field: 'status', header: 'Trạng thái', width: 150, matchMode: 'equals', filterType: 'dropdown', filterOptions: statuses, dataType: 'fromFilterOptions'},
      {field: 'issueType', header: `Loại ${header}`, width: 180, matchMode: 'equals', filterType: 'dropdown', filterOptions: issueTypes, dataType: 'fromFilterOptions'},
      {field: 'description', header: 'Nội dung', minWidth: 250, matchMode: 'contains'},
      {field: 'by', header: 'Người yêu cầu', width: 200, matchMode: 'contains'},
      {
        field: 'files', header: 'Files đính kèm', width: 200, dataType: 'custom', customCell(rowData: any): any {
          if (rowData.files) {
            return <div>
              {rowData.files.map((item, index) => <p key={index} className="mb-0">
                <a href={item.value} download={item.name}>{item.name}</a>
              </p>)}
            </div>;
          }
          return '';
        }
      }
    ],
    indexColumnWidth: 45,
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    },
    requiredParams
  });

  const {render: renderDialogCrup, create, update} = useDialogCrup({
    header,
    dataKey,
    width: '50rem',
    fields: [
      {field: 'pseudoId', header: `Mã ${header}`, required: true, className: 'md:col-12'},
      {field: 'issueType', header: `Loại ${header}`, required: true, type: 'Dropdown', DropdownProps: {options: issueTypes}, className: 'md:col-12'},
      {field: 'description', header: 'Nội dung', required: true, type: 'InputTextarea', className: 'md:col-12'},
      {field: 'by', header: 'Người yêu cầu', className: 'md:col-12'},
      {field: 'files', header: 'Files đính kèm', type: 'file', InputFileProps: {multiple: true}, className: 'md:col-12'}
    ],
    createItem: item => {
      const complete = [];
      if (item.files) {
        for (const file of item.files) {
          complete.push(new Promise<any>(resolve => {
            const reader = new FileReader();
            reader.onloadend = () => {
              resolve({name: file.name, value: reader.result});
            }
            reader.readAsDataURL(file);
          }));
        }
      }
      return Promise.all(complete).then((files) => {
        item.status = '1';
        item.files = files;
        return Service.create(item);
      });
    },
    updateItem: (id, item) => {
      const complete = [];
      if (item.files) {
        for (const file of item.files) {
          complete.push(new Promise<any>(resolve => {
            const reader = new FileReader();
            reader.onloadend = () => {
              resolve({name: file.name, value: reader.result});
            }
            reader.readAsDataURL(file);
          }));
        }
      }
      return Promise.all(complete).then((files) => {
        item.files = files;
        return Service.update(id, item);
      });
    },
    reloadLazyData
  });

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const doUpdate = () => {
    if (selectedItem && selectedItem[dataKey]) {
      update(selectedItem);
    }
  }

  const changeStatus = toStatus => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.update(selectedItem[dataKey], {status: toStatus}).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    }
  }

  const {renderToolbar} = useToolbar({
    doCreate: create,
    hasSelectedItem: selectedItem,
    doUpdate,
    leftButtons: <Fragment>
      <Button label="Loại Issue" icon="pi pi-tag" severity="help" size="small" onClick={() => setDisplayIssueTypes(true)}/>
      <Button label="Đang xử lý" icon="pi pi-hourglass" severity="success" size="small" onClick={() => changeStatus('2')} disabled={!selectedItem}/>
      <Button label="Đã xử lý" icon="pi pi-check" severity="info" size="small" onClick={() => changeStatus('3')} disabled={!selectedItem}/>
      <Button label="Từ chối" icon="pi pi-times" severity="danger" size="small" onClick={() => changeStatus('4')} disabled={!selectedItem}/>
    </Fragment>
  });

  return (
    <div className="grid">
      <div className="col-12">
        {renderToolbar()}
      </div>
      <div className="col-12 py-0" style={{maxHeight: 'calc(100vh - 29rem)'}}>
        {renderDataTable()}
      </div>

      {renderDialogCrup()}

      <IssueTypes display={displayIssueTypes} setDisplay={setDisplayIssueTypes}/>
    </div>
  );
}