import { useDataTable} from '@iamsoftware/react-hooks';

import {ArtifactHitService as Service} from './ArtifactHitService';

export const LogTruyCap = () => {
  const statuses = [
    {value: 'Y', label: 'Thất bại'},
    {value: 'N', label: 'Thành công'},
  ];

  const {render: renderDataTable} = useDataTable({
    columns: [
      {field: 'actionType', header: 'Thao tác', width: 120, matchMode: 'contains'},
      {field: 'username', header: 'Tài khoản', width: 150, matchMode: 'contains'},
      {field: 'startDateTime', header: 'Ngày giờ', width: 200, dataType: 'date-time'},
      {field: 'userId', header: 'Tài khoản', width: 200, matchMode: 'contains'},
      {
        field: 'wasError', header: 'Trạng thái truy cập', width: 200, matchMode: 'equals', filterType: 'dropdown', filterOptions: statuses, dataType: 'custom', customCell(rowData: any): any {
          let access;
          if (rowData.wasError === 'N') {
            access = <p>
              <i className="true-icon pi pi-check-circle mr-1"></i>
              <span>Thành công</span>
            </p>
          } else {
            access = <p>
              <i className="false-icon pi pi-times-circle mr-1"></i>
              <span>Thất bại</span>
            </p>
          }
          return <div>
            {access}
          </div>
        }
      },

    ],
    indexColumnWidth: 45,
    getList: lazyLoadEvent => {
      return Service.getListAccess(lazyLoadEvent);
    }
  });

  return (
    <div className="grid">
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 9rem)'}}>
        {renderDataTable()}
      </div>
    </div>
  );
}