import {EntityService} from '../../../service/EntityService';

export class ProfileService extends EntityService {

  static entity = 'vinca-cert/profiles/requests';

  static getDetail(partyCertId: string, requestId: string): Promise<any> {
    return this.axios.get(`${this.entity}/${requestId}/detail`, {params: {partyCertId}}).then(res => res.data);
  }

  static getContentTypeEnums(requestId: string): Promise<any> {
    return this.axios.get(`${this.entity}/${requestId}/content-types`).then(res => res.data);
  }

  static updateInfo(requestId: string, data: any): Promise<any> {
    return this.axios.put(`${this.entity}/${requestId}/update-info`, data).then(res => res.data);
  }

  static uploadContent(requestId: string, data: any): Promise<any> {
    return this.axios.post(`${this.entity}/${requestId}/upload`, data).then(res => res.data);
  }

  static updateContentReceivedDate(requestId: string, requestContentId: string, receivedDate: any): Promise<any> {
    return this.axios.put(`${this.entity}/${requestId}/contents/${requestContentId}/received`, {receivedDate}).then(res => res.data);
  }

  static deleteContent(requestId: string, requestContentId: string): Promise<any> {
    return this.axios.delete(`${this.entity}/${requestId}/contents/${requestContentId}/delete`).then(res => res.data);
  }

  static completeDocument(requestId: string, documentCompletedDate: any): Promise<any> {
    return this.axios.put(`${this.entity}/${requestId}/complete-document`, {documentCompletedDate}).then(res => res.data);
  }

  static incompleteDocument(requestId: string): Promise<any> {
    return this.axios.put(`${this.entity}/${requestId}/incomplete-document`).then(res => res.data);
  }

  static receivedDocument(requestId: string): Promise<any> {
    return this.axios.put(`${this.entity}/${requestId}/received-document`).then(res => res.data);
  }

  static unreceivedDocument(requestId: string): Promise<any> {
    return this.axios.put(`${this.entity}/${requestId}/unreceived-document`).then(res => res.data);
  }

  static sentCertAuthority(requestId: string): Promise<any> {
    return this.axios.put(`${this.entity}/${requestId}/sent-cert-authority`).then(res => res.data);
  }

  static unsentCertAuthority(requestId: string): Promise<any> {
    return this.axios.put(`${this.entity}/${requestId}/unsent-cert-authority`).then(res => res.data);
  }
  static updateNote(requestId: string, data:any): Promise<any> {
    return this.axios.put(`${this.entity}/${requestId}/note/update-note`, data).then(res => res.data);
  }
  static getNoteHistory(requestId: string): Promise<any> {
    return this.axios.get(`${this.entity}/${requestId}/note/list-note`).then(res => res.data);
  }
  static getProfileList(requestId: string, partyCertId: string): Promise<any> {
    return this.axios.get(`vinca-cert/profiles/requests/${requestId}/contents/popReContent`, {params:{partyCertId}}).then(res => res.data);
  }

}
