import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {ToastService, useDataTable, useToolbar} from '@iamsoftware/react-hooks';

import {CategoryService as Service} from './CategoryService';

import {AddProducts} from './AddProducts';

export const Products = ({display, setDisplay}) => {

  const header = 'Sản phẩm';
  const dataKey = 'productId';

  const [submitting, setSubmitting] = useState(false);

  const [displayAddProducts, setDisplayAddProducts] = useState(null);

  useEffect(() => {
    if (display) {
      reloadLazyData();
    }
  }, [display]); // eslint-disable-line react-hooks/exhaustive-deps

  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    dataKey,
    columns: [
      {field: 'ownerPartyId', header: 'Thuộc sở hữu', width: 150, matchMode: 'contains'},
      {field: 'pseudoId', header: `Mã ${header}`, width: 250, matchMode: 'contains'},
      {field: 'productName', header: `Tên ${header}`, minWidth: 150, matchMode: 'contains'},
      {field: 'mainCategoryName', header: 'Loại', minWidth: 250, matchMode: 'contains'}
    ],
    indexColumnWidth: 45,
    getList: lazyLoadEvent => {
      return new Promise<any>((resolve, reject) => {
        if (display) {
          resolve(display);
        } else {
          reject();
        }
      }).then(productCategoryId => Service.getProductsInCategory(productCategoryId, lazyLoadEvent));
    }
  });

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const doDelete = () => {
    if (selectedItem && selectedItem[dataKey] && !submitting) {
      setSubmitting(true);

      Service.removeProductsFromCategory(display, [selectedItem[dataKey]]).then(({messages}) => {
        ToastService.success(messages);
        reloadLazyData();
      }).finally(() => {
        setSubmitting(false);
      });
    }
  }

  const {renderToolbar} = useToolbar({
    doCreate() {
      setDisplayAddProducts(display);
    },
    hasSelectedItem: selectedItem,
    doDelete
  });

  const footer = (
    <div>
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(false)}/>
    </div>
  );

  return (
    <Fragment>
      <Dialog header={`Danh sách ${header}`} footer={footer} visible={!!display} style={{width: '80vw'}} position="top" onHide={() => setDisplay(false)}>
        <div className="grid">
          <div className="col-12">
            {renderToolbar()}
          </div>
          <div className="col-12" style={{maxHeight: 'calc(100vh - 20rem)'}}>
            {renderDataTable()}
          </div>
        </div>
      </Dialog>
      <AddProducts display={displayAddProducts} setDisplay={setDisplayAddProducts} reloadProducts={reloadLazyData}/>
    </Fragment>
  );
}