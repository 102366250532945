import {useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {ToastService, useDataTable} from '@iamsoftware/react-hooks';

import {InvoiceService as Service} from "../InvoiceService";

export const useCrupItemProducts = ({doCreateItem}) => {

  const [display, setDisplay] = useState(null);

  useEffect(() => {
    if (display) {
      reloadLazyData();
    }
  }, [display]); // eslint-disable-line react-hooks/exhaustive-deps

  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    tableHeader: 'Đơn hàng bán',
    dataKey: 'productId',
    columns: [
      {field: 'pseudoId', header: 'Mã sản phẩm', width: 170, matchMode: 'contains'},
      {field: 'productName', header: 'Tên sản phẩm', minWidth: 120, matchMode: 'contains'},
      {field: 'price', header: 'Giá', width: 140, matchMode: 'contains', dataType: 'number'},
      {field: 'amountValue', header: 'Số lượng', width: 140, matchMode: 'contains'},
      {
        field: 'periodNum', header: 'Thời hạn', width: 180, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          let amountUomName = "";
          if (rowData.periodNum) {
            for (let amountUom of amountUoms) {
              if (amountUom.value === rowData.periodUomId) {
                amountUomName = amountUom.label;
              }
            }
            return `${rowData.periodNum} ${amountUomName}`;
          }
        }
      },
    ],
    getList: lazyLoadEvent => {
      if (display) {
        return Service.getProducts(lazyLoadEvent);
      } else {
        return Promise.resolve({});
      }
    },
    selectionMode: 'checkbox'
  });

  const doPlan = () => {
    setDisplay(Date.now());
  }

  const onSubmit = event => {
    if (selectedItems?.length) {
      if(selectedItems.length ===1){
        doCreateItem(selectedItems);
        setDisplay(null);
      } else {
        ToastService.error('Chỉ được chọn 1 gói dịch vụ.');
      }
    } else {
      ToastService.error('Chưa chọn gói dịch vụ.');
    }
    event.preventDefault();
  }

  const footer = (
    <div className="flex justify-content-center">
      <Button label="Xác nhận" icon="pi pi-arrow-right" size="small" onClick={onSubmit}/>
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(null)}/>
    </div>
  );

  const renderPurchasePlanning = () => {
    return (
      <Dialog header="Chọn gói dịch vụ" footer={footer} visible={!!display} style={{width: '70rem'}} position="top" onHide={() => setDisplay(null)}>
        <div className="grid pt-2">
          {renderDataTable()}
        </div>
      </Dialog>
    );
  }

  return {renderPurchasePlanning, doPlan};
}
const amountUoms = [
  {value: "TF_yr", label: "Năm"},
  {value: "TF_mon", label: "Tháng"}
]