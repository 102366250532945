import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {Slider} from 'primereact/slider';
import {TabView, TabPanel} from 'primereact/tabview';
import {FormatDisplay, ToastService, useDataTable, useFileUploader, useForm} from '@iamsoftware/react-hooks';

import {CertificationService} from './CertificationService';
import {AgentService} from '../../dai-ly/danh-sach/AgentService';
import {CustomerService} from '../../khach-hang/CustomerService';
import {PartyService} from '../../../service/PartyService';
import {CommonService} from '../../../service/CommonService';

import {SelectCert} from './SelectCert';
import {useDialogCrupCustomer} from '../../khach-hang/danh-sach';

export const Crup = ({me, display, setDisplay, selectedItem, readOnly, accept, reject, reloadLazyData}) => {
  const [submitting, setSubmitting] = useState(null);
  const onSubmit = send => event => {
    if (!submitting) {
      setSubmitting(send);
    }
    event.preventDefault();
  }

  const footer = (
    <div className="text-center">
      {(me?.roleTypeId === 'Administrator' || me?.partyRank <= 1) && readOnly && ['ReqSubmitted'].includes(selectedItem?.statusId) && <Fragment>
				<Button label="Chấp nhận" icon="pi pi-check" severity="info" size="small" onClick={accept}/>
				<Button label="Từ chối" icon="pi pi-times" severity="warning" size="small" onClick={() => reject(selectedItem)}/>
			</Fragment>}
      {(!readOnly && !selectedItem?.requestId) && <Button label="Lưu và gửi" icon="pi pi-check" severity="success" size="small" onClick={onSubmit('Y')} loading={submitting}/>}
      {!readOnly && <Button label="Lưu" icon="pi pi-check" size="small" onClick={onSubmit('N')} loading={submitting}/>}
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(false)}/>
    </div>
  );

  return (
    <Dialog header="Yêu cầu chứng thư số" footer={footer} visible={display} maximized={true} draggable={false} onHide={() => setDisplay(false)}>
      <Screen display={display} setDisplay={setDisplay} selectedItem={selectedItem} readOnly={readOnly} reloadLazyData={reloadLazyData}
              submitting={submitting} setSubmitting={setSubmitting}/>
    </Dialog>
  );
}

const Screen = ({display, setDisplay, selectedItem, readOnly, reloadLazyData, submitting, setSubmitting}) => {
  const [parties, setParties] = useState([]);

  const [classifications, setClassifications] = useState([]);
  const [identifyTypes, setIdentifyTypes] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [counties, setCounties] = useState([]);

  const [orgIdentifyTypes, setOrgIdentifyTypes] = useState([]);

  const [relatedIdentifyTypes, setRelatedIdentifyTypes] = useState([]);

  const [categories, setCategories] = useState([]);
  const [requestTypes, setRequestTypes] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [products, setProducts] = useState([]);
  const [productFeatures, setProductFeatures] = useState([]);

  const [parents, setParents] = useState([]);

  const [idValueMaxLength, setIdValueMaxLength] = useState(20);
  const [idValueKeyFilter, setIdValueKeyFilter] = useState('alphanum');

  const [previewPdfSrc, setPreviewPdfSrc] = useState(null);
  const [previewImgSrc, setPreviewImgSrc] = useState(null);
  const [previewImgWidth, setPreviewImgWidth] = useState(null);
  const [previewImgMaxWidth, setPreviewImgMaxWidth] = useState(null);

  const [partyIdContents, setPartyIdContents] = useState(null);
  const [relatedIdContents, setRelatedIdContents] = useState(null);
  const [dk01Contents, setDk01Contents] = useState(null);
  // const [dk02Contents, setDk02Contents] = useState(null);
  const [dk03Contents, setDk03Contents] = useState(null);
  const [idValue, setIdValue] = useState([])

  const [displaySelectCert, setDisplaySelectCert] = useState(null);

  useEffect(() => {
    CommonService.getDigitalCertClassifications().then(data => {
      setClassifications(data.listData.map(item => {
        return {value: item.partyClassificationId, label: item.description}
      }));
    });
    CommonService.getProvinces().then(data => {
      setProvinces(data.listData.map(item => {
        return {value: item.toGeoId, label: item.geoName}
      }));
    });

    CommonService.getIdentifyTypes('PcltCertEnterprise').then(data => {
      setOrgIdentifyTypes(data.listData.map(item => {
        return {value: item.enumId, label: item.description}
      }));
    });

    CertificationService.getCategories().then(data => {
      setCategories(data.listData.map(item => {
        return {value: item.productCategoryId, label: item.categoryName}
      }));
    });
    CertificationService.getRequestTypes().then(data => {
      setRequestTypes(data.listData.map(item => {
        return {value: item.enumId, label: item.description}
      }));
    });
    CertificationService.getVendors().then(data => {
      setVendors(data.listData.map(item => {
        return {value: item.fromPartyId, label: item.pseudoId}
      }));
    });
    CertificationService.getFeatures().then(data => {
      setProductFeatures(data.listData.map(item => {
        return {value: item.productFeatureId, label: item.description}
      }));
    });

    AgentService.getTreeList('N').then(data => {
      const _parents = AgentService.dataTreeTableToTreeSelect(data.orgTree, data => {
        return `[${data.pseudoId}] - ${data.partyName}`;
      });
      setParents(_parents);
    });
  }, []);

  const clear = () => {
    setPreviewPdfSrc(null);
    setPreviewImgSrc(null);
    setPartyIdContents(null);
    setRelatedIdContents(null);
    setDk01Contents(null);
    // setDk02Contents(null);
    setDk03Contents(null);
  }
  useEffect(() => {
    const body = document.getElementsByTagName('BODY')[0];
    if (display) {
      body.classList.add('has-dialog-maximized');
    } else {
      body.classList.remove('has-dialog-maximized');
    }
  }, [display]);

  useEffect(() => {
    if (display && vendors?.length && parents?.length) {
      clear();
      reset();
      if (selectedItem) {
        if (selectedItem.partyIdContents && selectedItem.partyIdContents.length) {
          const _partyIdContents = [];
          for (const content of selectedItem.partyIdContents) {
            _partyIdContents.push({
              key: content.requestContentId,
              contentType: content.contentType,
              fileName: content.fileName,
              src: `${CertificationService.baseURL}/${CertificationService.entity}/${selectedItem.requestId}/contents/${content.requestContentId}/download`
            });
          }
          setPartyIdContents(_partyIdContents);
        }
        if (selectedItem.relatedIdContents && selectedItem.relatedIdContents.length) {
          const _relatedIdContents = [];
          for (const content of selectedItem.relatedIdContents) {
            _relatedIdContents.push({
              key: content.requestContentId,
              contentType: content.contentType,
              fileName: content.fileName,
              src: `${CertificationService.baseURL}/${CertificationService.entity}/${selectedItem.requestId}/contents/${content.requestContentId}/download`
            });
          }
          setRelatedIdContents(_relatedIdContents);
        }
        if (selectedItem.dk01Contents && selectedItem.dk01Contents.length) {
          const _dk01Contents = [];
          for (const content of selectedItem.dk01Contents) {
            _dk01Contents.push({
              key: content.requestContentId,
              contentType: content.contentType,
              fileName: content.fileName,
              src: `${CertificationService.baseURL}/${CertificationService.entity}/${selectedItem.requestId}/contents/${content.requestContentId}/download`
            });
          }
          setDk01Contents(_dk01Contents);
        }
        // if (selectedItem.dk02Contents && selectedItem.dk02Contents.length) {
        //   const _dk02Contents = [];
        //   for (const content of selectedItem.dk02Contents) {
        //     _dk02Contents.push({
        //       key: content.requestContentId,
        //       contentType: content.contentType,
        //       fileName: content.fileName,
        //       src: `${CertificationsService.baseURL}/${CertificationsService.entity}/${selectedItem.requestId}/contents/${content.requestContentId}/download`
        //     });
        //   }
        //   setDk02Contents(_dk02Contents);
        // }

        if (selectedItem.dk03Contents && selectedItem.dk03Contents.length) {
          const _dk03Contents = [];
          for (const content of selectedItem.dk03Contents) {
            _dk03Contents.push({
              key: content.requestContentId,
              contentType: content.contentType,
              fileName: content.fileName,
              src: `${CertificationService.baseURL}/${CertificationService.entity}/${selectedItem.requestId}/contents/${content.requestContentId}/download`
            });
          }
          setDk03Contents(_dk03Contents);
        }
        selectedItem.fromPartyId = selectedItem.vendorPartyId;
        selectedItem.partyId = {value: selectedItem.partyId, label: selectedItem.partyName};

        if (selectedItem.productId && selectedItem.productName) {
          setProducts([{value: selectedItem.productId, label: selectedItem.productName}])
        }
        if (selectedItem.relatedIdDate) {
          selectedItem.relatedIdDate = FormatDisplay.date(selectedItem.relatedIdDate, 'YYYY-MM-DD')
        }
        setValue(selectedItem);
      } else {
        const defaultValue = {
          // partyClassificationId: 'PcltCertEnterprise',
          partyIdTypeEnumId: 'PtidTaxIdent',
          otherPartyIdTypeEnumId: 'PtidTaxIdent',
          productCategoryId: 'VinCertToken',
          requestTypeEnumId: 'CrqtNew',
          fromPartyId: vendors[0].value,
          agentPartyId: parents[0]?.key,
          productFeatureId: productFeatures[0].value
          // productFeatureId: 'PARTNER_HARD_TOKEN'
        };
        setValue(defaultValue);
      }
    }
  }, [display, selectedItem, vendors, parents]); // eslint-disable-line react-hooks/exhaustive-deps

  const onFileSelect = async file => {
    let src;
    if (file.key) {
      if (file.contentType === 'application/pdf') {
        src = file.src;
        setPreviewPdfSrc(src);
        setPreviewImgSrc(null);
      } else {
        const blob = await fetch(file.src).then(r => r.blob());
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          src = reader.result;
          setPreviewPdfSrc(null);
          setPreviewImgSrc(src.replace('data:application/octet-stream;', 'data:image/png;'));
        }
      }
    } else {
      src = window.URL.createObjectURL(new Blob([file], {type: file.type}));
      if (file.type === 'application/pdf') {
        setPreviewPdfSrc(src);
        setPreviewImgSrc(null);
      } else {
        setPreviewPdfSrc(null);
        setPreviewImgSrc(src);
      }
    }
  }

  const onPreviewImgLoad = e => {
    setPreviewImgWidth(Math.min(500, e.target.naturalWidth));
    setPreviewImgMaxWidth(e.target.naturalWidth);
  }

  const {renderFileUploader: renderPartyIdContents, getFiles: getPartyIdContents, deletedExistingFiles: deletedPartyIdContents} = useFileUploader({
    onSelect: onFileSelect,
    existingFiles: partyIdContents,
    disabled: readOnly
  });
  const {renderFileUploader: renderDk01Contents, getFiles: getDk01Contents, deletedExistingFiles: deletedDk01Contents} = useFileUploader({
    onSelect: onFileSelect,
    existingFiles: dk01Contents,
    disabled: readOnly
  });
  // const {renderFileUploader: renderDk02Contents, getFiles: getDk02Contents, deletedExistingFiles: deletedDk02Contents} = useFileUploader({
  //   onSelect: onFileSelect,
  //   existingFiles: dk02Contents,
  //   disabled: readOnly
  // });
  const {renderFileUploader: renderDk03Contents, getFiles: getDk03Contents, deletedExistingFiles: deletedDk03Contents} = useFileUploader({
    onSelect: onFileSelect,
    existingFiles: dk03Contents,
    disabled: readOnly
  });
  const {renderFileUploader: renderRelatedIdContents, getFiles: getRelatedIdContents, deletedExistingFiles: deletedRelatedIdContents} = useFileUploader({
    onSelect: onFileSelect,
    existingFiles: relatedIdContents,
    disabled: readOnly
  });

  const {renderDialogCrup: renderDialogCrupCustomer, doCreate} = useDialogCrupCustomer({
    reloadLazyData: item => {

      setDirty(prevDirty => {
        prevDirty['partyId'] = true;
        return prevDirty;
      });

      const _value = getRawValue();
      _value.partyId = {value: item.partyId};
      setValue(_value);
    }
  });

  const searchParties = event => {
    CustomerService.find(event.query).then(data => {
      setParties(data.listData.map(item => {
        return {value: item.partyId, label: `[${item.pseudoId}] - ${item.partyName}`};
      }));
    });
  }

  const showSelectCert = () => {
    setDisplaySelectCert(getRawValue());
  }
  const setCert = cert => {
    if (cert?.certificateSn) {
      const _value = getRawValue();
      _value.partyCertId = cert.partyCertId;
      _value.certificateSn = cert.certificateSn;
      _value.originCertificateId = cert.originCertificateId;
      _value.originRequestTypeEnumId = cert.originRequestTypeEnumId || 'CrqtNew';
      _value.productId = cert.productId;
      _value.fromPartyId = cert.fromPartyId
      setValue(_value);
    }
  }

  const getAddress1 = () => {
    const _value = getRawValue();
    if (_value.idValue) {
      PartyService.getByTaxCode(_value.idValue, 'N').then(({data}) => {
        if (data?.partyName) {
          _value.address1 = data.address1;
          setValue(_value);
        }
      });
    }
  }
  let requestId = null
  useEffect(() => {
    if (display && selectedItem) {
      load();
    }
  }, [display, selectedItem]); // eslint-disable-line react-hooks/exhaustive-deps
  const load = () => {
    requestId = selectedItem.requestId;
    CertificationService.accountActiveCTS(selectedItem.requestId).then(data => {
      activeForm.setValue(data);
    });
  }
  const activeForm = useForm({
    fields: [
      {field: 'idValue', header: 'MST/CCCD/CMND/HC', className: 'md:col-3'},
      {field: 'certCode', header: 'Mã cấp CTS', className: 'md:col-3'},
      {field: 'certificateSn', header: 'certSerial', className: 'md:col-3'},
      {field: 'snToken', header: 'snToken', className: 'md:col-3'},
    ],
    readOnly
  })

  const histories = useDataTable({
    columns: [
      {field: 'changedDate', header: 'Ngày thay đổi', width: 150, dataType: 'date-time'},
      {field: 'changedByUserName', header: 'Người thay đổi', width: 150},
      {field: 'newValue', header: 'Trạng thái', minWidth: 150},
      {field: 'changeReason', header: 'Lý do', minWidth: 150}
    ],
    getList: lazyLoadEvent => {
      return CertificationService.getStatusHistories(requestId)
    }
  });

  const {render: renderForm, reset, setValue, valid: formValid, getValue, getRawValue, dirty, setDirty} = useForm({
    fields: [
      {field: 'partyCertId', type: 'hidden'},
      {field: 'originCertificateId', type: 'hidden'},
      {field: 'lastUpdatedStamp', type: 'hidden'},
      {field: 'requestTypeEnumId', header: 'Loại giao dịch', required: true, type: 'Dropdown', DropdownProps: {options: requestTypes}, className: 'md:col-12', labelClassName: 'md:col-4 justify-content-end', inputClassName: 'md:col-8'},
      {type: 'custom', className: 'md:col-12 mb-3', body: <b>Thông tin khách hàng</b>},
      {
        field: 'partyId', header: 'Khách hàng', required: true, type: 'AutoComplete',
        AutoCompleteProps: {suggestions: parties, completeMethod: searchParties, field: 'label', placeholder: 'Tìm theo MST, CCCD, Mã, Tên khách hàng', dropdown: true, forceSelection: true},
        className: 'md:col-11', labelClassName: 'md:col-4 justify-content-end pr-0', inputClassName: 'md:col-8 pl-6'
      },
      {
        field: 'getByTaxCode', type: 'custom', body: <Button icon="pi pi-plus" rounded severity="success" size="small" onClick={doCreate}/>, displayDependency(): boolean {
          return !readOnly;
        }, className: 'md:col-1 pl-0'
      },
      // {
      //   field: 'partyTaxId', header: 'Mã số thuế', required: true, displayDependency(item: any): boolean {
      //     return item?.partyClassificationId === 'PcltCertEnterprise';
      //   }, InputTextProps: {maxLength: 15, keyfilter: 'int'}, className: 'md:col-12', labelClassName: 'md:col-4 justify-content-end', inputClassName: 'md:col-8'
      // },
      {
        field: 'partyIdTypeEnumId', required: true, displayDependency(item: any): boolean {
          return ['PcltCertPersonalEnterprise', 'PcltCertPersonal', 'PcltCertEnterprise'].includes(item?.partyClassificationId) && ['CrqtNew', 'CrqtExtension', 'CrqtInfoChange', 'CrqtSuspend', 'CrqtRecovery', 'CrqtRevoke', 'CrqtReissue'].includes(item?.requestTypeEnumId);
        }, type: 'Dropdown', DropdownProps: {options: identifyTypes}, className: 'md:col-4', labelClassName: 'md:col-0', inputClassName: 'md:col-12'
      },

      {
        field: 'idValue', required: true, displayDependency(item: any): boolean {
          return ['PcltCertPersonalEnterprise', 'PcltCertPersonal', 'PcltCertEnterprise'].includes(item?.partyClassificationId) && ['CrqtNew', 'CrqtExtension', 'CrqtInfoChange', 'CrqtSuspend', 'CrqtRecovery', 'CrqtRevoke', 'CrqtReissue'].includes(item?.requestTypeEnumId);
        }, InputTextProps: {maxLength: idValueMaxLength}, className: 'md:col-7', labelClassName: 'md:col-0', inputClassName: 'md:col-12'
      },
      // {
      //   field: 'otherIdValue', header: 'Mã số thuế', required: true, disabled: true, displayDependency(item: any): boolean {
      //     return ['PcltCertPersonalEnterprise', 'PcltCertPersonal', 'PcltCertEnterprise'].includes(item?.partyClassificationId) && ['CrqtSuspend', 'CrqtRecovery', 'CrqtRevoke', 'CrqtReissue'].includes(item?.requestTypeEnumId);
      //   }, InputTextProps: {maxLength: idValueMaxLength}, className: 'md:col-12', labelClassName: 'md:col-4 justify-content-end', inputClassName: 'md:col-8'
      // },
      {
        field: 'partyName', header: 'Tên thuê bao', disabledDependency(item: any): boolean {
          return !(item.partyId?.value && !['CrqtSuspend', 'CrqtRecovery', 'CrqtRevoke', 'CrqtReissue'].includes(item?.requestTypeEnumId));
        }, required: true, type: 'InputTextarea', className: 'md:col-12', labelClassName: 'md:col-4 justify-content-end', inputClassName: 'md:col-8'
      },
      {
        field: 'stateProvinceGeoId', header: 'Tỉnh/Thành phố', disabledDependency(item: any): boolean {
          return !item.partyId?.value;
        }, displayDependency(item: any): boolean {
          return ['CrqtNew', 'CrqtExtension', 'CrqtInfoChange'].includes(item?.requestTypeEnumId);
        }, required: true, type: 'Dropdown', DropdownProps: {options: provinces, filter: true}, className: 'md:col-12', labelClassName: 'md:col-4 justify-content-end', inputClassName: 'md:col-8'
      },
      {
        field: 'countyGeoId', header: 'Quận/Huyện', disabledDependency(item: any): boolean {
          return !item.partyId?.value;
        }, displayDependency(item: any): boolean {
          return ['CrqtNew', 'CrqtExtension', 'CrqtInfoChange'].includes(item?.requestTypeEnumId);
        }, required: true, type: 'Dropdown', DropdownProps: {options: counties, filter: true}, className: 'md:col-12', labelClassName: 'md:col-4 justify-content-end', inputClassName: 'md:col-8'
      },
      {
        field: 'address1', required: true,  header: 'Địa chỉ', disabledDependency(item: any): boolean {
          return !item.partyId?.value;
        }, displayDependency(item: any): boolean {
          return ['CrqtNew', 'CrqtExtension', 'CrqtInfoChange'].includes(item?.requestTypeEnumId);
        }, className: 'md:col-11', labelClassName: 'md:col-4 justify-content-end pr-0', inputClassName: 'md:col-8 pl-6'
      },
      {
        field: 'getAddress1', type: 'custom', body: <Button icon="pi pi-refresh" rounded severity="success" size="small" onClick={getAddress1}/>, displayDependency(item: any): boolean {
          return ['CrqtNew', 'CrqtExtension', 'CrqtInfoChange'].includes(item?.requestTypeEnumId);
        }, className: 'md:col-1 pl-0'
      },
      {
        field: 'emailAddress', header: 'Email', disabledDependency(item: any): boolean {
          return !item.partyId?.value;
        }, displayDependency(item: any): boolean {
          return ['CrqtNew', 'CrqtExtension', 'CrqtInfoChange'].includes(item?.requestTypeEnumId);
        }, required: true, InputTextProps: {maxLength: 50}, className: 'md:col-12', labelClassName: 'md:col-4 justify-content-end', inputClassName: 'md:col-8'
      },
      {
        field: 'phoneNumber', header: 'Số điện thoại', disabledDependency(item: any): boolean {
          return !item.partyId?.value;
        }, displayDependency(item: any): boolean {
          return ['CrqtNew', 'CrqtExtension', 'CrqtInfoChange'].includes(item?.requestTypeEnumId);
        }, required: true, InputTextProps: {maxLength: 17, keyfilter: 'int'}, className: 'md:col-12', labelClassName: 'md:col-4 justify-content-end', inputClassName: 'md:col-8'
      },
      {
        field: 'jobTitle', header: 'Chức vụ', displayDependency(item: any): boolean {
          return item?.partyClassificationId === 'PcltCertPersonalEnterprise' && ['CrqtNew', 'CrqtExtension'].includes(item?.requestTypeEnumId);
        }, className: 'md:col-12', labelClassName: 'md:col-4 justify-content-end', inputClassName: 'md:col-8'
      },
      {
        field: 'departmentName', header: 'Phòng ban', displayDependency(item: any): boolean {
          return item?.partyClassificationId === 'PcltCertPersonalEnterprise' && ['CrqtNew', 'CrqtExtension'].includes(item?.requestTypeEnumId);
        }, className: 'md:col-12', labelClassName: 'md:col-4 justify-content-end', inputClassName: 'md:col-8'
      },
      {
        field: 'partyIdContents', header: 'Ảnh giấy phép', displayDependency(item: any): boolean {
          return ['CrqtNew', 'CrqtExtension'].includes(item?.requestTypeEnumId);
        }, type: 'FileUploader', FileUploaderProps: {render: renderPartyIdContents}, className: 'md:col-12', labelClassName: 'md:col-4 justify-content-end', inputClassName: 'md:col-8'
      },

      {
        type: 'custom', className: 'md:col-12 mb-3', body: <b>Thông tin tổ chức doanh nghiệp</b>, displayDependency(item: any): boolean {
          return item?.partyClassificationId === 'PcltCertPersonalEnterprise' && ['CrqtNew', 'CrqtExtension'].includes(item?.requestTypeEnumId);
        }
      },
      {
        field: 'relatedPartyName', header: 'Tên TCDN', displayDependency(item: any): boolean {
          return item?.partyClassificationId === 'PcltCertPersonalEnterprise' && ['CrqtNew', 'CrqtExtension'].includes(item?.requestTypeEnumId);
        }, className: 'md:col-12', labelClassName: 'md:col-4 justify-content-end', inputClassName: 'md:col-8'
      },
      {
        field: 'relatedIdTypeEnumId', displayDependency(item: any): boolean {
          return item?.partyClassificationId === 'PcltCertPersonalEnterprise' && ['CrqtNew', 'CrqtExtension'].includes(item?.requestTypeEnumId);
        }, required: true, type: 'Dropdown', DropdownProps: {options: orgIdentifyTypes}, className: 'md:col-4', labelClassName: 'md:col-0', inputClassName: 'md:col-12'
      },
      {
        field: 'relatedIdValue', displayDependency(item: any): boolean {
          return item?.partyClassificationId === 'PcltCertPersonalEnterprise' && ['CrqtNew', 'CrqtExtension'].includes(item?.requestTypeEnumId);
        }, required: true, InputTextProps: {maxLength: 200}, className: 'md:col-8', labelClassName: 'md:col-0', inputClassName: 'md:col-12'
      },
      {
        field: 'relatedAddress1', header: 'Địa chỉ', displayDependency(item: any): boolean {
          return item?.partyClassificationId === 'PcltCertPersonalEnterprise' && ['CrqtNew', 'CrqtExtension'].includes(item?.requestTypeEnumId);
        }, className: 'md:col-12', labelClassName: 'md:col-4 justify-content-end', inputClassName: 'md:col-8'
      },
      {
        field: 'relatedEmailAddress', header: 'Email', displayDependency(item: any): boolean {
          return item?.partyClassificationId === 'PcltCertPersonalEnterprise' && ['CrqtNew', 'CrqtExtension'].includes(item?.requestTypeEnumId);
        }, InputTextProps: {maxLength: 50}, className: 'md:col-12', labelClassName: 'md:col-4 justify-content-end', inputClassName: 'md:col-8'
      },
      {
        field: 'relatedPhoneNumber', header: 'Số điện thoại', displayDependency(item: any): boolean {
          return item?.partyClassificationId === 'PcltCertPersonalEnterprise' && ['CrqtNew', 'CrqtExtension'].includes(item?.requestTypeEnumId);
        }, InputTextProps: {maxLength: 17, keyfilter: 'int'}, className: 'md:col-12', labelClassName: 'md:col-4 justify-content-end', inputClassName: 'md:col-8'
      },
      {
        field: 'relatedIdContents', header: 'Ảnh ĐKKD - Chức vụ', displayDependency(item: any): boolean {
          return item?.partyClassificationId === 'PcltCertPersonalEnterprise' && ['CrqtNew', 'CrqtExtension'].includes(item?.requestTypeEnumId);
        }, type: 'FileUploader', FileUploaderProps: {render: renderRelatedIdContents}, className: 'md:col-12', labelClassName: 'md:col-4 justify-content-end', inputClassName: 'md:col-8'
      },
      {
        type: 'custom', className: 'md:col-12 mb-3', body: <b>Thông tin Người đại diện</b>, displayDependency(item: any): boolean {
          return item?.partyClassificationId === 'PcltCertEnterprise' && ['CrqtNew', 'CrqtExtension'].includes(item?.requestTypeEnumId);
        }
      },
      {
        field: 'relatedPartyName', header: 'Họ và tên', displayDependency(item: any): boolean {
          return item?.partyClassificationId === 'PcltCertEnterprise' && ['CrqtNew', 'CrqtExtension'].includes(item?.requestTypeEnumId);
        }, required: true, className: 'md:col-12', labelClassName: 'md:col-4 justify-content-end', inputClassName: 'md:col-8'
      },
      {
        field: 'relatedJobTitle', header: 'Chức vụ', displayDependency(item: any): boolean {
          return item?.partyClassificationId === 'PcltCertEnterprise' && ['CrqtNew', 'CrqtExtension'].includes(item?.requestTypeEnumId);
        }, required: true, className: 'md:col-12', labelClassName: 'md:col-4 justify-content-end', inputClassName: 'md:col-8'
      },
      {
        field: 'relatedIdTypeEnumId', displayDependency(item: any): boolean {
          return item?.partyClassificationId === 'PcltCertEnterprise' && ['CrqtNew', 'CrqtExtension'].includes(item?.requestTypeEnumId);
        }, type: 'Dropdown', DropdownProps: {options: relatedIdentifyTypes},  required: true, className: 'md:col-4', labelClassName: 'md:col-0', inputClassName: 'md:col-12'
      }, {
        field: 'relatedIdValue', displayDependency(item: any): boolean {
          return item?.partyClassificationId === 'PcltCertEnterprise' && ['CrqtNew', 'CrqtExtension'].includes(item?.requestTypeEnumId);
        }, required: true, className: 'md:col-8', labelClassName: 'md:col-0', inputClassName: 'md:col-12'
      },
      {
        field: 'relatedIdPlace', header: 'Nơi cấp', displayDependency(item: any): boolean {
          return item?.partyClassificationId === 'PcltCertEnterprise' && ['CrqtNew', 'CrqtExtension'].includes(item?.requestTypeEnumId);
        }, required: true,  className: 'md:col-12', labelClassName: 'md:col-4 justify-content-end', inputClassName: 'md:col-8'
      },
      {
        field: 'relatedIdDate', header: 'Ngày cấp', displayDependency(item: any): boolean {
          return item?.partyClassificationId === 'PcltCertEnterprise' && ['CrqtNew', 'CrqtExtension'].includes(item?.requestTypeEnumId);
        }, required: true,  className: 'md:col-12', labelClassName: 'md:col-4 justify-content-end', inputClassName: 'md:col-8', InputTextProps: {type: 'date'}
      },
      {
        field: 'relatedIdContents', header: 'Ảnh CMND/CCCD/Hộ chiếu', displayDependency(item: any): boolean {
          return item?.partyClassificationId === 'PcltCertEnterprise' && ['CrqtNew', 'CrqtExtension'].includes(item?.requestTypeEnumId);
        }, type: 'FileUploader', FileUploaderProps: {render: renderRelatedIdContents}, className: 'md:col-12', labelClassName: 'md:col-4 justify-content-end', inputClassName: 'md:col-8'
      },

      {type: 'custom', className: 'md:col-12 mb-3', body: <b>Thông tin giao dịch và biểu mẫu</b>},
      {field: 'partyClassificationId', header: 'Loại chứng thư', disabled: true, type: 'Dropdown', DropdownProps: {options: classifications}, className: 'md:col-12', labelClassName: 'md:col-4 justify-content-end', inputClassName: 'md:col-8'},
      {
        field: 'productCategoryId', header: 'Thiết bị', displayDependency(item: any): boolean {
          return ['CrqtNew', 'CrqtExtension'].includes(item?.requestTypeEnumId);
        }, required: true, type: 'Dropdown', DropdownProps: {options: categories}, className: 'md:col-12', labelClassName: 'md:col-4 justify-content-end', inputClassName: 'md:col-8'
      },
      {
        field: 'fromPartyId', header: 'NCC CTS', disabledDependency(item: any): boolean {
          return ['CrqtInfoChange'].includes(item?.requestTypeEnumId);
        },
        displayDependency(item: any): boolean {
          return ['CrqtNew', 'CrqtExtension ', 'CrqtInfoChange'].includes(item?.requestTypeEnumId);
        }, required: true, type: 'Dropdown', DropdownProps: {options: vendors}, className: 'md:col-12', labelClassName: 'md:col-4 justify-content-end', inputClassName: 'md:col-8'
      },
      {
        field: 'productFeatureId', header: 'Thiết bị lưu khóa', displayDependency(item: any): boolean {
          return ['CrqtNew', 'CrqtExtension'].includes(item?.requestTypeEnumId);
        }, required: true, type: 'Dropdown', DropdownProps: {options: productFeatures}, className: 'md:col-12', labelClassName: 'md:col-4 justify-content-end', inputClassName: 'md:col-8'
      },
      {
        field: 'certificateSn', header: 'Certificate SN', displayDependency(item: any): boolean {
          return ['CrqtExtension', 'CrqtSuspend', 'CrqtRecovery', 'CrqtRevoke', 'CrqtReissue', 'CrqtInfoChange'].includes(item?.requestTypeEnumId);
        }, disabledDependency(item: any): boolean {
          return ['CrqtSuspend', 'CrqtRecovery', 'CrqtRevoke', 'CrqtReissue', 'CrqtInfoChange'].includes(item?.requestTypeEnumId)
        }, required: true, className: 'md:col-11', labelClassName: 'md:col-4 pr-0 justify-content-end', inputClassName: 'md:col-8 pl-6'
      },
      {
        field: 'searchCert', type: 'custom', body: <Button icon="pi pi-list" rounded severity="success" size="small" onClick={showSelectCert}/>, displayDependency(item: any): boolean {
          return !!(!readOnly && ['CrqtExtension', 'CrqtSuspend', 'CrqtRecovery', 'CrqtRevoke', 'CrqtReissue', 'CrqtInfoChange'].includes(item?.requestTypeEnumId) && item?.partyId?.value && (item?.idValue || item?.partyTaxId) && item?.fromPartyId);
        }, className: 'md:col-1 pl-0'
      },
      {
        field: 'originRequestTypeEnumId', header: 'Hình thức', displayDependency(item: any): boolean {
          return ['CrqtInfoChange'].includes(item?.requestTypeEnumId);
        }, required: true, type: 'Dropdown', DropdownProps: {options: [{value: 'CrqtNew', label: 'Cấp mới'}, {value: 'CrqtExtension', label: 'Gia hạn'}]}, className: 'md:col-12', labelClassName: 'md:col-4 justify-content-end', inputClassName: 'md:col-8'
      },
      {
        field: 'productId', header: 'Gói cước', displayDependency(item: any): boolean {
          return ['CrqtNew', 'CrqtExtension', 'CrqtInfoChange'].includes(item?.requestTypeEnumId);
        }, required: true, type: 'Dropdown', DropdownProps: {options: products}, className: 'md:col-12', labelClassName: 'md:col-4 justify-content-end', inputClassName: 'md:col-8'
      },
      {
        field: 'requestName', header: 'Lý do', displayDependency(item: any): boolean {
          return ['CrqtSuspend', 'CrqtRecovery', 'CrqtRevoke', 'CrqtReissue'].includes(item?.requestTypeEnumId);
        }, required: true, className: 'md:col-12', labelClassName: 'md:col-4 justify-content-end', inputClassName: 'md:col-8'
      },
      {
        field: 'dk01Contents', header: 'File DK 01', displayDependency(item: any): boolean {
          return ['CrqtNew', 'CrqtExtension'].includes(item?.requestTypeEnumId);
        }, type: 'FileUploader', FileUploaderProps: {render: renderDk01Contents}, className: 'md:col-12', labelClassName: 'md:col-4 justify-content-end', inputClassName: 'md:col-8'
      },
      // {
      //   field: 'dk02Contents', header: 'File DK 02', displayDependency(item: any): boolean {
      //     return ['CrqtNew', 'CrqtExtension'].includes(item?.requestTypeEnumId);
      //   }, type: 'FileUploader', FileUploaderProps: {render: renderDk02Contents}, className: 'md:col-12', labelClassName: 'md:col-4 justify-content-end', inputClassName: 'md:col-8'
      // },
      {
        field: 'dk03Contents', header: 'File DK 03', displayDependency(item: any): boolean {
          return ['CrqtSuspend', 'CrqtRecovery', 'CrqtRevoke', 'CrqtReissue', 'CrqtInfoChange'].includes(item?.requestTypeEnumId);
        }, type: 'FileUploader', FileUploaderProps: {render: renderDk03Contents}, className: 'md:col-12', labelClassName: 'md:col-4 justify-content-end', inputClassName: 'md:col-8'
      },
      {
        type: 'custom', className: 'md:col-12 mb-3', displayDependency(item: any): boolean {
          return ['CrqtNew', 'CrqtExtension'].includes(item?.requestTypeEnumId);
        }, body: <b>Chọn giao dịch viên</b>
      },
      {
        field: 'agentPartyId', header: 'Giao dịch viên', displayDependency(item: any): boolean {
          return ['CrqtNew', 'CrqtExtension'].includes(item?.requestTypeEnumId);
        }, required: true, type: 'TreeSelect', TreeSelectProps: {options: parents, filter: true}, className: 'md:col-12', labelClassName: 'md:col-4 justify-content-end', inputClassName: 'md:col-8'
      },
      {
        field: 'description', header: 'Ghi chú', displayDependency(item: any): boolean {
          return ['CrqtNew', 'CrqtExtension'].includes(item?.requestTypeEnumId);
        }, className: 'md:col-12', labelClassName: 'md:col-4 justify-content-end', inputClassName: 'md:col-8'
      }
    ],
    layout: 'Horizontal',
    formClassName: readOnly ? 'detail' : '',
    disabled: readOnly
  });

  const _value = getRawValue();


  useEffect(() => {
    if (_value.partyId?.value && dirty['partyId']) {
      CustomerService.get(_value.partyId?.value).then(data => {
        if (data) {
          const _value = getRawValue();
          _value.partyClassificationId = data.partyClassificationId;
          if (data.partyIdContents?.length) {
            const _partyIdContents = [];
            for (const content of data.partyIdContents) {
              _partyIdContents.push({
                key: content.partyContentId,
                contentType: content.contentType,
                fileName: content.fileName,
                src: `${CustomerService.baseURL}/${CustomerService.entity}/${data.partyId}/contents/${content.partyContentId}/download`
              });
            }
            setPartyIdContents(_partyIdContents);
          }
          if (data.relatedIdContents?.length) {
            const _relatedIdContents = [];
            for (const content of data.relatedIdContents) {
              _relatedIdContents.push({
                key: content.partyContentId,
                contentType: content.contentType,
                fileName: content.fileName,
                src: `${CustomerService.baseURL}/${CustomerService.entity}/${data.partyId}/contents/${content.partyContentId}/download`
              });
            }
            setRelatedIdContents(_relatedIdContents);
          }

          if (!_value.partyId.label) {
            _value.partyId = {value: data.partyId, label: data.partyName};
          }
          for (let partyIdent of data.partyIdentList) {
            if (partyIdent.partyIdTypeEnumId === 'PtidTaxIdent') {
              _value.idValue = partyIdent.idValue;
              break;
            } else if (partyIdent.partyIdTypeEnumId === 'PtidCccd') {
              _value.otherIdValue = partyIdent.idValue;
              break;
            }
          }
          _value.otherPartyIdTypeEnumId = data.partyIdTypeEnumId
          _value.partyName = data.partyName;
          _value.partyTaxId = data.partyTaxId;
          _value.partyIdTypeEnumId = data.partyIdTypeEnumId;
          _value.stateProvinceGeoId = data.stateProvinceGeoId;
          _value.countyGeoId = data.countyGeoId;
          _value.address1 = data.address1;
          _value.emailAddress = data.emailAddress;
          _value.phoneNumber = data.phoneNumber;
          _value.jobTitle = data.jobTitle;
          _value.departmentName = data.departmentName;
          _value.relatedPartyName = data.relatedPartyName;
          _value.relatedJobTitle = data.relatedJobTitle;
          _value.relatedIdTypeEnumId = data.relatedIdTypeEnumId;
          _value.relatedIdValue = data.relatedIdValue;
          _value.relatedIdPlace = data.relatedIdPlace;
          _value.relatedIdDate = FormatDisplay.date(data.relatedIdDate, 'YYYY-MM-DD');
          _value.relatedAddress1 = data.relatedAddress1;
          _value.relatedEmailAddress = data.relatedEmailAddress;
          _value.relatedPhoneNumber = data.relatedPhoneNumber;
          _value.agentPartyId = data.agentPartyId;
          setValue(_value);
          setIdValue(data.partyIdentList)
        }
      });
    }
  }, [_value.partyId?.value]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    switch (_value.partyIdTypeEnumId) {
      case 'PtidTaxIdent':
        setIdValueMaxLength(14);
        setIdValueKeyFilter('int');
        for (let partyIdent of idValue) {
          _value.idValue = ''
          if (partyIdent.partyIdTypeEnumId === 'PtidTaxIdent') {
            _value.idValue = partyIdent.idValue;
            break;
          }
        }
        setValue(_value);
        break;
      case 'PtidSocialInsurance':
        setIdValueMaxLength(14);
        setIdValueKeyFilter('int');
        for (let partyIdent of idValue) {
          _value.idValue = ''
          if (partyIdent.partyIdTypeEnumId === 'PtidSocialInsurance') {
            _value.idValue = partyIdent.idValue;
            break;
          }
        }
        setValue(_value);

        break;
      case 'PtidBudgetCode':
        setIdValueMaxLength(14);
        setIdValueKeyFilter('int');
        for (let partyIdent of idValue) {
          _value.idValue = ''
          if (partyIdent.partyIdTypeEnumId === 'PtidBudgetCode') {
            _value.idValue = partyIdent.idValue;
            break;
          }
        }
        setValue(_value);
        break;
      case 'PtidCccd':
      case 'PtidCmnd':
        setIdValueMaxLength(12);
        setIdValueKeyFilter('int');
        break;
      case 'PtidPassport':
        setIdValueMaxLength(8);
        setIdValueKeyFilter('alphanum');
        break;
      default:
        setIdValueMaxLength(10);
        setIdValueKeyFilter('int');
    }
    ;


  }, [_value.partyIdTypeEnumId]);

  useEffect(() => {
    if (_value.partyClassificationId) {
      CommonService.getIdentifyTypes(_value.partyClassificationId).then(data => {
        const {partyIdTypeEnumId} = getValue();
        let has_value = false;
        setIdentifyTypes(data.listData.map(item => {
          if (partyIdTypeEnumId === item.enumId) {
            has_value = true;
          }
          return {value: item.enumId, label: item.description}
        }));
        _value.partyIdTypeEnumId = has_value ? partyIdTypeEnumId : data.listData[0].enumId;
        setValue(_value);
      });
    }
    if (_value.partyClassificationId === 'PcltCertEnterprise') {
      CommonService.getIdentifyTypes('PcltCertPersonalEnterprise').then(data => {
        const {relatedIdTypeEnumId} = getValue();
        let has_value = false;
        setRelatedIdentifyTypes(data.listData.map(item => {
          if (relatedIdTypeEnumId === item.enumId) {
            has_value = true
          }
          return {value: item.enumId, label: item.description}
        }));
        _value.relatedIdTypeEnumId = has_value ? relatedIdTypeEnumId : data.listData[0].enumId;
        setValue(_value);
      })
    }
  }, [_value.partyClassificationId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_value.stateProvinceGeoId) {
      CommonService.getCounties(_value.stateProvinceGeoId).then(data => {
        setCounties(data.listData.map(item => {
          return {value: item.toGeoId, label: item.geoName}
        }));
      });
    }
  }, [_value.stateProvinceGeoId]);
  useEffect(() => {
    if (_value.requestTypeEnumId && _value.partyClassificationId && _value.fromPartyId) {
      if (_value.requestTypeEnumId === 'CrqtInfoChange' && !_value.originRequestTypeEnumId) {
        return;
      }
      CertificationService.getProducts({
        requestTypeEnumId: _value.requestTypeEnumId === 'CrqtInfoChange' ? _value.originRequestTypeEnumId : _value.requestTypeEnumId,
        partyClassificationId: _value.partyClassificationId,
        productCategoryId: _value.productCategoryId,
        fromPartyId: _value.fromPartyId
      }).then(data => {
        setProducts(data.listData.map(item => {
          return {value: item.productId, label: item.productName}
        }));
      });
    } else {
      setProducts([]);
    }
  }, [_value.requestTypeEnumId, _value.partyClassificationId, _value.productCategoryId, _value.fromPartyId, _value.originRequestTypeEnumId]);
  useEffect(() => {
    if (submitting) {
      if (formValid()) {
        doSubmit().then();
      } else {
        setSubmitting(false);
      }
    }
  }, [submitting]); // eslint-disable-line react-hooks/exhaustive-deps

  const doSubmit = async () => {
    const data = getValue();
    data.partyId = data.partyId.value;
    data.partyIdContents = await getPartyIdContents(true);
    if (data.partyIdContents.length === 1) {
      data.partyIdContent = data.partyIdContents[0];
      delete data.partyIdContents;
    }

    data.relatedIdContents = await getRelatedIdContents(true);
    if (data.relatedIdContents.length === 1) {
      data.relatedIdContent = data.relatedIdContents[0];
      delete data.relatedIdContents;
    }
    data.dk01Contents = await getDk01Contents(true);
    if (data.dk01Contents.length === 1) {
      data.dk01Content = data.dk01Contents[0];
      delete data.dk01Contents;
    }
    // data.dk02Contents = await getDk02Contents(true);
    // if (data.dk02Contents.length === 1) {
    //   data.dk02Content = data.dk02Contents[0];
    //   delete data.dk02Contents;
    // }
    data.dk03Contents = await getDk03Contents(true);
    if (data.dk03Contents.length === 1) {
      data.dk03Content = data.dk03Contents[0];
      delete data.dk03Contents;
    }

    let promise;
    if (selectedItem && selectedItem.requestId) {

      let deleteRequestContentIds = [];
      deleteRequestContentIds.push(...deletedPartyIdContents);
      deleteRequestContentIds.push(...deletedRelatedIdContents);
      deleteRequestContentIds.push(...deletedDk01Contents);
      // deleteRequestContentIds.push(...deletedDk02Contents);
      deleteRequestContentIds.push(...deletedDk03Contents);
      if (deleteRequestContentIds.length) {
        if (deleteRequestContentIds.length === 1) {
          data.deleteRequestContentIds = deleteRequestContentIds[0];
        } else {
          data.deleteRequestContentIds = deleteRequestContentIds;
        }
      }

      promise = CertificationService.update(selectedItem.requestId, CertificationService.toFormData(data));
    } else {
      if (submitting === 'Y') {
        promise = CertificationService.createAndSend(CertificationService.toFormData(data));
      } else {
        promise = CertificationService.create(CertificationService.toFormData(data));
      }
    }

    promise.then(() => {
      setDisplay(false);
      ToastService.success();
      reloadLazyData();
    }).finally(() => {
      setSubmitting(false);
    });
  }
  return (
    <TabView>
      <TabPanel header="Thông tin yêu cầu">
        <div className="grid pt-1">
          <div className="col-6">
            {renderForm()}
            {renderDialogCrupCustomer()}
            <SelectCert display={displaySelectCert} setDisplay={setDisplaySelectCert} setCert={setCert}/>
          </div>
          <div className="col-6">
            <div className="card-container blue-container pr-2" style={{position: 'fixed', width: '48vw', height: '70vh'}}>
              {previewPdfSrc &&
								<iframe className="iframe-full" src={previewPdfSrc} title="template-preview"/>
              }
              {previewImgSrc &&
								<div className="grid w-full h-full">
									<div className="col-12">
										<Slider value={previewImgWidth} min={300} max={previewImgMaxWidth} onChange={e => setPreviewImgWidth(e.value)}/>
									</div>
									<div className="col-12 iam-scroll" style={{width: '100%', height: 'calc(100% - 30px)', overflow: 'auto'}}>
										<img src={previewImgSrc} alt="" style={{width: `${previewImgWidth}px`, maxWidth: previewImgMaxWidth}} onLoad={onPreviewImgLoad}/>
									</div>
								</div>
              }
            </div>
          </div>
        </div>
      </TabPanel>
      <TabPanel header="Tài khoản cấp phát CTS">
        {activeForm.render()}
      </TabPanel>
      <TabPanel header="Lịch sử yêu cầu">
        {histories.render()}
      </TabPanel>
    </TabView>
  );
}