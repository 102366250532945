import {TabView, TabPanel} from 'primereact/tabview';

import {LcsAuth} from './LcsAuth';
import {VininvoiceAuth} from "./VininvoiceAuth";

export const ThongTinTaiKhoan = () => {

  return (
    <TabView>
      <TabPanel header="Tài khoản LCS">
        <LcsAuth/>
      </TabPanel>
        <TabPanel header="Tài khoản Vininvoice">
        <VininvoiceAuth/>
      </TabPanel>
    </TabView>
  );
}