import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {Slider} from 'primereact/slider';

import {ConfirmDialogService, FormatDisplay, ToastService, useDataTableBasic, useDialogCrup, useForm} from '@iamsoftware/react-hooks';

import {InvoiceService as Service} from '../InvoiceService';

export const InvoiceDetail = ({display, setDisplay, reloadLazyData}) => {
  const [items, setItems] = useState([]);
  const [invoiceTemplateParties, setInvoiceTemplateParties] = useState(null)
  const [paymentTypes, setPaymentTypes] = useState(null)
  const [includeTax, setIncludeTax] = useState(false)
  const [taxRateTypes, setTaxRateTypes] = useState(null)

  useEffect(() => {
    const body = document.getElementsByTagName('BODY')[0];
    if (display) {
      body.classList.add('has-dialog-maximized');
    } else {
      body.classList.remove('has-dialog-maximized');
    }
  }, [display]);
  useEffect(() => {
    if (display) {
      Service.getInvoiceFormList().then(data => {
        setInvoiceTemplateParties(data.listData.map(item => {
          return {value: item.invoiceTemplatePartyId, label: `[${item.invoiceForm}${item.invoiceSerial}] - ${item.description}`, includeTax: item.includeTax}
        }));
      });
      Service.getPaymentFormTypes().then(data => {
        setPaymentTypes(data.listData.map(item => {
          return {value: item.enumId, label: item.description}
        }))
      });
      Service.getTaxRateTypes().then(data => {
        setTaxRateTypes(data.listData.map(item => {
          return {value: item.enumId, label: item.description, enumCode: item.enumCode}
        }))
      })
      load();
    }
  }, [display]); // eslint-disable-line react-hooks/exhaustive-deps

  const load = () => {
    Service.getDetail(display.invoiceId).then(data => {
      resetFormDetail();
      setValueFormDetail(data);
      setItems(data.items)
    });
  }
  const {render: renderFormDetail, reset: resetFormDetail, setValue: setValueFormDetail, valid, getValue} = useForm({
    fields: [
      {field: 'invoiceTemplatePartyId', header: 'Mẫu số ký hiệu', type: 'Dropdown', DropdownProps: {options: invoiceTemplateParties}, className: 'md:col-4', required: true},
      {type: 'custom', className: 'md:col-12 mt-2', body: <b>Thông tin đơn vị bán hàng</b>},
      {field: 'fromPartyName', header: 'Tên đơn vị', className: 'md:col-8', disabled: true},
      {field: 'fromPartyTaxId', header: 'Mã số thuế', className: 'md:col-4', disabled: true},
      {field: 'fromAddress1', header: 'Địa chỉ', className: 'md:col-12', disabled: true},
      {type: 'custom', className: 'md:col-12 mt-2', body: <b>Thông tin đơn vị mua hàng</b>},
      {field: 'toPartyName', header: 'Tên đơn vị', className: 'md:col-8'},
      {field: 'toPartyTaxId', header: 'Mã số thuế', className: 'md:col-4'},
      {field: 'toAddress1', header: 'Địa chỉ', className: 'md:col-8'},
      {field: 'toEmailAddress', header: 'Email', className: 'md:col-2'},
      {field: 'toContactNumber', header: 'Số điện thoại', className: 'md:col-2'},
      {field: 'paymentFormTypeEnumId', header: 'Hình thức thanh toán', type: 'Dropdown', DropdownProps: {options: paymentTypes}, className: 'md:col-3'},
      {type: 'custom', className: 'md:col-12 mt-2', body: <b>Hàng hóa/ Dịch vụ</b>},
    ],
  });

  const {invoiceTemplatePartyId} = getValue();
  const dataKey = 'dataKey';

  useEffect(() => {
    if (invoiceTemplatePartyId && invoiceTemplateParties) {
      invoiceTemplateParties.map(item => {
        if (invoiceTemplatePartyId === item.value) {
          if (item.includeTax === 'Y') {
            setIncludeTax(true);
            items.map(itemData => {
              itemData.taxPercentage = 0
            })
          } else if (item.includeTax === 'N') {
            setIncludeTax(false)
          }
        }
      })
    }
  }, [invoiceTemplatePartyId])

  useEffect(() => {
    if (!invoiceTemplatePartyId && invoiceTemplateParties) {
      const _value = getValue();
      _value.invoiceTemplatePartyId = invoiceTemplateParties[0].value
      setValueFormDetail(_value);
    }
  }, [items])

  useEffect(() => {
    items.map(item => {
      item.totalAmount = item.amount * item.quantity
      if (item.taxTypeEnumId) {
        taxRateTypes.map(taxRateData => {
          if (item.taxTypeEnumId === taxRateData.value) {
            item.taxAmount = item.totalAmount * taxRateData.enumCode / 100
          }
        })
      } else {
        item.taxAmount = 0;
      }
    })
  }, [items])

  const onEditorChange = (rowData, column, newValue) => {
    setItems(prevProductItems => prevProductItems.map(prevProductItem => {
      if (prevProductItem[dataKey] === rowData[dataKey]) {
        return {
          ...prevProductItem,
          [column.field]: newValue
        }
      }
      return prevProductItem;
    }));
  }

  const {render} = useDataTableBasic({
    columns: [
      {field: 'description', header: 'Tên hàng hóa/Dịch vụ', width: 200},
      {
        field: 'quantity', header: 'Số lượng', width: 150,
        dataType: 'editor', editorConfig: {
          onEditorChange, readOnly: false,
          field: {type: 'InputNumber', InputNumberProps: {min: 0}}
        }
      },
      {
        field: 'amount', header: 'Đơn giá', width: 150,
        dataType: 'editor', editorConfig: {
          onEditorChange, readOnly: false,
          field: {type: 'InputNumber', InputNumberProps: {min: 0}}
        }
      },
      {
        field: 'totalAmount', header: 'Thành tiền', width: 150, dataType: 'editor', editorConfig: {
          onEditorChange, readOnly: true,
          field: {type: 'InputNumber', InputNumberProps: {min: 0}}
        }
      },
      {
        field: 'taxTypeEnumId', header: 'Thuế suất', width: 150, hidden: !includeTax, dataType: 'editor', editorConfig: {
          onEditorChange, readOnly: false,
          field: {
            type: 'Dropdown', DropdownProps: {options: taxRateTypes}
          }
        }
      },
      {
        field: 'taxAmount', header: 'Tiền thuế', width: 150, hidden: !includeTax, dataType: 'editor', editorConfig: {
          onEditorChange, readOnly: true,
          field: {type: 'InputNumber', InputNumberProps: {min: 0}}
        }
      }
    ],
    items
  });

  const onUpdate = () => {
    if (valid()) {
      const item = getValue();
      item.items = items
      item.invoiceId = display.invoiceId
      Service.invoiceUpdate(item).then(() => {
        ToastService.success();
        reloadLazyData();
        setDisplay(false)
      })
    }
  }
  const onUpdateAndIssue = () => {
    if (valid()) {
      const item = getValue();
      item.items = items
      item.invoiceId = display.invoiceId
      Service.invoiceUpdateAndIssue(item).then(() => {
        ToastService.success();
        reloadLazyData();
        setDisplay(null);
      })
    }
  }


  const footer = (
    <div>
      {display?.statusId === 'InvoiceInit' && <Button label="Tạo nháp" icon='pi pi-pencil' type='button' severity='warning' size='small' onClick={() => onUpdate()}></Button>}
      {display?.statusId === 'InvoiceInit' && <Button label="Tạo và ký HĐ" icon='pi pi-file-edit' type='button' severity='warning' size='small' onClick={() => onUpdateAndIssue()}></Button>}
      {display?.statusId === 'InvoiceDraft' && <Button label="Lưu nháp " icon='pi pi-pencil' type='button' severity='warning' size='small' onClick={() => onUpdate()}></Button>}
      {display?.statusId === 'InvoiceDraft' && <Button label="Lưu và ký HĐ" icon='pi pi-file-edit' type='button' severity='warning' size='small' onClick={() => onUpdateAndIssue()}></Button>}
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(false)}/>
    </div>
  );
  const header = (
    <div>
      Hóa Đơn Giá Trị Gia Tăng
    </div>
  )
  return (
    <Fragment>
      <Dialog header={header} footer={footer} visible={display} maximized={true} draggable={false} onHide={() => setDisplay(false)}>
        <div className="grid pt-2">
          <div className="col-12">
            <div className="grid">
              <div className="col-12">
                {renderFormDetail()}
                {render()}
              </div>
            </div>
          </div>
        </div>
      </Dialog>

    </Fragment>
  );
}