import {EntityService} from '../../../service/EntityService';

export class CategoryService extends EntityService {

  static entity = 'vinca-product/categories';

  static getProductsNotInCategory(productCategoryId: string, lazyLoadEvent: string, params?: any): Promise<any> {
    return this.axios.get(`${this.entity}/${productCategoryId}/products/not-in?lazyLoadEvent=${lazyLoadEvent}`, {params}).then(res => res.data);
  }

  static getProductsInCategory(productCategoryId: string, lazyLoadEvent: string, params?: any): Promise<any> {
    return this.axios.get(`${this.entity}/${productCategoryId}/products?lazyLoadEvent=${lazyLoadEvent}`, {params}).then(res => res.data);
  }

  static addProductsToCategory(productCategoryId: string, productIdList: Array<any>): Promise<any> {
    return this.axios.post(`${this.entity}/${productCategoryId}/products/add`, {productIdList}).then(res => res.data);
  }

  static removeProductsFromCategory(productCategoryId: string, productIdList: Array<any>): Promise<any> {
    return this.axios.post(`${this.entity}/${productCategoryId}/products/remove`, {productIdList}).then(res => res.data);
  }

}