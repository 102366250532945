import {Route, Routes} from 'react-router-dom';

import {YeuCau} from './yeu-cau';
import {TaiKhoan} from "./tai-khoan";
import {CanXuatHD} from './cts-can-xuat-hd';
import {DaXuatHD} from './cts-da-xuat-hd';
export const HoaDonDienTu = ({me}) => {

    return (
        <Routes>
            <Route path="yeu-cau" element={<YeuCau me={me}/>}/>
            <Route path="tai-khoan" element={<TaiKhoan me={me}/>}/>
            <Route path="cts-can-xuat-hd" element={<CanXuatHD/>}/>
            <Route path="cts-da-xuat-hd" element={<DaXuatHD />}/>
        </Routes>
    );
}