import {EntityService} from '../../service/EntityService';

export class MessageService extends EntityService {

  static entity = 'vinca-cert/notifications';

  static getMessageList(lazyLoadEvent?: string): Promise<any> {
    return this.axios.get(`${this.entity}/messages/?lazyLoadEvent=${lazyLoadEvent}`).then(res => res.data);
  }

  static configDetail(): Promise<any> {
    return this.axios.get(`${this.entity}/config`).then(res => res.data);
  }
  static configHistory(): Promise<any> {
    return this.axios.get(`${this.entity}/config/histories`).then(res => res.data);
  }

  static updateConfig(data: any): Promise<any> {
    return this.axios.put(`${this.entity}/config`, data).then(res => res.data);
  }


  static GetTimePeriod(): Promise<any> {
    return this.axios.get(`/vinca-common/time-uoms`).then(res => res.data);
  }

  static GetCertList(workEffortId: string, lazyLoadEvent?: string): Promise<any> {
    return this.axios.get(`${this.entity}/messages/${workEffortId}/certs?lazyLoadEvent=${lazyLoadEvent}`).then(res => res.data);
  }

  static GetMessageHistory(workEffortId: string, partyCertId: string): Promise<any> {
    return this.axios.get(`${this.entity}/messages/${workEffortId}/certs/${partyCertId}/histories`).then(res => res.data);
  }

  static sendMessage(workEffortId: string, data: any): Promise<any> {
    return this.axios.put(`${this.entity}/messages/${workEffortId}/certs/send-batch`, data).then(res => res.data);
  }

  static updatePhoneNumber(workEffortId: string, partyCertId: string, data: any): Promise<any> {
    return this.axios.put(`${this.entity}/messages/${workEffortId}/certs/${partyCertId}/update`, data).then(res => res.data);
  }

  static resendMessage(workEffortId: string, partyCertId: string): Promise<any> {
    return this.axios.put(`${this.entity}/messages/${workEffortId}/certs/${partyCertId}/resend`).then(res => res.data);
  }

  static sendMessageToAll(workEffortId: string): Promise<any> {
    return this.axios.put(`${this.entity}/messages/${workEffortId}/certs/send-remain`).then(res => res.data);
  }

  static removeFromList(workEffortId: string, data: any): Promise<any> {
    return this.axios.post(`${this.entity}/messages/${workEffortId}/certs/delete`, data).then(res => res.data);
  }


}