import {Fragment, useEffect} from 'react';

import {Button} from 'primereact/button';
import {ToastService, useDataTable, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {PromotionService as Service} from './PromotionService';

interface Props {
  me: any
  beCreate?: boolean
}

const statuses = [
  {value: '1', label: 'Mới tạo'},
  {value: '2', label: 'Đã duyệt'},
  {value: '3', label: 'Đã huỷ'},
  {value: '4', label: 'Đã hết hạn'}
];

export const KhuyenMai = (props: Props) => {

  const header = 'CTKM';
  const dataKey = '_id';

  useEffect(() => {
    if (props.beCreate) {
      create();
    }
  }, [props.beCreate]); // eslint-disable-line react-hooks/exhaustive-deps

  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    dataKey,
    columns: [
      {field: 'pseudoId', header: `Mã ${header}`, width: 150, matchMode: 'contains'},
      {field: 'name', header: `Tên ${header}`, minWidth: 250, matchMode: 'contains'},
      {field: 'for', header: 'Gói cước áp dụng', minWidth: 200, matchMode: 'contains'},
      {field: 'fromDate', header: 'Ngày hiệu lực', width: 130, dataType: 'date'},
      {field: 'thruDate', header: 'Đến ngày', width: 130, dataType: 'date'},
      {field: 'status', header: 'Trạng thái', width: 150, matchMode: 'equals', filterType: 'dropdown', filterOptions: statuses, dataType: 'fromFilterOptions'}
    ],
    indexColumnWidth: 45,
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    }
  });

  const {render: renderDialogCrup, create, update} = useDialogCrup({
    header,
    dataKey,
    width: '50rem',
    fields: [
      {field: 'pseudoId', header: `Mã ${header}`, required: true, className: 'md:col-12'},
      {field: 'name', header: `Tên ${header}`, required: true, className: 'md:col-12'},
      {field: 'for', header: 'Gói cước áp dụng', type: 'InputTextarea', className: 'md:col-12'},
      {field: 'fromDate', header: 'Ngày hiệu lực', type: 'Calendar', className: 'md:col-6'},
      {field: 'thruDate', header: 'Đến ngày', type: 'Calendar', className: 'md:col-6'}
    ],
    createItem: item => {
      item.status = '1';
      if (item.fromDate) {
        item.fromDate = new Date(1 * item.fromDate);
      }
      if (item.thruDate) {
        item.thruDate = new Date(1 * item.thruDate);
      }
      return Service.create(item);
    },
    updateItem: (id, item) => {
      if (item.fromDate) {
        item.fromDate = new Date(1 * item.fromDate);
      }
      if (item.thruDate) {
        item.thruDate = new Date(1 * item.thruDate);
      }
      return Service.update(id, item);
    },
    reloadLazyData
  });

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const doUpdate = () => {
    if (selectedItem && selectedItem[dataKey]) {
      if (selectedItem.fromDate) {
        selectedItem.fromDate = new Date(selectedItem.fromDate);
      }
      if (selectedItem.thruDate) {
        selectedItem.thruDate = new Date(selectedItem.thruDate);
      }
      update(selectedItem);
    }
  }

  const changeStatus = toStatus => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.update(selectedItem[dataKey], {status: toStatus}).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    }
  }

  const {renderToolbar} = useToolbar({
    doCreate: create,
    hasSelectedItem: selectedItem,
    doUpdate,
    leftButtons: <Fragment>
      <Button label="Duyệt" icon="pi pi-check" severity="success" size="small" onClick={() => changeStatus('2')} disabled={!selectedItem}/>
      <Button label="Huỷ" icon="pi pi-times" severity="danger" size="small" onClick={() => changeStatus('3')} disabled={!selectedItem}/>
    </Fragment>
  });

  return (
    <div className="grid">
      <div className="col-12">
        {renderToolbar()}
      </div>
      <div className="col-12 py-0" style={{maxHeight: 'calc(100vh - 29rem)'}}>
        {renderDataTable()}
      </div>

      {renderDialogCrup()}
    </div>
  );
}