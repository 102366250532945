import React from 'react';
import {Link, useNavigate} from 'react-router-dom';

import {classNames} from 'primereact/utils';

import {useDialogCrup} from '@iamsoftware/react-hooks';

import {AuthService} from '../service/AuthService';

const AppTopbar = props => {

  const navigate = useNavigate();

  const {render: renderDialogChangePassword, create: changePassword} = useDialogCrup({
    header: '!Đổi mật khẩu',
    submitLabel: 'Xác nhận',
    width: '40rem',
    fields: [
      {field: 'oldPassword', header: 'Mật khẩu hiện tại', required: true, type: 'Password', className: 'md:col-12'},
      {field: 'newPassword', header: 'Mật khẩu mới', required: true, type: 'Password', className: 'md:col-12'},
      {field: 'newPasswordVerify', header: 'Xác nhận mật khẩu mới', required: true, type: 'Password', className: 'md:col-12'}
    ],
    createItem: item => {
      return AuthService.changePassword(item);
    },
    checkResponseError: data => {
      let error = '';
      if (data?.isSuccess === 'N') {
        error = data.messages;
      }
      return error;
    }
  });

  const onTopbarItemClick = (event, item) => {
    if (props.onTopbarItemClick) {
      props.onTopbarItemClick({
        originalEvent: event,
        item: item
      });
    }
  };

  const logout = () => {
    AuthService.logout().then(() => {
      window.location.hash = '/login';
    });
  }

  return (
    <div className="layout-topbar">
      {/*<button type="button" className="p-link layout-right-panel-button layout-topbar-icon" onClick={props.onRightMenuButtonClick}>*/}
      {/*  <i className="pi pi-ellipsis-v"></i>*/}
      {/*</button>*/}

      <button type="button" className="p-link layout-menu-button layout-topbar-icon" onClick={props.onMenuButtonClick}>
        <i className="pi pi-bars"></i>
      </button>

      <button type="button" className="p-link layout-topbar-logo" onClick={() => navigate('/')} style={{background: '#0972b3'}}>
        <img id="topbar-logo" src="/assets/vin-invoice/logovin_new.png" alt="VIN-CRM"/>
      </button>

      <ul className="topbar-menu">
        <li className={classNames('user-profile pr-0', {'active-topmenuitem fadeInDown': props.activeTopbarItem === 'profile'})}>
          {!props.inlineUser && (
            <button type="button" className="p-link layout-topbar-icon" onClick={(e) => onTopbarItemClick(e, 'profile')}>
              <i className="topbar-icon pi pi-fw pi-user"></i>
              <div className="layout-profile-userinfo pr-2">
                <span className="layout-profile-name">{props.me.roleTypeId}</span>
              </div>
            </button>
          )}

          <ul className="fadeInDown">
            <li role="menuitem">
              <Link to="/thong-tin-tai-khoan" className="p-link">
                <i className="pi pi-fw pi-user mr-2"></i>
                <span>Thông tin tài khoản</span>
              </Link>
            </li>
            <li role="menuitem">
              <button type="button" className="p-link" onClick={changePassword}>
                <i className="pi pi-fw pi-unlock mr-2"></i>
                <span>Đổi mật khẩu</span>
              </button>
            </li>
            <li role="menuitem">
              <button type="button" className="p-link" onClick={logout}>
                <i className="pi pi-fw pi-power-off mr-2"></i>
                <span>Đăng xuất</span>
              </button>
            </li>
          </ul>
        </li>

        <li className="ml-3">
          <button type="button" className="p-link layout-topbar-icon" onClick={props.onConfigButtonClick}>
            <i className="topbar-icon pi pi-fw pi-cog"></i>
          </button>
        </li>
        <li className={classNames({'active-topmenuitem fadeInDown': props.activeTopbarItem === 'settings'})}>
          <button type="button" className="p-link layout-topbar-icon" onClick={(e) => onTopbarItemClick(e, 'settings')}>
            <i className="topbar-icon pi pi-fw pi-bell p-overlay-badge">
              <span className="p-badge p-component p-badge-no-gutter p-badge-success">5</span>
            </i>
          </button>
          <ul className="fadeInDown">
            {[1, 2, 3, 4, 5].map(i => <li key={i} role="menuitem">
              <button type="button" className="p-link">
                <img src="/iam/bell-Icon.png" alt="bell"/>
                <div className="topbar-menu-info">
                  <span className="topbar-menu-name">Chứng thư số</span>
                  <span className="topbar-menu-role">Yêu cầu cấp mới mã 20221006-000{i}.</span>
                </div>
              </button>
            </li>)}
          </ul>
        </li>
      </ul>
      <span className="layout-profile-role">{props.me.partyName}</span>
      {renderDialogChangePassword()}
    </div>
  );
};

export default AppTopbar;
