import {Fragment, useEffect, useRef, useState} from 'react';

import {Button} from 'primereact/button';
import {Calendar} from 'primereact/calendar';
import {Dialog} from 'primereact/dialog';
import {Dropdown} from 'primereact/dropdown';
import {FormatDisplay} from '@iamsoftware/react-hooks';
import {Messages} from 'primereact/messages';

import {ProfileService as Service} from './ProfileService';

export const UploadContent = ({requestId, reload}) => {

  const msgs = useRef(null);

  const [display, setDisplay] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [items, setItems] = useState([]);

  useEffect(() => {
    if (requestId) {
      Service.getContentTypeEnums(requestId).then(data => {
        setItems(data.items);
      });
    }
  }, [requestId]);

  const onInputChange = (index, field, newValue) => {
    setItems(prevItems => {
      prevItems[index][field] = newValue;
      return [...prevItems];
    });
  }

  const onSubmit = () => {
    if (!submitting) {
      msgs.current.clear();

      const errorMessages = [];
      items.forEach(item => {
        if (item.files) {
          if (item.formTypes?.length > 0) {
            if (item.formTypeId) {
              if (item.formTypeId.hardCopyRequire === 'Y' && !item.hardCopyReceivedDate) {
                errorMessages.push(`${item.description} chưa chọn Ngày nhận bản cứng`);
              }
            } else {
              errorMessages.push(`${item.description} chưa chọn Hình thức HS`);
            }
          }
        }
      });

      if (errorMessages.length) {
        msgs.current.show(
          {sticky: true, severity: 'error', detail: errorMessages.join(', '), closable: false}
        );
      } else {
        setSubmitting(true);

        const form: any = {
          contentFiles: [],
          contentTypeEnumIds: [],
          formTypeIds: [],
          hardCopyReceivedDates: []
        }
        items.forEach(item => {
          if (item.files) {
            for (const file of item.files) {
              form.contentFiles.push(file);
              form.contentTypeEnumIds.push(item.enumId);
              if (item.formTypeId?.formTypeId) {
                form.formTypeIds.push(item.formTypeId.formTypeId);
              } else {
                form.formTypeIds.push('');
              }
              if (item.hardCopyReceivedDate) {
                form.hardCopyReceivedDates.push(new Date(item.hardCopyReceivedDate).getTime());
              } else {
                form.hardCopyReceivedDates.push('');
              }
            }
          }
        });

        Object.keys(form).forEach(k => {
          if (form[k].length === 1) {
            form[k.substring(0, k.length - 1)] = form[k][0];
            delete form[k];
          }
        });

        Service.uploadContent(requestId, Service.toFormData(form)).then(() => {
          setSubmitting(false);
          setDisplay(false);
          reload();
        }).catch(() => {
          setSubmitting(false);
        });
      }

    }
  }

  const footer = (
    <div>
      <Button label="Lưu" icon="pi pi-check" size="small" onClick={onSubmit} loading={submitting}/>
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(false)}/>
    </div>
  );

  return (
    <Fragment>
      <Button label="Upload hồ sơ CTS" icon="pi pi-plus" onClick={() => setDisplay(true)} severity="success" size="small"/>

      <Dialog header="Upload hồ sơ CTS" footer={footer} visible={display} style={{width: '80rem'}} position="top" onHide={() => setDisplay(false)}>
        <div className="p-datatable p-component p-datatable-responsive-scroll p-datatable-gridlines p-datatable-sm iam-cell-editing p-fluid">
          <div className="p-datatable-wrapper">
            <table className="p-datatable-table">
              <thead className="p-datatable-thead">
              <tr role="row">
                <th style={{width: '35px'}} className="text-center">#</th>
                <th>Loại hồ sơ</th>
                <th>Files hồ sơ</th>
                <th style={{width: '180px'}}>Hình thức HS</th>
                <th style={{width: '150px'}}>Ngày nhận bản cứng</th>
              </tr>
              </thead>
              <tbody className="p-datatable-tbody">
              {items.map((item, index) => <tr key={index} className={index % 2 ? 'p-selectable-row p-row-odd' : 'p-selectable-row'}>
                <td className="text-center">{FormatDisplay.number(index + 1)}</td>
                <td>{item.description}</td>
                <td>
                  <input type="file" onChange={e => onInputChange(index, 'files', e.target.files)} multiple={true}/>
                </td>
                <td>
                  {item.formTypes?.length > 0 &&
										<Dropdown value={item.formTypeId} onChange={e => onInputChange(index, 'formTypeId', e.value)} options={item.formTypes}
															optionLabel="description"/>
                  }
                </td>
                <td>
                  {item.formTypeId?.hardCopyRequire === 'Y' &&
										<Calendar value={item.hardCopyReceivedDate} onChange={e => onInputChange(index, 'hardCopyReceivedDate', e.value)} dateFormat="dd/mm/yy"/>
                  }
                </td>
              </tr>)}
              </tbody>
            </table>
          </div>
        </div>

        <Messages ref={msgs}/>
      </Dialog>
    </Fragment>
  );
}