import {Fragment} from 'react';

import {Button} from 'primereact/button';
import {useDataTable, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {AccountService as Service} from './AccountService';

export const QuanLyTaiKhoan = () => {

  const dataKey = 'userId';

  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    dataKey,
    columns: [
      {field: 'pseudoId', header: 'Mã', width: 120, matchMode: 'contains'},
      {field: 'username', header: 'Tài Khoản', width: 180, matchMode: 'contains'},
      {field: 'roleType', header: 'Vai trò', width: 145, matchMode: 'contains'},
      {field: 'emailAddress', header: 'Email', width: 150, matchMode: 'contains'},
      {field: 'userFullName', header: 'Người dùng', minWidth: 170, matchMode: 'contains'},
      {field: 'partyName', header: 'Tên đại lý', minWidth: 170, matchMode: 'contains'},
      {field: 'partyTaxId', header: 'Mã số thuế', width: 170, matchMode: 'contains'}
    ],
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    }
  });

  const {render: renderDialogResetPassword, update: resetPassword} = useDialogCrup({
    header: '!Reset Mật khẩu',
    dataKey,
    width: '40rem',
    fields: [
      {field: 'password', header: 'Mật khẩu mới', required: true, className: 'md:col-12'}
    ],
    updateItem(id: string, item: any): Promise<any> {
      return Service.resetPassword(id, item);
    },
    reloadLazyData
  });

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const {renderToolbar} = useToolbar({
    hasSelectedItem: selectedItem,
    leftButtons: <Fragment>
      <Button label="Reset Mật khẩu" icon="pi pi-refresh" severity="warning" size="small" onClick={() => resetPassword(selectedItem)} disabled={!selectedItem}/>
    </Fragment>
  });

  return (
    <div className="grid">
      <div className="col-12">
        {renderToolbar()}
      </div>
      <div className="col-12 py-0" style={{maxHeight: 'calc(100vh - 15.5rem)'}}>
        {renderDataTable()}
      </div>
      {renderDialogResetPassword()}
    </div>
  );
}



