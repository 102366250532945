import {EntityService} from '../../../service/EntityService';

export class ProductService extends EntityService {

  static entity = 'vinca-product/services';

  static getList(lazyLoadEvent: string, path: string): Promise<any> {
    return this.axios.get(`${this.entity}/${path}?lazyLoadEvent=${lazyLoadEvent}`).then(res => res.data);
  }

  static getVendors(): Promise<any> {
    return this.axios.get(`vinca-party/vendors/list`).then(res => res.data);
  }
  static getCategory(ownerPartyId: string): Promise<any> {
    return this.axios.get(`/vinca-product/services/list-category?ownerPartyId=${ownerPartyId}`).then(res => res.data);
  }
}