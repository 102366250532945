type AppStorageKey = ('moquiSessionToken');

export class AppStorage {

  static readonly app = 'iam-vin-crm-react.v1.0.0';

  static set(key: AppStorageKey, value: string): void {
    localStorage.setItem(`${this.app}_${key}`, value);
  }

  static get(key: AppStorageKey): string {
    return localStorage.getItem(`${this.app}_${key}`) || '';
  }

  static remove(key: AppStorageKey): void {
    localStorage.removeItem(`${this.app}_${key}`);
  }

}
