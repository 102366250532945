import {Route, Routes} from 'react-router-dom';

import {DanhSach} from './danh-sach';
import {TaiKhoan} from "../hoa-don-dien-tu/tai-khoan";


export const KhachHang = ({me}) => {

  return (
    <Routes>
      <Route path="danh-sach" element={<DanhSach me={me}/>}/>
    </Routes>
  );
}