import {Route, Routes} from 'react-router-dom';
import {TokenList} from './danh-sach-token';

export const QuanLyToken = () => {

  return (
    <Routes>
      <Route path="danh-sach-token" element={<TokenList/>}/>
    </Routes>
  );
}