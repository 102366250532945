import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import moment from 'moment';
import {Button} from 'primereact/button';
import {Menu} from 'primereact/menu';
import {Dropdown} from 'primereact/dropdown';
import {ConfirmDialogService, FormatDisplay, ToastService, useDataTable, useDialogCrup, useForm, useToolbar} from '@iamsoftware/react-hooks';

import {IamPermission} from '../../../shared/utils/Permission';

import {CertificationService as Service} from './CertificationService';

import {Crup} from './Crup';
import {Histories} from './Histories';
import {MessageService} from "../../canh-bao/MessageService";

interface Props {
  me: any
  beCreate?: boolean
}

export const YeuCau = (props: Props) => {

  const {me} = props;
  const dataKey = 'requestId';

  const {status} = useParams();
  const [url, setUrl] = useState(null);

  const [lazyLoadParams, setLazyLoadParams] = useState(null);
  const [excelParams, setExcelParams] = useState(null);

  const [displayCrup, setDisplayCrup] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [crupItem, setCrupItem] = useState(null);
  const [approvePerms, setApprovePerms] = useState();
  const [revokePerms, setRevokePerms] = useState();
  const [display, setDisplay] = useState(false)
  const [requestStatus, setRequestStatus] = useState(null)
  const [requestType, setRequestType] = useState(null)
  const [displayHistories, setDisplayHistories] = useState(null);
  const [requestTypeEnumId, setRequestTypeEnumId] = useState(null)
  const [statusCode, setStatusCode] = useState(null)

  useEffect(() => {
    Service.getRequestStatus().then(data => {
      setRequestStatus(data.listData.map(item => {
        return {value: item.statusCode, label: item.description}
      }));
    });
  }, []);

  useEffect(() => {
    Service.getRequestType().then(data => {
      setRequestType(data.listData.map(item => {
        return {value: item.enumId, label: item.description}
      }));
    });
  }, []);

  useEffect(() => {
    if (props.beCreate) {
      doCreate();
    }
  }, [props.beCreate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setUrl(status ? status : 'list');
    if (status) {
      doClear()
    } else {
      searchForm.setValue({
        fromDate: FormatDisplay.date(moment().subtract(1, 'month').startOf('day').toDate(), 'YYYY-MM-DD'),
        thruDate: FormatDisplay.date(moment().endOf('day').toDate(), 'YYYY-MM-DD')
      });
    }
    reloadLazyData();
  }, [status]); // eslint-disable-line react-hooks/exhaustive-deps


  const {render: renderDataTable, selectedItems, refreshLazyData, reloadLazyData} = useDataTable({
    dataKey,
    columns: [

      {field: 'pseudoId', header: 'Mã yêu cầu', minWidth: 130, matchMode: 'contains'},
      {field: 'partyName', header: 'Tên thuê bao', minWidth: 150, matchMode: 'contains'},
      {field: 'partyClassification', header: 'Loại khách hàng', minWidth: 150, matchMode: 'contains'},
      {field: 'idValue', header: 'MST/CCCD/CMND', minWidth: 155, matchMode: 'contains'},
      {
        field: 'requestTypeEnum',
        header: 'Loại giao dịch',
        minWidth: 160,
        matchMode: 'contains',
        dataType: 'custom',
        customCell(rowData: any): any {
          let _className;
          switch (rowData.requestTypeEnumId) {
            case 'CrqtNew':
              _className = 'alert alert-success';
              break;
            case 'CrqtSuspend':
              _className = 'alert alert-dark';
              break;
            default:
              _className = 'alert alert-primary';
          }
          return <div className={_className}>{rowData.requestTypeEnum}</div>
        }
      },
      {field: 'itemDescription', header: 'Gói dịch vụ', minWidth: 150, matchMode: 'contains'},
      {field: 'vendorPseudoId', header: 'NCC CTS', minWidth: 120, matchMode: 'contains'},
      {field: 'responseCode', header: 'Mã y/c NCC', minWidth: 120, matchMode: 'contains'},
      {
        field: 'status',
        header: 'Trạng thái',
        minWidth: 180,
        matchMode: 'contains',
        dataType: 'custom',
        customCell(rowData: any): any {
          const _className = certStatusAlert(rowData.statusId);
          let innerText;
          if (rowData.statusId === 'ReqRejected' && rowData.approvedText) {
            innerText = <p>Lý do: {rowData.approvedText}</p>;
          } else if (rowData.responseText) {
            innerText = <p>Lý do: {rowData.responseText}</p>;
          }
          return <div>
            <div className={_className}>{rowData.status}</div>
            {innerText}</div>
        }
      },
      {
        field: 'certStatus',
        header: 'Trạng thái CTS',
        minWidth: 150,
        matchMode: 'contains',
        dataType: 'custom',
        customCell(rowData: any): any {
          if (!rowData.certStatus) {
            return null;
          }
          return <span className={certStatusAlert(rowData.certStatusId)}>{rowData.certStatus}</span>
        }
      },
      {field: 'certCode', header: 'Mã cấp CTS', minWidth: 220, matchMode: 'contains'},
      {field: 'snToken', header: 'SN Token', minWidth: 160, matchMode: 'contains'},
      {field: 'certificateSn', header: 'SN Cert', minWidth: 260, matchMode: 'contains'},
      {field: 'lastUpdatedStamp', header: 'Ngày cập nhật', width: 150, dataType: 'date-time'},
      {field: 'enteredByPartyName', header: 'Người tạo', minWidth: 150, matchMode: 'contains'},
      {field: 'requestDate', header: 'Ngày gửi', width: 100, dataType: 'date'},
      {field: 'expireDate', header: 'Thời hạn CTS', width: 150, dataType: 'date'},
      {field: 'expireContractDate', header: 'Thời hạn dịch vụ', width: 150, dataType: 'date'}
    ],
    indexColumnWidth: 45,
    getList: lazyLoadEvent => {
      if (url) {
        const params = {
          ...searchForm.getValue(),
          statusCode,
          requestTypeEnumId,
          lazyLoadEvent
        }
        setLazyLoadParams(lazyLoadEvent);
        return Service.getList(url, lazyLoadEvent, params).then(data => {
          setRevokePerms(data.revokePerms);
          setApprovePerms(data.approvePerms);
          return data;
        });
      } else {
        return new Promise(resolve => resolve({}));
      }
    },
    originalProps: {
      onRowDoubleClick: e => {
        const {data} = e;
        if (data && data[dataKey]) {
          doUpdate(true);
        }
      }
    }
  });


  const doCreate = () => {
    setCrupItem(null);
    setReadOnly(false);
    setDisplayCrup(true);
  }

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const doDelete = () => {
    ConfirmDialogService.confirm('Xóa yêu cầu', 'Xác nhận xóa yêu cầu này?', () => {
      Service.delete(selectedItem[dataKey], {lastUpdatedStamp: selectedItem.lastUpdatedStamp}).then(() => {
        ToastService.success();
        refreshLazyData();
      });
    });
  }

  const doUpdate = (doView?: boolean) => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.get(selectedItem[dataKey]).then(data => {
        setCrupItem(data);
        setReadOnly(doView === true);
        setDisplayCrup(true);
      });
    }
  }

  const send = () => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.send(selectedItem[dataKey], selectedItem.lastUpdatedStamp).then(() => {
        ToastService.success();
        refreshLazyData();
      });
    }
  }

  const accept = () => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.accept(selectedItem[dataKey], selectedItem.lastUpdatedStamp).then(() => {
        ToastService.success();
        refreshLazyData();
        setDisplayCrup(null);
      });
    }
  }


  const {render: renderDialogReject, create: reject} = useDialogCrup({
    header: '!Từ chối yêu cầu',
    dataKey,
    submitLabel: 'Xác nhận',
    width: '40rem',
    fields: [
      {field: 'requestId', type: 'hidden'},
      {field: 'lastUpdatedStamp', type: 'hidden'},
      {field: 'approvedText', header: 'Lý do từ chối', required: true, className: 'md:col-12'}
    ],
    createItem: (item) => {
      return Service.reject(item);
    },
    reloadLazyData: () => {
      refreshLazyData();
      setDisplayCrup(null);
    }
  });

  const {render: renderCertCancelDialog, update: cancel} = useDialogCrup({
    header: '!Thu hồi/Hủy yêu cầu',
    dataKey,
    submitLabel: 'Xác nhận',
    width: '40rem',
    fields: [
      {
        field: 'certificateRevocationReason',
        header: 'Lý do Thu hồi/Hủy yêu cầu',
        required: true,
        className: 'md:col-12'
      }
    ],
    updateItem: (id, item) => {
      return Service.cancel(id, item);
    },
    reloadLazyData
  });
  const doCancel = () => {
    if (selectedItem && selectedItem[dataKey]) {
      const data = Object.assign({}, selectedItem);
      delete data.certificateRevocationReason;
      cancel(data);
    }
  }

  const {render: renderReApprove, create: reApprove} = useDialogCrup({
    header: '!Duyệt lại yêu cầu',
    dataKey,
    submitLabel: 'Xác nhận',
    width: '40rem',
    fields: [
      {field: 'requestId', type: 'hidden'},
      {field: 'actionTypeEnumId', type: 'hidden'}
    ],
    createItem: (item) => {
      item.actionTypeEnumId = 'LcsTicketAccepted';
      return Service.reApprove(item);
    },
    reloadLazyData
  });


  const doExportExcel = () => {
    exportExcel({thruDate: FormatDisplay.date(new Date(), 'YYYY-MM-DD')});
  }

  const {render: renderDialogExportExcel, create: exportExcel} = useDialogCrup({
    header: '!Xuất Excel',
    dataKey,
    submitLabel: 'Xác nhận',
    width: '40rem',
    fields: [
      {
        field: 'fromDate',
        header: 'Từ ngày',
        required: true,
        InputTextProps: {type: 'date'},
        className: 'md:col-12'
      },
      {
        field: 'thruDate',
        header: 'Đến ngày',
        required: true,
        InputTextProps: {type: 'date'},
        className: 'md:col-12'
      }
    ],
    createItem: item => {
      window.open(`${Service.baseURL}/${Service.entity}/excel?fromDate=${item.fromDate}&thruDate=${item.thruDate}&lazyLoadEvent=${lazyLoadParams}`);
      return Promise.resolve(item);
    },
    hideToastMessage: true,
    useSaveSplitButton: false
  });


  const downloadPdf = path => () => {
    if (selectedItem && selectedItem[dataKey]) {
      window.open(`${Service.baseURL}/${Service.entity}/${selectedItem[dataKey]}/${path}`);
    }
  }

  const doClear = () => {
    searchForm.setValue({
      fromDate: null,
      thruDate: null
    })
    setStatusCode(null)
    setRequestTypeEnumId(null)
    refreshLazyData()
  }
  const toggleDisplay = () => {
    if (display === true) {
      setDisplay(false)
    } else if (display === false) {
      setDisplay(true)
    }
  }

  const pdfMenu = useRef(null);

  const pdfMenus = [
    {label: 'Giấy đăng ký (ĐK01)', icon: 'pi pi-download', command: downloadPdf('xuatGiayDangKy')},
    {label: 'Giấy xác nhận (ĐK02)', icon: 'pi pi-download', command: downloadPdf('xuatGiayXacNhan')},
    {label: 'Giấy y/c TĐTT (ĐK03)', icon: 'pi pi-download', command: downloadPdf('xuatGiayThayDoi')},
    {label: 'Giấy chứng nhận', icon: 'pi pi-download', command: downloadPdf('xuatGiayChungNhan')},
    {label: 'Giấy chứng nhận (Có hình nền)', icon: 'pi pi-download', command: downloadPdf('xuatGiayChungNhan?withBackGround=Y')},
  ];


  const searchForm = useForm({
    fields: [
      {
        field: 'fromDate',
        header: 'Tạo từ ngày',
        InputTextProps: {type: 'date'},
        className: 'md:col-6 mt-2',
        inputClassName: 'md:col-12'
      },
      {
        field: 'thruDate',
        header: 'Đến ngày',
        InputTextProps: {type: 'date'},
        className: 'md:col-6 mt-2',
        inputClassName: 'md:col-12'
      },
    ],
    layout: 'Horizontal'
  });

  const downloadExcel = () => {
    ConfirmDialogService.confirm('Xuất excel', 'Xác nhận xuất Excel theo khoảng thời gian đã chọn ?', () => {
      if (!statusCode && !requestTypeEnumId) {
        window.open(`${Service.baseURL}/${Service.entity}/excel?fromDate=${searchForm.getValue().fromDate}&thruDate=${searchForm.getValue().thruDate}&lazyLoadEvent=${lazyLoadParams}`);
      } else if (statusCode && requestTypeEnumId) {
        window.open(`${Service.baseURL}/${Service.entity}/excel?fromDate=${searchForm.getValue().fromDate}&thruDate=${searchForm.getValue().thruDate}&statusCode=${statusCode}&requestTypeEnumId=${requestTypeEnumId}&lazyLoadEvent=${lazyLoadParams}`);
      } else if (statusCode) {
        window.open(`${Service.baseURL}/${Service.entity}/excel?fromDate=${searchForm.getValue().fromDate}&thruDate=${searchForm.getValue().thruDate}&statusCode=${statusCode}&lazyLoadEvent=${lazyLoadParams}`);
      } else {
        window.open(`${Service.baseURL}/${Service.entity}/excel?fromDate=${searchForm.getValue().fromDate}&thruDate=${searchForm.getValue().thruDate}&requestTypeEnumId=${requestTypeEnumId}&lazyLoadEvent=${lazyLoadParams}`);
      }
    })
  }

  const doSyncStatus = () => {
    ConfirmDialogService.confirm('Đồng bộ', 'Xác nhận đồng bộ trạng thái CTS từ CA ?', () => {
      if (selectedItem && selectedItem[dataKey]) {
        Service.syncStatus(selectedItem[dataKey]).then(() => {
          ToastService.success();
          refreshLazyData()
        })
      }
    })
  }

  const {renderToolbar} = useToolbar({
    doCreate: (IamPermission.has('CREATE') || ['Administrator', 'Agent'].includes(me.roleTypeId)) && doCreate,
    hasSelectedItem: selectedItem,
    doView: () => doUpdate(true),
    leftButtons: <Fragment>
      {(IamPermission.has('UPDATE') || ['Administrator', 'Agent'].includes(me.roleTypeId)) &&
				<Button label="Chỉnh sửa" icon="pi pi-pencil" severity="warning" size="small" onClick={() => doUpdate()}
								disabled={!['ReqCreated', 'ReqRejected', 'ReqSentFailed', 'ReqSentRejected', 'ReqSentDataInvalid'].includes(selectedItem?.statusId)}/>}
      <Button label="Gửi duyệt" icon="pi pi-send" size="small" onClick={send}
              disabled={!['ReqCreated', 'ReqRejected', 'ReqSentFailed', 'ReqSentRejected', 'ReqSentDataInvalid'].includes(selectedItem?.statusId)}/>
      {(approvePerms === 'Y') && ['Administrator', 'Agent'].includes(me.roleTypeId) &&
				<Fragment>
          {/*<Menu model={approveMenus} popup ref={approveMenu}/>*/}
          {/*<Button label="Chọn duyệt" severity="info" size="small"*/}
          {/*        onClick={e => approveMenu.current.toggle(e)} disabled={!['ReqSubmitted'].includes(selectedItem?.statusId)}/>*/}
					<Button label="Chấp nhận" icon="pi pi-check" severity="info" size="small" onClick={accept}
									disabled={!['ReqSubmitted'].includes(selectedItem?.statusId)}/>
					<Button label="Từ chối" icon="pi pi-times" severity="warning" size="small"
									onClick={() => reject(selectedItem)}
									disabled={!['ReqSubmitted'].includes(selectedItem?.statusId)}/>
					<Button label="Duyệt lại" icon="pi pi-send" severity="warning" size="small"
									onClick={() => reApprove(selectedItem)}
									disabled={!['ReqCaApprError'].includes(selectedItem?.statusId)}/>
				</Fragment>
      }
      {/*{(revokePerms === 'Y') &&*/}
      {/*	<Fragment>*/}
      {/*		<Button label="Thu hồi/Hủy" icon="pi pi-ban" severity="danger" size="small" onClick={doCancel}*/}
      {/*						disabled={!selectedItem}/>*/}
      {/*	</Fragment>*/}
      {/*}*/}

      {(IamPermission.has('DELETE') || ['Administrator', 'Agent'].includes(props.me.roleTypeId)) &&
				<Button label="Xóa" icon="pi pi-trash" severity="danger" size="small" onClick={doDelete}
								disabled={!['ReqCreated', 'ReqRejected', 'ReqSentFailed', 'ReqSentRejected', 'ReqSentDataInvalid'].includes(selectedItem?.statusId)}/>}
      <Button label="Lịch sử phê duyệt" icon="pi pi-history" severity="secondary" size="small"
              onClick={() => setDisplayHistories(selectedItem)}
              disabled={!selectedItem || ['ReqCreated'].includes(selectedItem?.statusId)}/>
      <Menu className="w-19rem" model={pdfMenus} popup ref={pdfMenu}/>
      <Button label="Tải file" icon="pi pi-file-pdf" severity="secondary" size="small"
              onClick={e => pdfMenu.current.toggle(e)} disabled={!selectedItem}/>
      <Button label="Đồng bộ TT" icon="pi pi-sync" severity="secondary" size="small" onClick={doSyncStatus} disabled={!selectedItem}/>
    </Fragment>
  });

  return (
    <div className="grid h-max">
      <div className="col-6 ml-3">
        {!status && searchForm.render()}
      </div>
      <div className="col-5 mt-2">
        {!status && <Fragment>
					<Button label="Tìm kiếm" icon="pi pi-search" size="small" severity="info" className="w-8rem h-2rem  ml-3 mt-5" onClick={refreshLazyData} visible={!status}/>
					<Button label="Reset" icon="pi pi-times" size="small" outlined severity="secondary" className=" w-6rem h-2rem ml-2" onClick={doClear} visible={!status}/>
          {(me?.roleTypeId === 'Administrator') &&
						<Fragment>
							<Button label="Xuất Excel" icon="pi pi-file-excel" severity="secondary" size="small" className="h-2rem ml-2"
											onClick={downloadExcel}/>
						</Fragment>
          }
          {display === false &&
						<Button label="Thêm điều kiện" icon="pi pi-angle-double-down" size="small" severity="secondary" className=" h-2rem  ml-2 mt-5 w-10rem" onClick={toggleDisplay} visible={!status}/>}
          {display === true &&
						<Button label="Thu gọn" icon="pi pi-angle-double-up" size="small" severity="secondary" className=" h-2rem  ml-2 mt-5 w-10rem" onClick={toggleDisplay} visible={!status}/>}
				</Fragment>}
      </div>
      {display === true &&
				<div className="col-12">
          {!status && <Dropdown className="md:w-21rem ml-3 " showClear value={statusCode} onChange={(e) => setStatusCode(e.value)} options={requestStatus} placeholder="Tìm kiếm theo trạng thái"></Dropdown>}
          {!status && <Dropdown className="md:w-21rem ml-5" showClear value={requestTypeEnumId} onChange={(e) => setRequestTypeEnumId(e.value)} options={requestType} placeholder="Tìm kiếm theo loại giao dịch"></Dropdown>}
				</div>}
      <div className="col-12">
        {renderToolbar()}
      </div>
      <div className="col-12 py-0" style={{maxHeight: 'calc(100vh - 15.5rem)'}}>
        {renderDataTable()}
      </div>
      <Crup me={me} display={displayCrup} setDisplay={setDisplayCrup} selectedItem={crupItem} readOnly={readOnly}
            accept={accept} reject={reject} reloadLazyData={reloadLazyData}/>

      <Histories display={displayHistories} setDisplay={setDisplayHistories}/>

      {renderDialogReject()}
      {renderDialogExportExcel()}
      {renderCertCancelDialog()}
      {renderReApprove()}
    </div>
  );
}

export const certStatusAlert = statusId => {
  let _className;
  switch (statusId) {
    default:
      if (statusId.includes('InProcess')
        || statusId.includes('Passed')
        || statusId.includes('Init')
        || statusId.includes('Submitted')
      ) {
        _className = 'alert alert-dark';
      } else if (statusId.includes('Created') || statusId.includes('Recovered')
        || statusId.includes('Revoked') || statusId.includes('Extended')
        || statusId.includes('Changed') || statusId.includes('Reissued')) {
        _className = 'alert alert-success';
      } else if (
        statusId.includes('Accepted')
      ) {
        _className = 'alert alert-info';
      } else if (statusId.includes('Rejected') || statusId.includes('Declined')
        || statusId.includes('Suspended') || statusId.includes('Error')) {
        _className = 'alert alert-danger';
      } else {
        _className = 'alert alert-light ' + statusId;
      }
  }
  return _className;
}