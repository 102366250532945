import {Route, Routes} from 'react-router-dom';

import {DanhSach} from './danh-sach';
import {CongNoQuaHan} from './cong-no-qua-han';

export const ThanhToan = () => {

  return (
    <Routes>
      <Route path="danh-sach" element={<DanhSach/>}/>
      <Route path="cong-no-qua-han" element={<CongNoQuaHan type={0}/>}/>
      <Route path="cong-no-cap-tren-qua-han" element={<CongNoQuaHan type={2}/>}/>
    </Routes>
  );
}