import {EntityService} from '../../service/EntityService';

export class EmployeeService extends EntityService {

    static entity = 'vinca-party/agent-employees';

    // static getList(lazyLoadEvent: string,status:string): Promise<any> {
    //     return this.axios.get(`${this.entity}/list?lazyLoadEvent=${lazyLoadEvent}&disabled=${status}`).then(res => res.data);
    // }

    static find(term: string): Promise<any> {
        return this.axios.get(`${this.entity}/find`, {params: {term}}).then(res => res.data);
    }

    static setPermissions(partyId: string, userGroups: any): Promise<any> {
        return this.axios.put(`${this.entity}/${partyId}/perms-grant`, userGroups).then(res => res.data);
    }
    static getPermissions(partyId: string): Promise<any> {
        return this.axios.get(`${this.entity}/${partyId}/perms-grant`).then(res => res.data);
    }
    static privilegeList(): Promise<any> {
        return this.axios.get(`/vinca-common/user-account/grant-privilege`).then(res => res.data);
    }
    static disable(id: string): Promise<any> {
        return this.axios.put(`${this.entity}/${id}/lock`).then(res => res.data);
    }
    static enable(id: string): Promise<any> {
        return this.axios.put(`${this.entity}/${id}/unlock`).then(res => res.data);
    }
    static resetPassword(id: string, password: string): Promise<any> {
        return this.axios.put(`${this.entity}/${id}/reset-password`,{password}).then(res => res.data);
    }


}
