import {useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {ToastService, useDataTable} from '@iamsoftware/react-hooks';

import {CategoryService as Service} from './CategoryService';

export const AddProducts = ({display, setDisplay, reloadProducts}) => {

  const header = 'Sản phẩm';
  const dataKey = 'productId';

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (display) {
      reloadLazyData();
    }
  }, [display]); // eslint-disable-line react-hooks/exhaustive-deps

  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    dataKey,
    columns: [
      {field: 'ownerPartyId', header: 'Thuộc sở hữu', width: 150, matchMode: 'contains'},
      {field: 'pseudoId', header: `Mã ${header}`, width: 250, matchMode: 'contains'},
      {field: 'productName', header: `Tên ${header}`, width: 150, matchMode: 'contains'},
      {field: 'mainCategoryName', header: 'Danh mục', width: 250, matchMode: 'contains'}
    ],
    indexColumnWidth: 45,
    getList: lazyLoadEvent => {
      return new Promise<any>((resolve, reject) => {
        if (display) {
          resolve(display);
        } else {
          reject();
        }
      }).then(productCategoryId => Service.getProductsNotInCategory(productCategoryId, lazyLoadEvent));
    },
    selectionMode: 'checkbox'
  });

  const add = () => {
    if (!submitting && selectedItems?.length) {

      setSubmitting(true);

      Service.addProductsToCategory(display, selectedItems.map(item => item[dataKey])).then(({messages}) => {
        ToastService.success(messages);
        setDisplay(false);
        reloadProducts();
      }).finally(() => {
        setSubmitting(false);
      });
    }
  }

  const footer = (
    <div>
      <Button label={`Thêm ${selectedItems?.length || 0} sản phẩm`} icon="pi pi-check" type="button" size="small" onClick={add} loading={submitting}/>
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(false)}/>
    </div>
  );

  return (
    <Dialog header={header} footer={footer} visible={!!display} style={{width: '80vw'}} position="top" onHide={() => setDisplay(false)}>
      <div className="grid">
        <div className="col-12" style={{maxHeight: 'calc(100vh - 20rem)'}}>
          {renderDataTable()}
        </div>
      </div>
    </Dialog>
  );
}