import {Service} from './Service';

export class AuthService extends Service {

  static entity: string = 'vinca-auth';

  static login(username: string, password: string): Promise<any> {
    return this.axios.post(`${this.entity}/login`, {username, password}).then(res => res.data);
  }

  static changePassword(data: any): Promise<any> {
    return this.axios.post(`${this.entity}/change-password`, data).then(res => res.data);
  }

  static logout(): Promise<any> {
    return this.axios.post(`${this.entity}/logout`).then(res => res.data);
  }

  static getToken(): Promise<any> {
    return this.axios.get(`${this.entity}/token`).then(res => res.data);
  }

  static resetPassword(username: string, emailAddress: string): Promise<any> {
    return this.axios.put(`${this.entity}/reset-password`, {username, emailAddress}).then(res => res.data);
  }

}
