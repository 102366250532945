import {Fragment, useEffect, useState} from 'react';

import {ConfirmDialogService, ToastService, useDataTable, useDataTableBasic, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';
import {PDFFile} from './PDFfile';
import {InvoiceService as Service} from '../InvoiceService'


const dataKey = 'invoiceId';

export const DaXuatHD = () => {
  const [displayPDFFile, setDisplayPDFFile] = useState(null);

  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    dataKey,
    columns: [
      {field: 'toPartyTaxId', header: 'MST/CCCD/CMND', minWidth: 100, matchMode: 'contains'},
      {field: 'toPartyName', header: 'Tên khách hàng', minWidth: 200, matchMode: 'contains'},
      {field: 'itemDescription', header: 'Gói dịch vụ', minWidth: 150, matchMode: 'contains'},
      {field: 'amount', header: 'Tổng tiền', minWidth: 100, matchMode: 'contains', dataType: 'number'},
      {field: 'effectiveDate', header: 'Ngày hiệu lực CTS', minWidth: 100, matchMode: 'contains', dataType: 'date'},
      {field: 'certificateSn', header: 'SN Cert', minWidth: 100, matchMode: 'contains'},
      {field: 'fromPseudoId', header: 'NCC CTS', minWidth: 100, matchMode: 'contains'},
      {
        field: 'invoice', header: 'Ký hiệu HĐ', minWidth: 100, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return <div>
            {rowData.invoiceForm} - {rowData.invoiceSerial}
          </div>
        }
      },
      {field: 'invoiceNoInt', header: 'Số hóa đơn', minWidth: 100, matchMode: 'contains',},
      {field: 'invoiceDate', header: 'Ngày hóa đơn', minWidth: 100, matchMode: 'contains', dataType: 'date'},
      {field: 'externalId', header: 'Mã CQT', minWidth: 100, matchMode: 'contains',},
      {field: 'referenceNumber', header: 'Mã tra cứu', minWidth: 100, matchMode: 'contains',},
    ],
    getList: lazyLoadEvent => {
      return Service.certInvoiceList('issued', lazyLoadEvent)
    },
    actionColumnWidth: 120,
    initActions(items: Array<any>) {
      if (items) {
        items.forEach(item => {
          item.actions = [
            {icon: 'pi pi-file-pdf', title: 'Xem PDF', className: 'p-button-danger', command: () => setDisplayPDFFile(item)},
            {icon: 'pi pi-replay', title: 'Đồng bộ TT', className: 'p-button-info', command: () => doSync(item)},
          ]
        })
      }
    },
  });
  const viewPDF = () => {
    if (selectedItem && selectedItem[dataKey]) {
      setDisplayPDFFile(selectedItem);
    }
  }
  const doSync = (input) => {
    ConfirmDialogService.confirm('Xác nhận', 'Xác nhận đồng bộ thông tin hóa đơn này?', () => {
      Service.certSyncHeader(input.invoiceId).then(() => {
        ToastService.success();
        reloadLazyData();
      })
    })
  }

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  return (
    <div className="grid">
      <div className="col-12">
      </div>
      <div className="col-12 py-0" style={{maxHeight: 'calc(100vh - 15.5rem)'}}>
        {renderDataTable()}
      </div>
      <PDFFile display={displayPDFFile} setDisplay={setDisplayPDFFile}></PDFFile>
    </div>
  );
}

