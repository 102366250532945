import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {SelectButton} from 'primereact/selectbutton';
import {ConfirmDialogService, ToastService, useDataTable, useDataTableBasic, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';
import {PDFFile} from '../cts-da-xuat-hd/PDFfile';
import {InvoiceService as Service} from '../InvoiceService'
import {InvoiceDetail} from './Detail';

const dataKey = 'invoiceId';
const statuses = [
  {value: 'processing', label: 'Đang xử lý'},
  {value: 'expired', label: 'Hết hạn'}
];

export const CanXuatHD = () => {
  const [statusId, setStatusId] = useState('processing');
  const [displayPDFFile, setDisplayPDFFile] = useState(null);
  const [displayDetail, setDisplayDetail] = useState(null);

  useEffect(() => {
    if (statusId) {
      reloadLazyData();
    } else {
      setStatusId(statuses[0].value);
    }
  }, [statusId]);

  const {render: renderDataTable, reloadLazyData} = useDataTable({
    dataKey,
    columns: [
      {field: 'toPartyTaxId', header: 'MST/CCCD/CMND', minWidth: 140, matchMode: 'contains'},
      {field: 'toPartyName', header: 'Tên khách hàng', minWidth: 100, matchMode: 'contains'},
      {field: 'itemDescription', header: 'Gói dịch vụ', minWidth: 100, matchMode: 'contains'},
      {field: 'amount', header: 'Tổng tiền', minWidth: 75, matchMode: 'contains', dataType:'number'},
      {field: 'effectiveDate', header: 'Ngày hiệu lực CTS', minWidth: 100, matchMode: 'contains', dataType: 'date'},
      {field: 'invoiceDeadline', header: 'Hạn xuất HĐ', minWidth: 100, matchMode: 'contains', dataType: 'date'},
      {field: 'certificateSn', header: 'SN Cert', minWidth: 100, matchMode: 'contains'},
      {field: 'fromPseudoId', header: 'NCC CTS', minWidth: 100, matchMode: 'contains'},
      {field: 'status', header: 'Trạng thái', minWidth: 100, matchMode: 'contains', hidden: statusId === 'expired'},
    ], actionColumnWidth: 120,
    initActions(items: Array<any>) {
      if (items) {
        items.forEach(item => {
          item.actions = [
            {icon: "pi pi-pencil", title: 'Chỉnh sửa', className: 'p-button-warning', command: () => setDisplayDetail(item)},
          ]
          if (item?.statusId === 'InvoiceDraft') {
            item.actions.push({icon: 'pi pi-file-pdf', title: 'Xem PDF', className: 'p-button-danger', command: () => setDisplayPDFFile(item)})
            item.actions.push({icon: 'pi pi-file-edit', title: 'Ký HĐ', className: 'p-button-danger', command: () => invoiceIssue(item)})
          }
        })
      }
    },
    getList: lazyLoadEvent => {
      if (statusId) {
        return Service.certInvoiceList(statusId, lazyLoadEvent)
      } else {
        return Promise.resolve({});
      }
    }
  });

  const invoiceIssue = (input) => {
    ConfirmDialogService.confirm('Xóa file', 'Xác nhận ký hóa đơn này?', () => {
      const inputData = {
        "invoiceId": input.invoiceId
      }
      Service.invoiceIssue(inputData).then(() => {
        ToastService.success();
      });
    });
  }
  return (
    <div className="grid">
      <div className="col-12 pb-0">
        <SelectButton value={statusId} options={statuses} onChange={e => setStatusId(e.value)}/>
      </div>
      <div className="col-12 py-0" style={{maxHeight: 'calc(100vh - 15.5rem)'}}>
        {renderDataTable()}
      </div>
      <PDFFile display={displayPDFFile} setDisplay={setDisplayPDFFile}></PDFFile>
      <InvoiceDetail display={displayDetail} setDisplay={setDisplayDetail} reloadLazyData={reloadLazyData}/>
    </div>
  );
}

