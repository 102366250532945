import {Route, Routes} from 'react-router-dom';

import {HopDong} from './hop-dong';

export const ChungTu = () => {

  return (
    <Routes>
      <Route path="hop-dong" element={<HopDong/>}/>
    </Routes>
  );
}