import {Service} from '../../service/Service';

export class IssueTypeService extends Service {

  static entity = 'https://mock.iamsoftware.com.vn/vin-crm/docs/issue-types';

  static getList(lazyLoadEvent: string, params?: any): Promise<any> {
    return this.axios.get(`${this.entity}?lazyLoadEvent=${lazyLoadEvent}`, {params})
      .then(res => res.data)
      .then(data => {
        return {listData: data.items, totalRecords: data.totalRecords}
      });
  }

  static create(data: any): Promise<any> {
    return this.axios.post(`${this.entity}`, data).then(res => res.data);
  }

  static update(id: string, data: any): Promise<any> {
    return this.axios.put(`${this.entity}/${id}`, data).then(res => res.data);
  }

}
