import React, {Fragment, useRef, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {Toast} from 'primereact/toast';
import {ConfirmDialogService, ToastService, useDataTable, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {MessageService as Service} from "../MessageService";
import {Histories} from "./Histories";

export const CrupCertList = ({display, setDisplay}) => {
  const header = (
    <div>
      <div className='flex justify-content-center'>
        <h4>Danh sách CTS cần gửi tin nhắn Zalo</h4>
      </div>
    </div>
  );
  const footer = (
    <div className='flex justify-content-center'>
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" onClick={() => setDisplay(false)}/>
    </div>
  );

  return (
    <Dialog header={header} footer={footer} visible={!!display} style={{width: '100%'}} position="top" onHide={() => setDisplay(null)}>
      <Screen workEffortId={display?.workEffortId}/>
    </Dialog>
  );
}

const Screen = ({workEffortId}) => {
  const [partyCertId, setPartyCertId] = useState(null);
  const [displayHistories, setDisplayHistories] = useState(null);
  const updateTooltip = 'Cập nhật SĐT nhận tin nhắn';
  const resendTooltip = 'Gửi lại tin nhắn';
  const historyTooltip = 'Lịch sử gửi tin nhắn của CTS';
  const toast = useRef(null);


  // eslint-disable-line react-hooks/exhaustive-deps

  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    dataKey: 'partyCertId',
    columns: [
      {
        field: 'productNameWithPseudoId', header: 'Action', width: 150, dataType: 'custom', customCell(rowData: any): any {
          return <div>
            <Button className="pi pi-pencil ml-2" size='small' severity="warning" tooltip={updateTooltip}
                    onClick={() => doUpdate(rowData.partyCertId)}></Button>
            <Button className="pi pi-replay ml-2" size='small' severity="success" tooltip={resendTooltip}
                    onClick={() => doResend(rowData.partyCertId)}></Button>
            <Button className="pi pi-history ml-2" size='small' severity="info" tooltip={historyTooltip}
                    onClick={() => setDisplayHistories(rowData)}></Button>
          </div>
        }
      },
      {field: 'pseudoId', header: 'Mã khách hàng', width: 100, matchMode: 'contains'},
      {field: 'partyName', header: 'Tên khách hàng', width: 250, matchMode: 'contains'},
      {field: 'productName', header: 'Gói dịch vụ', width: 100, matchMode: 'contains'},
      {field: 'snToken', header: 'snToken', width: 200, matchMode: 'contains'},
      {field: 'certificateSn', header: 'CertSn', width: 200, matchMode: 'contains'},
      {field: 'expireDate', header: 'Ngày hết hạn', width: 100, matchMode: 'contains', dataType: "date"},
      {field: 'contactNumber', header: 'Số điện thoại', width: 100, matchMode: 'contains',},
      {field: 'sentMessageCount', header: 'Số lần gửi', width: 100, matchMode: 'contains', dataType: "number"},
      {field: 'status', header: 'Trạng thái', width: 100, matchMode: 'contains'},
      {field: 'sentDate', header: 'Ngày gửi', width: 120, matchMode: 'contains', dataType: "date"},
    ],
    getList: lazyLoadEvent => {
      return Service.GetCertList(workEffortId, lazyLoadEvent);
    },
    selectionMode: 'checkbox'
  });


  const {render: renderUpdateNumber, create: updateNumber} = useDialogCrup({
    header: '!Đổi số điện thoại',
    submitLabel: 'Xác nhận',
    width: '40rem',
    fields: [
      {field: 'contactNumber', header: 'Số điện thoại mới', required: true, className: 'md:col-12'},
    ],
    createItem: item => {
      return Service.updatePhoneNumber(workEffortId, partyCertId, item).then(
        reloadLazyData
      );
    },
    checkResponseError: data => {
      let error = '';
      if (data?.isSuccess === 'N') {
        error = data.messages;
      }
      return error;
    }
  });

  const onSubmit = event => {
    if (selectedItems?.length) {
      let x = {
        partyCertIds: selectedItems.map(item => {
          return item.partyCertId
        })
      }
      const input = {
        ...x
      }
      Service.sendMessage(workEffortId, input).then(messages => {
        let success = 'Số lượng gửi thành công: ' + messages.success
        let fail = ' Số lượng gửi thất bại: ' + messages.fail
        toast.current.show({
          severity: 'info',
          summary: 'Kết quả:',
          detail: <div>
            <h6>{success}</h6>
            <h6>{fail}</h6>
          </div>, life: 3000
        })
        reloadLazyData();
      })
    } else {
      ToastService.error('Chưa chọn CTS.');
    }
    event.preventDefault();
    reloadLazyData();
  }
  const doDelete = () => {
    if (selectedItems?.length) {
      ConfirmDialogService.confirm('Xác nhận', 'Xác nhận xóa CTS đã chọn khỏi danh sách?', () => {
        let x = {
          partyCertIds: selectedItems.map(item => {
            return item.partyCertId
          })
        }
        const input = {
          ...x
        }
        Service.removeFromList(workEffortId, input).then(() => {
          ToastService.success();
          reloadLazyData();
        })
      });
    } else {
      ToastService.error('Chưa chọn CTS.');
    }
  }
  const doSendToAll = () => {
    ConfirmDialogService.confirm('Xác nhận', 'Xác nhận gửi tin nhắn cho toàn bộ CTS trong danh sách?', () => {
      Service.sendMessageToAll(workEffortId).then(messages => {
        let success = 'Số lượng gửi thành công: ' + messages.success
        let fail = ' Số lượng gửi thất bại: ' + messages.fail
        toast.current.show({
          severity: 'info', summary: 'Kết quả', detail: <div>
            <h6>{success}</h6>
            <h6>{fail}</h6>
          </div>, life: 3000
        })
        reloadLazyData();
        reloadLazyData();
      })
    })
  }
  const doUpdate = (partyCertId) => {
    setPartyCertId(partyCertId);
    updateNumber(partyCertId);
  }
  const doResend = (partyCertId) => {
    ConfirmDialogService.confirm('Xác nhận', 'Gửi lại tin nhắn đến CTS này?', () => {
      Service.resendMessage(workEffortId, partyCertId).then(() => {
        ToastService.success();
        reloadLazyData();
      })
    })
  }
  const {renderToolbar} = useToolbar({
    leftButtons: <Fragment>
      <Button label="Gửi tin nhắn đến CTS đã chọn" icon="pi pi-send" size='small' className="mr-3" onClick={onSubmit}/>
      <Button label="Gửi tin nhắn đến tất cả CTS" icon="pi pi-send" size='small' className="mr-3" severity="info" onClick={doSendToAll}/>
      <Button label="Xóa CTS đã chọn khỏi danh sách" icon="pi pi-times" size='small' severity="danger" className="mr-3" onClick={doDelete}/>
    </Fragment>
  });

  return (<div className="grid pt-2">
      <Histories display={displayHistories} setDisplay={setDisplayHistories}/>
      <Toast ref={toast}/>
      <div className="col-12">
        {renderToolbar()}
      </div>
      {renderDataTable()}
      {renderUpdateNumber()}
    </div>
  )
}