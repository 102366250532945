import {useState, useEffect, useRef} from 'react';
import {CSSTransition} from 'react-transition-group';

import PrimeReact from 'primereact/api';
import {classNames} from 'primereact/utils';

import {CommonService} from "../service/CommonService";

import AppTopbar from './AppTopbar';
import AppRightMenu from './AppRightMenu';
import AppMenu from './AppMenu';
import AppConfig from './AppConfig';
import {IamPermission} from './../shared/utils/Permission'
import {App} from './App';
import {EmployeeService as Service} from "./nhan-vien/EmployeeService";

export const AppWrapper = () => {

  const [layoutMode, setLayoutMode] = useState('static');
  const [lightMenu, setLightMenu] = useState(true);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
  const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
  const [isRTL, setIsRTL] = useState(false);
  const [inlineUser, setInlineUser] = useState(false);
  const [topbarMenuActive, setTopbarMenuActive] = useState(false);
  const [activeTopbarItem, setActiveTopbarItem] = useState(null);
  const [rightPanelMenuActive, setRightPanelMenuActive] = useState(null);
  const [inlineUserMenuActive, setInlineUserMenuActive] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const [topbarColor, setTopbarColor] = useState('layout-topbar-blue');
  const [theme, setTheme] = useState('blue');
  const [configActive, setConfigActive] = useState(false);
  const [inputStyle, setInputStyle] = useState('filled');
  const [ripple, setRipple] = useState(false);
  const inlineUserRef = useRef();

  const [me, setMe] = useState(null);
  const [privilege, setPrivilege] = useState(null)

  let topbarItemClick: boolean;
  let menuClick: boolean;
  let rightMenuClick: boolean;
  let userMenuClick: boolean;
  let configClick = false;

  useEffect(() => {
    CommonService.getMe().then(data => {
      Service.privilegeList().then(({listData}) => {
        setMe(data);
        IamPermission.init(listData);
      });
    }).catch(() => {
    });
  }, []);


  useEffect(() => {
    if (staticMenuMobileActive) {
      blockBodyScroll();
    } else {
      unblockBodyScroll();
    }
  }, [staticMenuMobileActive]);

  const onInputStyleChange = (inputStyle) => {
    setInputStyle(inputStyle);
  };

  const onRippleChange = (e) => {
    PrimeReact.ripple = e.value;
    setRipple(e.value);
  };

  const onDocumentClick = () => {
    if (!topbarItemClick) {
      setActiveTopbarItem(null);
      setTopbarMenuActive(false);
    }

    if (!rightMenuClick) {
      setRightPanelMenuActive(false);
    }

    if (!userMenuClick && isSlim() && !isMobile()) {
      setInlineUserMenuActive(false);
    }

    if (!menuClick) {
      if (isHorizontal() || isSlim()) {
        setMenuActive(false);
      }

      if (overlayMenuActive || staticMenuMobileActive) {
        hideOverlayMenu();
      }

      unblockBodyScroll();
    }

    if (configActive && !configClick) {
      setConfigActive(false);
    }

    topbarItemClick = false;
    menuClick = false;
    rightMenuClick = false;
    userMenuClick = false;
    configClick = false;
  };

  const onMenuButtonClick = (event) => {
    menuClick = true;
    setTopbarMenuActive(false);
    setRightPanelMenuActive(false);

    if (layoutMode === 'overlay') {
      setOverlayMenuActive((prevOverlayMenuActive) => !prevOverlayMenuActive);
    }

    if (isDesktop()) setStaticMenuDesktopInactive((prevStaticMenuDesktopInactive) => !prevStaticMenuDesktopInactive);
    else {
      setStaticMenuMobileActive((prevStaticMenuMobileActive) => !prevStaticMenuMobileActive);
      if (staticMenuMobileActive) {
        blockBodyScroll();
      } else {
        unblockBodyScroll();
      }
    }

    event.preventDefault();
  };

  const onTopbarMenuButtonClick = (event) => {
    topbarItemClick = true;
    setTopbarMenuActive((prevTopbarMenuActive) => !prevTopbarMenuActive);
    hideOverlayMenu();
    event.preventDefault();
  };

  const onTopbarItemClick = (event) => {
    topbarItemClick = true;

    if (activeTopbarItem === event.item) setActiveTopbarItem(null);
    else setActiveTopbarItem(event.item);

    event.originalEvent.preventDefault();
  };

  const onMenuClick = () => {
    menuClick = true;
  };

  const onInlineUserClick = () => {
    userMenuClick = true;
    setInlineUserMenuActive((prevInlineUserMenuActive) => !prevInlineUserMenuActive);
    setMenuActive(false);
  };

  const onConfigClick = () => {
    configClick = true;
  };

  const onConfigButtonClick = () => {
    setConfigActive((prevConfigActive) => !prevConfigActive);
    configClick = true;
  };

  const blockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.add('blocked-scroll');
    } else {
      document.body.className += ' blocked-scroll';
    }
  };

  const unblockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.remove('blocked-scroll');
    } else {
      document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }
  };

  const onRightMenuButtonClick = (event) => {
    rightMenuClick = true;
    setRightPanelMenuActive((prevRightPanelMenuActive) => !prevRightPanelMenuActive);

    hideOverlayMenu();

    event.preventDefault();
  };

  const onRightMenuClick = () => {
    rightMenuClick = true;
  };

  const hideOverlayMenu = () => {
    setOverlayMenuActive(false);
    setStaticMenuMobileActive(false);
  };

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      hideOverlayMenu();
    }
    if (!event.item.items && (isHorizontal() || isSlim())) {
      setMenuActive(false);
    }
  };

  const onRootMenuItemClick = () => {
    setMenuActive((prevMenuActive) => !prevMenuActive);
    setInlineUserMenuActive(false);
  };

  const isDesktop = () => {
    return window.innerWidth > 896;
  };

  const isMobile = () => {
    return window.innerWidth <= 1025;
  };

  const isHorizontal = () => {
    return layoutMode === 'horizontal';
  };

  const isSlim = () => {
    return layoutMode === 'slim';
  };

  const onLayoutModeChange = (layoutMode) => {
    setLayoutMode(layoutMode);
    setStaticMenuDesktopInactive(false);
    setOverlayMenuActive(false);

    if (layoutMode === 'horizontal' && inlineUser) {
      setInlineUser(false);
    }
  };

  const onMenuColorChange = (menuColor) => {
    setLightMenu(menuColor);
  };

  const onThemeChange = (theme) => {
    setTheme(theme);
  };

  const onProfileModeChange = (profileMode) => {
    setInlineUser(profileMode);
  };

  const onOrientationChange = (orientation) => {
    setIsRTL(orientation);
  };

  const onTopbarColorChange = (color) => {
    setTopbarColor(color);
  };

  const layoutClassName = classNames(
    'layout-wrapper',
    {
      'layout-horizontal': layoutMode === 'horizontal',
      'layout-overlay': layoutMode === 'overlay',
      'layout-static': layoutMode === 'static',
      'layout-slim': layoutMode === 'slim',
      'layout-menu-light': lightMenu,
      'layout-menu-dark': !lightMenu,
      'layout-overlay-active': overlayMenuActive,
      'layout-mobile-active': staticMenuMobileActive,
      'layout-static-inactive': staticMenuDesktopInactive,
      'layout-rtl': isRTL,
      'p-input-filled': inputStyle === 'filled',
      'p-ripple-disabled': !ripple
    },
    topbarColor
  );

  const inlineUserTimeout = layoutMode === 'slim' ? 0 : {enter: 1000, exit: 450};

  if (!me) {
    return null;
  }

  return (
    <div className={layoutClassName} onClick={onDocumentClick}>
      <AppTopbar me={me}
                 topbarMenuActive={topbarMenuActive}
                 activeTopbarItem={activeTopbarItem}
                 inlineUser={inlineUser}
                 onRightMenuButtonClick={onRightMenuButtonClick}
                 onMenuButtonClick={onMenuButtonClick}
                 onTopbarMenuButtonClick={onTopbarMenuButtonClick}
                 onTopbarItemClick={onTopbarItemClick}
                 onConfigButtonClick={onConfigButtonClick}
      />

      <AppRightMenu rightPanelMenuActive={rightPanelMenuActive} onRightMenuClick={onRightMenuClick}></AppRightMenu>

      <div className="layout-menu-container" onClick={onMenuClick}>
        {inlineUser && (
          <div className="layout-profile">
            <button type="button" className="p-link layout-profile-button" onClick={onInlineUserClick}>
              <img src="/assets/layout/images/avatar.png" alt="roma-layout"/>
              <div className="layout-profile-userinfo">
                <span className="layout-profile-name">Arlene Welch</span>
                <span className="layout-profile-role">Design Ops</span>
              </div>
            </button>
            <CSSTransition nodeRef={inlineUserRef} classNames="p-toggleable-content" timeout={inlineUserTimeout} in={inlineUserMenuActive} unmountOnExit>
              <ul ref={inlineUserRef} className={classNames('layout-profile-menu', {'profile-menu-active': inlineUserMenuActive})}>
                <li>
                  <button type="button" className="p-link">
                    <i className="pi pi-fw pi-user"></i>
                    <span>Profile</span>
                  </button>
                </li>
                <li>
                  <button type="button" className="p-link">
                    <i className="pi pi-fw pi-cog"></i>
                    <span>Settings</span>
                  </button>
                </li>
                <li>
                  <button type="button" className="p-link">
                    <i className="pi pi-fw pi-envelope"></i>
                    <span>Messages</span>
                  </button>
                </li>
                <li>
                  <button type="button" className="p-link">
                    <i className="pi pi-fw pi-bell"></i>
                    <span>Notifications</span>
                  </button>
                </li>
              </ul>
            </CSSTransition>
          </div>
        )}
        <AppMenu onMenuItemClick={onMenuItemClick} onRootMenuItemClick={onRootMenuItemClick} layoutMode={layoutMode} active={menuActive} mobileMenuActive={staticMenuMobileActive} me={me}/>
      </div>

      <div className="layout-main">
        <div className="layout-content pb-0">
          <App me={me}/>
        </div>
        <AppConfig
          configActive={configActive}
          onConfigClick={onConfigClick}
          onConfigButtonClick={onConfigButtonClick}
          rippleActive={ripple}
          onRippleChange={onRippleChange}
          inputStyle={inputStyle}
          onInputStyleChange={onInputStyleChange}
          theme={theme}
          onThemeChange={onThemeChange}
          topbarColor={topbarColor}
          onTopbarColorChange={onTopbarColorChange}
          inlineUser={inlineUser}
          onProfileModeChange={onProfileModeChange}
          isRTL={isRTL}
          onOrientationChange={onOrientationChange}
          layoutMode={layoutMode}
          onLayoutModeChange={onLayoutModeChange}
          lightMenu={lightMenu}
          onMenuColorChange={onMenuColorChange}
        ></AppConfig>
      </div>

      <div className="layout-content-mask"></div>
    </div>
  );
}
