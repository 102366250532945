import {Fragment, useEffect, useState} from 'react';

import {Chart} from 'primereact/chart';
import {Dropdown} from 'primereact/dropdown';

import moment from 'moment';
import {DashboardService} from "./DashboardService";

const periods = [
  {value: '1', label: 'Hôm nay'},
  {value: '2', label: 'Tuần này'},
  {value: '3', label: 'Tháng này'},
];
const dateList = [
  {value: moment().add(3, 'months').startOf('day').format('x'), label: '3 tháng'},
  {value: moment().add(6, 'months').startOf('day').format('x'), label: '6 tháng'},
  {value: moment().add(9, 'months').startOf('day').format('x'), label: '9 tháng'},
  {value: moment().add(12, 'months').startOf('day').format('x'), label: '12 tháng'},

]
const graphList = [
  {value: '1', label: '1 tháng gần đây'},
  {value: '2', label: '2 tháng gần đây'},
  {value: '3', label: '3 tháng gần đây'},
  {value: '4', label: '4 tháng gần đây'},
  {value: '5', label: '5 tháng gần đây'},
  {value: '6', label: '6 tháng gần đây'},
  {value: '7', label: '7 tháng gần đây'},
  {value: '8', label: '8 tháng gần đây'},
  {value: '9', label: '9 tháng gần đây'},
  {value: '10', label: '10 tháng gần đây'},
  {value: '11', label: '11 tháng gần đây'},
  {value: '12', label: '12 tháng gần đây'},
]


export const Administrator = () => {


  const [newCert, setNewCert] = useState(null);
  const [extendedCert, setExtendedCert] = useState(null);
  const [certProfile, setCertProfile] = useState(null);
  const [certExpired, setCertExpired] = useState(null);
  const [expireDate, setExpireDate] = useState(dateList[0].value)
  const [graphNumber, setGraphNumber] = useState(graphList[5].value)
  const [graphLabel, setgraphLabel] = useState([])
  const [newGraphData, setNewGraphData] = useState([])
  const [extendGraphData, setExtendGraphData] = useState([])

  const [period1, setPeriod1] = useState(periods[0].value);
  const [period2, setPeriod2] = useState(periods[0].value);
  const [period3, setPeriod3] = useState(periods[0].value);

  useEffect(() => {
    //hôm nay
    if (period1 == '1') {
      DashboardService.getNewCert(
        moment().startOf('day').format('x'),
        moment().endOf('day').format('x')
      ).then(({number}) => {
        setNewCert(number);
      });
    } else if (period1 == '2')
      //tuần này
    {
      DashboardService.getNewCert(
        moment().startOf('week').format('x'),
        moment().endOf('week').format('x')
      ).then(({number}) => {
        setNewCert(number);
      });
    } else if (period1 == '3')
      //tháng này
    {
      DashboardService.getNewCert(
        moment().startOf('month').format('x'),
        moment().endOf('month').format('x')
      ).then(({number}) => {
        setNewCert(number);
      });
    }
  }, [period1]);

  useEffect(() => {
    //hôm nay
    if (period2 == '1') {
      DashboardService.getExtendCert(
        moment().startOf('day').format('x'),
        moment().endOf('day').format('x')
      ).then(({number}) => {
        setExtendedCert(number);
      });
    } else if (period2 == '2')
      //tuần này
    {
      DashboardService.getExtendCert(
        moment().startOf('week').format('x'),
        moment().endOf('week').format('x')
      ).then(({number}) => {
        setExtendedCert(number);
      });
    } else if (period2 == '3')
      //tháng này
    {
      DashboardService.getExtendCert(
        moment().startOf('month').format('x'),
        moment().endOf('month').format('x')
      ).then(({number}) => {
        setExtendedCert(number);
      });
    }
  }, [period2]);
  useEffect(() => {
    //hôm nay
    if (period3 == '1') {

      DashboardService.getCertProfile(
        moment().startOf('day').format('x'),
        moment().endOf('day').format('x')
      ).then(({number}) => {
        setCertProfile(number);
      });
    } else if (period3 == '2')
      //tuần này
    {

      DashboardService.getCertProfile(
        moment().startOf('week').format('x'),
        moment().endOf('week').format('x')
      ).then(({number}) => {
        setCertProfile(number);
      });
    } else if (period3 == '3')
      //tháng này
    {

      DashboardService.getCertProfile(
        moment().startOf('month').format('x'),
        moment().endOf('month').format('x')
      ).then(({number}) => {
        setCertProfile(number);
      });
    }
  }, [period3]);


  useEffect(() => {
    if (expireDate) {
      DashboardService.getCertExpired(expireDate).then(({number}) => {
        setCertExpired(number);
      });
    }
  }, [expireDate]);

  useEffect(() => {
    DashboardService.getChartData(graphNumber).then(data => {
      setgraphLabel(data.requestNewData.map(item => {
        return item.label;
      }));
      setNewGraphData(data.requestNewData.map(item => {
        return item.value;
      }));
      setExtendGraphData(data.requestExtendData.map(item => {
        return item.value;
      }));
    })
  }, [graphNumber]);


  const lineData = {
    labels: graphLabel,
    datasets: [
      {
        label: 'Cấp mới',
        data: newGraphData,
        backgroundColor: ['rgba(187,222,251,0.2)'],
        borderColor: ['#578697'],
        borderWidth: 3,
        fill: true,
        tension: 0.4
      },
      {
        label: 'Gia hạn',
        data: extendGraphData,
        borderColor: ['#1BA7AF'],
        borderWidth: 3,
        fill: false,
        pointRadius: [4, 6, 4, 12, 8, 0, 4],
        tension: 0.4
      }
    ]
  };

  return (
    <Fragment>
      <div className="col-12 md:col-6 lg:col-3">
        <div className="overview-box card">
          <div className="overview-box-value">Yêu cầu cấp mới
            <span className="ml-2"/>
            <Dropdown className="w-full md:w-8rem" value={period1} onChange={(e) => setPeriod1(e.value)} options={periods} placeholder="Chọn khoảng thời gian"></Dropdown>
            <div className="overview-box-title">{newCert}</div>
          </div>
          <img src="/assets/layout/images/dashboard/graph-issues.svg" alt="roma"/>
        </div>
      </div>
      <div className="col-12 md:col-6 lg:col-3">
        <div className="overview-box card">
          <div className="overview-box-value">Yêu cầu gia hạn
            <span className="ml-2"/>
            <Dropdown className="w-full md:w-8rem" value={period2} onChange={(e) => setPeriod2(e.value)} options={periods} placeholder="Chọn khoảng thời gian"></Dropdown>
            <div className="overview-box-title">{extendedCert}</div>
          </div>
          <img src="/assets/layout/images/dashboard/graph-messages.svg" alt="roma"/>
        </div>
      </div>
      <div className="col-12 md:col-6 lg:col-3">
        <div className="overview-box card">
          <div className="overview-box-value">Hồ sơ CTS
            <span className="ml-2"/>
            <Dropdown className="w-full md:w-8rem" value={period3} onChange={(e) => setPeriod3(e.value)} options={periods} placeholder="Chọn khoảng thời gian"></Dropdown>
            <div className="overview-box-title">Đã xử lý: {certProfile} </div>
          </div>
          <img src="/assets/layout/images/dashboard/graph-purchases.svg" alt="roma"/>
        </div>
      </div>
      <div className="col-12 md:col-6 lg:col-3">
        <div className="overview-box card">
          <div className="overview-box-value">CTS hết hạn sau
            <span className="ml-2"/>
            <Dropdown showClear value={expireDate} onChange={(e) => setExpireDate(e.value)} options={dateList} placeholder="Chọn khoảng thời gian"></Dropdown>
            <span className="ml-2"/>
            <div className="overview-box-title">{certExpired} </div>
          </div>
          <img src="/assets/layout/images/dashboard/graph-tasks.svg" alt="roma"/>
        </div>
      </div>

      <div className="col-6">
        <div className="card card-w-title">
          <h5>Thống kê khách hàng Chứng thư số</h5>
          <Dropdown value={graphNumber} onChange={(e) => setGraphNumber(e.value)} options={graphList} placeholder="Chọn khoảng thời gian"></Dropdown>
          <Chart type="line" data={lineData} options={lineOptions} height="300px"/>
        </div>
      </div>

    </Fragment>
  );
}


const lineOptions = {
  responsive: true,
  maintainAspectRatio: false,
  hover: {
    mode: 'index'
  },
  scales: {
    x: {
      display: true,
      title: {
        display: true,
        text: 'Thời gian'
      }
    },
    y: {
      display: true,
      title: {
        display: true,
        text: 'Số lượng'
      }
    }
  }
};

const requiredParams = {status: {value: ['1', '2'], matchMode: 'in'}};