type Action = 'CREATE' | 'VIEW' | 'UPDATE' | 'DELETE' | 'APPROVE' | 'ALL';

export class IamPermission {

  private static permissions;

  static init(permissions) {
    this.permissions = permissions;
  }

  static has(action: Action | Array<Action>, screen?: string | Array<string>) {
    if (!this.permissions?.length) {
      return false;
    }
    if (!screen) {
      screen = window.location.hash.substring(1);
    }
    if (!Array.isArray(screen)) {
      screen = [screen];
    }
    for (const _screen of screen) {
      for (const permission of this.permissions.filter(permission => permission.screenId === _screen || permission.path === _screen)) {
        for (const artifact of permission.artifacts) {
          if (!Array.isArray(action)) {
            action = [action];
          }
          for (const _action of action) {
            if (artifact.permissions?.includes('ALL') || artifact.permissions?.includes(_action)) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

}