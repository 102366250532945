import {Service} from '../../service/Service';

export class DashboardService extends Service {

  static entity = 'vinca-report';

  static getRequestsRejected(): Promise<any> {
    return this.axios.get(`${this.entity}/requests/rejected`).then(res => res.data);
  }

  static getRequestsInProgress(): Promise<any> {
    return this.axios.get(`${this.entity}/requests/in-progress`).then(res => res.data);
  }

  static getRequestsCreated(): Promise<any> {
    return this.axios.get(`${this.entity}/requests/created`).then(res => res.data);
  }

  static getProfileIncomplete(): Promise<any> {
    return this.axios.get(`/vinca-cert/profiles/requests/uncompleted-count`).then(res => res.data);
  }

  static getProfileCompleted(): Promise<any> {
    return this.axios.get(`/vinca-cert/profiles/requests/completed-count`).then(res => res.data);
  }

  static getNewCert(fromDate: string, thruDate: string): Promise<any> {
    return this.axios.get(`${this.entity}/dashboard/cert-new-count?fromDate=${fromDate}&thruDate=${thruDate}`,).then(res => res.data);
  }

  static getExtendCert(fromDate: string, thruDate: string): Promise<any> {
    return this.axios.get(`${this.entity}/dashboard/cert-extend-count?fromDate=${fromDate}&thruDate=${thruDate}`,).then(res => res.data);
  }

  static getCertProfile(fromDate: string, thruDate: string): Promise<any> {
    return this.axios.get(`${this.entity}/dashboard/cert-profile-completed?fromDate=${fromDate}&thruDate=${thruDate}`,).then(res => res.data);
  }

  static getCertExpired(exprireDate: string): Promise<any> {
    return this.axios.get(`${this.entity}/dashboard/cert-expire?expireDate=${exprireDate}`,).then(res => res.data);
  }
  static getChartData(lastNMonth: string): Promise<any> {
    return this.axios.get(`${this.entity}/dashboard/request-line-graph-by-month?lastNMonth=${lastNMonth}`,).then(res => res.data);
  }


}
