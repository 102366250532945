import {EntityService} from '../../service/EntityService';

export class TokenService extends EntityService {

  static entity = 'vinca-cert/tms/histories';

  static getList(lazyLoadEvent: string): Promise<any> {
      return this.axios.get(`${this.entity}?lazyLoadEvent=${lazyLoadEvent}`).then(res => res.data);
  }


}
