import {Route, Routes} from 'react-router-dom';

import {GoiChungThuSo} from './goi-chung-thu-so';
import {NhomSanPham} from './nhom-san-pham';

export const DanhMuc = () => {

  return (
    <Routes>
      <Route path="goi-chung-thu-so" element={<GoiChungThuSo/>}/>
      <Route path="nhom-san-pham" element={<NhomSanPham/>}/>
    </Routes>
  );
}