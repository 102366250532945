import {useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {useDataTableBasic} from '@iamsoftware/react-hooks';
import {useCrupItemProducts} from "./CrupItemProducts";

export const useItemProducts = ({readOnly},{update},{create}) => {

  const dataKey = 'dataKey';

  const [productItems, setProductItems] = useState([]);


  const doCreateItem = (productItems: Array<any>) => {
    if (productItems?.length) {
      setProductItems(productItems.map(productItem => {
        return {...productItem, label: `[${productItem.pseudoId}] - ${productItem.productName || ''}`}
      }));
    }
  }

    useEffect(() => {
      if (productItems?.length) {
        setProductItems(prevProductItems => {
          prevProductItems.forEach((prevProductItem, index) => {
            if (!prevProductItem[dataKey]) {
              prevProductItem[dataKey] = `${index}`;
            }
          });
          return [...prevProductItems];
        });
      } else {
        addBlankItem();
      }
    }, [JSON.stringify(productItems)]); // eslint-disable-line react-hooks/exhaustive-deps

    const onEditorChange = (rowData, column, newValue) => {
      setProductItems(prevProductItems => prevProductItems.map(prevProductItem => {
        if (prevProductItem[dataKey] === rowData[dataKey]) {
          return {
            ...prevProductItem,
            [column.field]: newValue
          }
        }
        return prevProductItem;
      }));
    }

    const addBlankItem = () => {
      setProductItems(prevProductItems => {
        prevProductItems.push({});
        return [...prevProductItems];
      });
    }
    const {renderPurchasePlanning, doPlan} = useCrupItemProducts({doCreateItem});
    const header = (
      <div className="flex flex-wrap align-items-center justify-content-between gap-2">
        <span>Gói dịch vụ</span>
        {(update===true||create===true) && <div>
          <Button label='Chọn gói dịch vụ' severity="success"  onClick={doPlan}/>
        </div>}
        {renderPurchasePlanning()}
      </div>
    );

    const {render: renderProductItems} = useDataTableBasic({
      tableHeader: header,
      dataKey,
      columns: [
        {field: 'pseudoId', header: 'Mã sản phẩm', width: 150,  editorConfig: {onEditorChange, readOnly}},
        {field: 'productName', header: 'Tên sản phẩm', minWidth: 200, editorConfig: {onEditorChange, readOnly}},
        {field: 'price', header: 'Đơn giá', width: 200, dataType: 'number', editorConfig: {onEditorChange, readOnly}},
        {field: 'amountValue', header: 'Số lượng', width: 180,  editorConfig: {onEditorChange, readOnly}}
      ],
      indexColumnWidth: 45,
      items: productItems
    });

    const getProductItems = () => {
      return productItems.filter(itemProducts => itemProducts.productName);
    }
    return {renderProductItems, getProductItems, setProductItems};

}