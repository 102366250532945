import React, {useEffect, useRef, useState} from 'react';

import {Button} from 'primereact/button';
import {Checkbox} from 'primereact/checkbox';
import {InputText} from 'primereact/inputtext';
import {Password} from 'primereact/password';
import {Messages} from 'primereact/messages';

import {AuthService} from '../service/AuthService';

import {AppStorage} from '../service/UserStorage';

export const Login = () => {

  const [form, setForm] = useState('login');

  useEffect(() => {
    getToken();
  }, []);

  const getToken = () => {
    AuthService.getToken().then(({moquiSessionToken}) => {
      AppStorage.set('moquiSessionToken', moquiSessionToken);
    });
  }

  return (
    <div className="login-body">
      <div className="card login-panel p-fluid">
        <div className="login-panel-content">
          {{
            'login': <LoginForm setForm={setForm} getToken={getToken}/>,
            'resetPassword': <ResetPasswordForm setForm={setForm} getToken={getToken}/>
          }[form]}
        </div>
      </div>
    </div>
  );
}

const LoginForm = ({setForm, getToken}) => {

  const message = useRef(null);

  const [submitting, setSubmitting] = useState(false);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [checked, setChecked] = useState(false);

  const onSubmit = event => {
    if (!submitting) {
      message.current.clear();
      setSubmitting(true);

      AuthService.login(username, password).then(data => {
        if (data.isSuccess === 'Y') {
          AppStorage.set('moquiSessionToken', data.moquiSessionToken);
          window.location.hash = '/';
        } else {
          data?.errors.forEach(error => {
            message.current.show({severity: 'error', content: error, sticky: true});
          });
          getToken();
        }
      }).catch(() => getToken()).finally(() => {
        setSubmitting(false);
      });
    }
    event.preventDefault();
  }

  return (
    <form onSubmit={onSubmit}>
      <div className="grid">
        <div className="col-12 sm:col-6 md:col-6 logo-container">
          <img src="/assets/vin-invoice/logovin_new.png" alt="VIN-CRM"/>
          <span className="guest-sign-in">Đăng nhập VIN-CRM</span>
        </div>
        <div className="col-12 username-container">
          <label>Tài khoản</label>
          <div className="login-input">
            <InputText value={username} onChange={e => setUsername(e.target.value)} required/>
          </div>
        </div>
        <div className="col-12 password-container">
          <label>Mật khẩu</label>
          <div className="login-input">
            <Password value={password} onChange={e => setPassword(e.target.value)} feedback={false} toggleMask required/>
          </div>
        </div>
        <div className="col-12 sm:col-6 md:col-6 rememberme-container">
          <Checkbox checked={checked} onChange={e => setChecked(e.checked)}/>
          <label> Nhớ tài khoản</label>
        </div>

        <div className="col-12 sm:col-6 md:col-6 forgetpassword-container">
          <span className="text-primary" onClick={() => setForm('resetPassword')}>Quên mật khẩu</span>
        </div>

        <div className="col-12 py-0">
          <Messages ref={message}/>
        </div>

        <div className="col-12 sm:col-6 md:col-6">
          <Button label="Đăng nhập" icon="pi pi-check" loading={submitting}/>
        </div>
      </div>
    </form>
  );
}

const ResetPasswordForm = ({setForm, getToken}) => {

  const message = useRef(null);

  const [submitting, setSubmitting] = useState(false);

  const [username, setUsername] = useState('');
  const [emailAddress, setEmailAddress] = useState('');

  const onSubmit = event => {
    if (!submitting) {
      message.current.clear();
      setSubmitting(true);

      AuthService.resetPassword(username, emailAddress).then(() => {
        message.current.show({
          severity: 'info',
          content: 'Mật khẩu mới đã được gửi đến email cùa bạn. Vui lòng kiểm tra hộp thư đến và làm theo hướng dẫn.',
          sticky: true
        });
      }).finally(() => {
        setSubmitting(false);
        getToken();
      });
    }
    event.preventDefault();
  }

  return (
    <form onSubmit={onSubmit}>
      <div className="grid">
        <div className="col-12 sm:col-6 md:col-6 logo-container">
          <img src="/assets/vin-invoice/logovin_new.png" alt="VIN-CRM"/>
          <span className="guest-sign-in">Quên mật khẩu</span>
        </div>
        <div className="col-12 username-container">
          <label>Tài khoản</label>
          <div className="login-input">
            <InputText value={username} onChange={e => setUsername(e.target.value)} required/>
          </div>
        </div>
        <div className="col-12 password-container">
          <label>Email</label>
          <div className="login-input">
            <InputText value={emailAddress} onChange={e => setEmailAddress(e.target.value)} required/>
          </div>
        </div>

        <div className="col-12 py-0">
          <Messages ref={message}/>
        </div>

        <div className="col-12 md:col-8">
          <Button label="Nhận mật khẩu qua Email" icon="pi pi-check" loading={submitting}/>
        </div>

        <div className="col-12 rememberme-container">
          <span className="text-primary" onClick={() => setForm('login')}><i className="pi pi-arrow-left"></i> Quay lại đăng nhập</span>
        </div>
      </div>
    </form>
  );
}
