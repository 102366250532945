import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {FormatDisplay, useDataTableBasic} from '@iamsoftware/react-hooks';

import {ProfileService} from "./ProfileService";

export const NoteHistory = ({display, setDisplay}) => {

  const [items, setItems] = useState([]);

  useEffect(() => {
    if (display) {
      ProfileService.getNoteHistory(display.requestId).then(({listData}) => {
        setItems(listData);
      });
    }
  }, [display]);

  const {render} = useDataTableBasic({
    dataKey:'certificateSn',
    columns: [
      // {field: 'username', header: 'Tài khoản đăng nhập', width: 150, matchMode: 'contains'},
      // {field: 'partyName', header: 'Họ và tên', width: 150, matchMode: 'contains'},
      {field: 'noteDate', header: 'Ngày ghi chú', width: 150, matchMode: 'contains', dataType:'date'},
      {field: 'noteText', header: 'Ghi chú', width: 300, matchMode: 'contains'},
    ],
    items
  });


  const footer = (
    <div>
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(false)}/>
    </div>
  );
  const header = (
    <div>
      <h4> Lịch sử ghi chú</h4>
    </div>
  );

  return (
    <Dialog header={header} footer={footer} visible={!!display} style={{width: '100'}} position="top" onHide={() => setDisplay(false)}>
      {render()}
    </Dialog>
  );
}