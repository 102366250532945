import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {FormatDisplay, useDataTable, useDataTableBasic, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {CustomerService as Service} from '../CustomerService';
import {AgentService} from '../../dai-ly/danh-sach/AgentService';
import {CommonService} from '../../../service/CommonService';
import {PartyService} from '../../../service/PartyService';
import {IamPermission} from './../../../shared/utils/Permission';


const dataKey = 'partyId';

export const DanhSach = ({me}) => {

  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    dataKey,
    columns: [
      {field: 'pseudoId', header: 'Mã', minWidth: 120, matchMode: 'contains'},
      {field: 'partyClassification', header: 'Loại khách hàng', minWidth: 160, matchMode: 'contains'},
      {field: 'partyName', header: 'Khách hàng', minWidth: 180, matchMode: 'contains'},
      {field: 'partyTaxId', header: 'MST', minWidth: 145, matchMode: 'contains'},
      {field: 'partyIdentId', header: 'CCCD/CMND/HC', minWidth: 150, matchMode: 'contains'},
      {field: 'stateProvinceGeo', header: 'Tỉnh/Thành phố', minWidth: 170, matchMode: 'contains'},
      {field: 'countyGeo', header: 'Quận/Huyện', minWidth: 170, matchMode: 'contains'},
      {field: 'emailAddress', header: 'Email', minWidth: 170, matchMode: 'contains'},
      {field: 'phoneNumber', header: 'Số điện thoại', minWidth: 150, matchMode: 'contains'},
      {field: 'agentPartyName', header: 'Thuộc đại lý', minWidth: 150, matchMode: 'contains'}
    ],
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    }
  });

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const {renderDialogCrup, doCreate, doView, doUpdate} = useDialogCrupCustomer({reloadLazyData});

  const downloadExcel=()=>{
    window.open(`${Service.baseURL}/${Service.entity}/download`);
  }

  const {renderToolbar} = useToolbar({
    doCreate: (IamPermission.has('CREATE') ||['Administrator', 'Agent'].includes(me.roleTypeId))  && doCreate,
    hasSelectedItem: selectedItem,
    doView() {
      doView(selectedItem);
    },
    doUpdate: (IamPermission.has('UPDATE')||['Administrator', 'Agent'].includes(me.roleTypeId)) &&  doUpdate(selectedItem),
    leftButtons: <Fragment>
      <Button label="Xuất excel" icon="pi pi-file-excel" severity="help" size="small" onClick={downloadExcel}/>
    </Fragment>
  });

  return (
    <div className="grid">
      <div className="col-12">
        {renderToolbar()}
      </div>
      <div className="col-12 py-0" style={{maxHeight: 'calc(100vh - 15.5rem)'}}>
        {renderDataTable()}
      </div>
      {renderDialogCrup()}
    </div>
  );
}

export const useDialogCrupCustomer = ({reloadLazyData}) => {

  const [customer, setCustomer] = useState(null);
  const [action, setAction] = useState('');

  const [classifications, setClassifications] = useState([]);

  const [identifyTypes, setIdentifyTypes] = useState([]);
  const [identifyType, setIdentifyType] = useState('');
  const [relatedIdentifyTypes, setRelatedIdentifyTypes] = useState([]);
  const [relatedIdentifyType, setRelatedIdentifyType] = useState('');

  const [provinces, setProvinces] = useState([]);
  const [counties, setCounties] = useState([]);
  const [parents, setParents] = useState([]);

  const [idValueMaxLength, setIdValueMaxLength] = useState(20);
  const [idValueKeyFilter, setIdValueKeyFilter] = useState('alphanum');

  useEffect(() => {
    CommonService.getDigitalCertClassifications().then(data => {
      setClassifications(data.listData.map(item => {
        return {value: item.partyClassificationId, label: item.description}
      }));
    });
    CommonService.getProvinces().then(data => {
      setProvinces(data.listData.map(item => {
        return {value: item.toGeoId, label: item.geoName}
      }));
    });
    AgentService.getTreeList('N').then(data => {
      const _parents = AgentService.dataTreeTableToTreeSelect(data.orgTree, data => {
        return `[${data.pseudoId}] - ${data.partyName}`;
      });
      setParents(_parents);
    });
  }, []);

  const {render: agentParties} = useDataTableBasic({
    tableHeader: 'Đại lý quản lý',
    columns: [
      {field: 'pseudoId', header: 'Mã đại lý', width: 150},
      {field: 'partyName', header: 'Tên đại lý', minWidth: 150},
      {field: 'fromDate', header: 'Từ ngày', width: 160, dataType: 'date-time'},
      {field: 'thruDate', header: 'Đến ngày', width: 160, dataType: 'date-time'}
    ],
    items: customer?.agentParties
  });

  const {render: certInfos} = useDataTableBasic({
    tableHeader: 'Chứng thư số',
    columns: [
      {field: 'certificateSn', header: 'Certificate SN', minWidth: 150},
      {field: 'snToken', header: 'Token', width: 150},
      {field: 'productName', header: 'Gói dịch vụ', minWidth: 150},
      {field: 'effectiveDate', header: 'Ngày có hiệu lực', width: 160, dataType: 'date-time'},
      {field: 'expireDate', header: 'Ngày hết hạn', width: 160, dataType: 'date-time'}
    ],
    items: customer?.certInfos
  });

  const getByTaxCode = () => {
    const _value = form.getRawValue();
    if (_value.partyTaxId) {
      PartyService.getByTaxCode(_value.partyTaxId, 'N').then(({data}) => {
        if (data?.partyName) {
          _value.partyId = '';
          _value.partyName = data.partyName;
          _value.stateProvinceGeoId = data.stateProvinceGeoId;
          _value.countyGeoId = data.countyGeoId;
          _value.address1 = data.address1;
          form.setValue(_value);
        }
      });
    }
  }

  const {render: renderDialogCrup, create, view, update, form} = useDialogCrup({
    header: 'Khách hàng',
    dataKey,
    width: '80rem',
    fields: [
      {field: 'partyClassificationId', header: 'Loại khách hàng', required: true, type: 'Dropdown', DropdownProps: {options: classifications}, className: 'md:col-4'},
      {
        field: 'partyTaxId', header: 'Mã số thuế', required: true, displayDependency(item: any): boolean {
          return item?.partyClassificationId === 'PcltCertEnterprise';
        }, className: 'md:col-4'
      },
      {
        field: 'getByTaxCode', type: 'custom', body: <Button icon="pi pi-search" rounded severity="success" size="small" onClick={getByTaxCode}/>, displayDependency(item: any): boolean {
          return ['Create'].includes(action) && item?.partyClassificationId === 'PcltCertEnterprise';
        }, className: 'md:col-1 pl-0 pt-4'
      },
      {
        type: 'custom', displayDependency(item: any): boolean {
          return item?.partyClassificationId === 'PcltCertEnterprise';
        }, className: 'md:col-3'
      },
      {
        field: 'partyIdTypeEnumId', header: 'Loại định danh', required: true, displayDependency(item: any): boolean {
          return ['PcltCertPersonalEnterprise', 'PcltCertPersonal'].includes(item?.partyClassificationId);
        }, type: 'Dropdown', DropdownProps: {options: identifyTypes}, className: 'md:col-4'
      },
      {
        field: 'idValue', header: identifyType, required: true, displayDependency(item: any): boolean {
          return ['PcltCertPersonalEnterprise', 'PcltCertPersonal'].includes(item?.partyClassificationId);
        }, InputTextProps: {keyfilter: idValueKeyFilter}, className: 'md:col-4'
      },
      {field: 'pseudoId', header: 'Mã khách hàng', required: true, className: 'md:col-4'},
      {field: 'partyName', header: 'Tên khách hàng', required: true, className: 'md:col-8'},
      {field: 'stateProvinceGeoId', header: 'Tỉnh/Thành phố', required: true, type: 'Dropdown', DropdownProps: {options: provinces, filter: true}, className: 'md:col-4'},
      {field: 'countyGeoId', header: 'Quận/Huyện', required: true, type: 'Dropdown', DropdownProps: {options: counties, filter: true}, className: 'md:col-4'},
      {type: 'custom', className: 'md:col-4'},
      {field: 'address1', header: 'Địa chỉ', className: 'md:col-8'},
      {type: 'custom', className: 'md:col-4'},
      {field: 'emailAddress', header: 'Email', required: true, InputTextProps: {maxLength: 50}, className: 'md:col-4'},
      {field: 'phoneNumber', header: 'Số điện thoại', required: true, InputTextProps: {maxLength: 17, keyfilter: 'int'}, className: 'md:col-4'},
      {type: 'custom', className: 'md:col-4'},
      {
        field: 'jobTitle', header: 'Chức vụ', displayDependency(item: any): boolean {
          return item?.partyClassificationId === 'PcltCertPersonalEnterprise';
        }, className: 'md:col-4'
      },
      {
        field: 'departmentName', header: 'Phòng ban', displayDependency(item: any): boolean {
          return item?.partyClassificationId === 'PcltCertPersonalEnterprise';
        }, className: 'md:col-8'
      },
      {
        field: 'relatedPartyName', header: 'Tên người đại diện', displayDependency(item: any): boolean {
          return item?.partyClassificationId === 'PcltCertEnterprise';
        }, className: 'md:col-4'
      },
      {
        field: 'relatedPartyName', header: 'Tên tổ chức/doanh nghiệp', displayDependency(item: any): boolean {
          return item?.partyClassificationId === 'PcltCertPersonalEnterprise';
        }, className: 'md:col-4'
      },
      {
        field: 'relatedIdTypeEnumId', header: 'Loại định danh', displayDependency(item: any): boolean {
          return ['PcltCertEnterprise', 'PcltCertPersonalEnterprise'].includes(item?.partyClassificationId);
        }, type: 'Dropdown', DropdownProps: {options: relatedIdentifyTypes, placeholder: '-'}, className: 'md:col-4'
      },
      {
        field:'', displayDependency(item: any): boolean {
          return ['PcltCertEnterprise', 'PcltCertPersonalEnterprise'].includes(item?.partyClassificationId);
        }, className: 'md:col-4' , type:'custom'
      },
      {
        field: 'relatedIdValue', header: relatedIdentifyType, displayDependency(item: any): boolean {
          return ['PcltCertEnterprise', 'PcltCertPersonalEnterprise'].includes(item?.partyClassificationId);
        }, InputTextProps: {maxLength: 200}, className: 'md:col-4'
      },
      {
        field: 'relatedIdDate', header: 'Ngày cấp', displayDependency(item: any): boolean {
          return ['PcltCertEnterprise', 'PcltCertPersonalEnterprise'].includes(item?.partyClassificationId);
        }, InputTextProps: {type:'date'}, className: 'md:col-4'
      },
      {
        field: 'relatedIdPlace', header: 'Nơi cấp', displayDependency(item: any): boolean {
          return ['PcltCertEnterprise', 'PcltCertPersonalEnterprise'].includes(item?.partyClassificationId);
        }, className: 'md:col-4'
      },
      {
        field: 'relatedAddress1', header: 'Địa chỉ (Tổ chức/doanh nghiệp)', displayDependency(item: any): boolean {
          return item?.partyClassificationId === 'PcltCertPersonalEnterprise';
        }, className: 'md:col-12'
      },
      {
        field: 'relatedEmailAddress', header: 'Email (Tổ chức/doanh nghiệp)', displayDependency(item: any): boolean {
          return item?.partyClassificationId === 'PcltCertPersonalEnterprise';
        }, InputTextProps: {maxLength: 50}, className: 'md:col-4'
      },
      {
        field: 'relatedPhoneNumber', header: 'Số điện thoại (Tổ chức/doanh nghiệp)', displayDependency(item: any): boolean {
          return item?.partyClassificationId === 'PcltCertPersonalEnterprise';
        }, InputTextProps: {maxLength: 17, keyfilter: 'int'}, className: 'md:col-4'
      },
      {
        type: 'custom', displayDependency(item: any): boolean {
          return item?.partyClassificationId === 'PcltCertPersonalEnterprise';
        }, className: 'md:col-4'
      },
      {field: 'agentPartyId', header: 'Thuộc đại lý', required: true, display: ['Create'].includes(action), type: 'TreeSelect', TreeSelectProps: {options: parents, filter: true}, className: 'md:col-8'},
      {type: 'custom', className: 'md:col-12', body: agentParties(), display: ['View'].includes(action)},
      {type: 'custom', className: 'md:col-12', body: certInfos(), display: ['View'].includes(action)}
    ],
    createItem(item: any): Promise<any> {
      if (item.partyClassificationId === 'PcltCertEnterprise') {
        item.partyIdTypeEnumId = 'PtidTaxIdent';
        item.idValue = item.partyTaxId;
      }
      return Service.create(item);
    },
    updateItem(id: string, item: any): Promise<any> {
      return Service.updateInfo(id, item);
    },
    reloadLazyData
  });

  const _value = form.getRawValue();

  useEffect(() => {
    switch (_value.partyIdTypeEnumId) {
      case 'PtidTaxIdent':
        setIdValueMaxLength(14);
        setIdValueKeyFilter('int');
        break;
      case 'PtidCccd':
      case 'PtidCmnd':
        setIdValueMaxLength(12);
        setIdValueKeyFilter('int');
        break;
      case 'PtidPassport':
        setIdValueMaxLength(8);
        setIdValueKeyFilter('alphanum');
        break;
      default:
        setIdValueMaxLength(10);
        setIdValueKeyFilter('int');
    }
  }, [_value.partyIdTypeEnumId]);

  useEffect(() => {
    _value.pseudoId = _value.idValue || _value.partyTaxId;
    form.setValue(_value);
  }, [_value.idValue, _value.partyTaxId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let _identifyType = '-';
    if (identifyTypes && _value.partyIdTypeEnumId) {
      identifyTypes.forEach(item => {
        if (item.value === _value.partyIdTypeEnumId) {
          _identifyType = item.label;
        }
      });
    }
    setIdentifyType(_identifyType);
  }, [_value.partyIdTypeEnumId, identifyTypes]);

  useEffect(() => {
    let _relatedIdentifyType = '-';
    if (relatedIdentifyTypes && _value.relatedIdTypeEnumId) {
      relatedIdentifyTypes.forEach(item => {
        if (item.value === _value.relatedIdTypeEnumId) {
          _relatedIdentifyType = item.label;
        }
      });
    }
    setRelatedIdentifyType(_relatedIdentifyType);
  }, [_value.relatedIdTypeEnumId, relatedIdentifyTypes]);

  useEffect(() => {
    if (_value.partyClassificationId) {
      CommonService.getIdentifyTypes(_value.partyClassificationId).then(data => {
        setIdentifyTypes(data.listData.map(item => {
          return {value: item.enumId, label: item.description}
        }));
        _value.partyIdTypeEnumId = data.listData[0].enumId;
        form.setValue(_value);
      });

      let relatedClassificationId;
      switch (_value.partyClassificationId) {
        case 'PcltCertPersonalEnterprise':
          relatedClassificationId = 'PcltCertEnterprise';
          break;
        case 'PcltCertEnterprise':
          relatedClassificationId = 'PcltCertPersonal';
          break;
      }
      if (relatedClassificationId) {
        CommonService.getIdentifyTypes(relatedClassificationId).then(data => {
          setRelatedIdentifyTypes(data.listData.map(item => {
            return {value: item.enumId, label: item.description}
          }));
          form.setValue(_value);
        });
      }
    }
  }, [_value.partyClassificationId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_value.stateProvinceGeoId) {
      CommonService.getCounties(_value.stateProvinceGeoId).then(data => {
        setCounties(data.listData.map(item => {
          return {value: item.toGeoId, label: item.geoName}
        }));
      });
    }
  }, [_value.stateProvinceGeoId]);

  const doCreate = () => {
    setAction('Create');
    create({
      partyClassificationId: 'PcltCertEnterprise',
      partyIdTypeEnumId: 'PtidTaxIdent'
    });
  }

  const doView = item => {
    setAction('View');
    if (item && item[dataKey]) {
      Service.get(item[dataKey]).then(data => {
        data.relatedIdDate = FormatDisplay.date(data.relatedIdDate,'YYYY-MM-DD')
        view(data);
        setCustomer(data);
      });
    }
  }
  const doUpdate = selectedItem => (doView?: boolean) => {
    setAction('Update');
    if (selectedItem && selectedItem[dataKey]) {
      Service.get(selectedItem[dataKey]).then(data => {
        data.relatedIdDate = FormatDisplay.date(data.relatedIdDate,'YYYY-MM-DD')
        update(data);
      });
    }
  }


  return {renderDialogCrup, doCreate, doView, doUpdate};

}