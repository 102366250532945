import {useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {ToastService, useForm} from '@iamsoftware/react-hooks';
import {LcsAuthService} from './LcsAuthService';

export const VininvoiceAuth = () => {

    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        LcsAuthService.getVininvoice().then(auth => {
            reset();
            setValue(auth);
        });
    }, []);// eslint-disable-line react-hooks/exhaustive-deps
    const {render: renderForm,reset, setValue, valid: formValid, getValue} = useForm({
        fields: [
            {field: 'username', header: 'Tài khoản', required: true, className: 'md:col-12', labelClassName: 'md:col-4 justify-content-end', inputClassName: 'md:col-8'},
            {field: 'password', header: 'Mật khẩu', required: true, type: 'Password', className: 'md:col-12', labelClassName: 'md:col-4 justify-content-end', inputClassName: 'md:col-8'},
        ],
        layout: 'Horizontal'
    });

    const onSubmit = event => {
        if (!submitting && formValid()) {
            setSubmitting(true);

            LcsAuthService.setVininvoice(getValue()).then(() => {
                ToastService.success();
            }).finally(() => {
                setSubmitting(false);
            });
        }
        event.preventDefault();
    }

    return (
        <div className="grid mt-2">
            <div className="col-5">
                {renderForm()}
            </div>
            <div className="col-7"></div>
            <div className="col-5 text-right">
                <Button label="Lưu" icon="pi pi-check" size="small" onClick={onSubmit} loading={submitting}/>
            </div>
        </div>
    );
}