import {useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import {Panel} from 'primereact/panel';
import {ConfirmDialogService, FormatDisplay, ToastService, useDataTableBasic, useDialogCrup, useForm} from '@iamsoftware/react-hooks';

import {ProfileService as Service} from './ProfileService';

import {UploadContent} from './UploadContent';

export const RequestDetail = ({detail, reload, setPreviewPdfSrc, setPreviewImgSrc}) => {

  useEffect(() => {
    if (detail) {
      reset();
      setValue(detail);
    }
  }, [detail]); // eslint-disable-line react-hooks/exhaustive-deps

  const onFileSelect = async file => {
    let src;
    if (file.key) {
      if (file.contentType === 'application/pdf') {
        src = file.src;
        setPreviewPdfSrc(src);
        setPreviewImgSrc(null);
      } else if (file.contentType && file.contentType.includes('image/')) {
        const blob = await fetch(file.src).then(r => r.blob());
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          src = reader.result;
          setPreviewPdfSrc(null);
          setPreviewImgSrc(src.replace('data:application/octet-stream;', 'data:image/png;'));
        }
      } else {
        window.open(file.src);
      }
    } else {
      src = window.URL.createObjectURL(new Blob([file], {type: file.type}));
      if (file.type === 'application/pdf') {
        setPreviewPdfSrc(src);
        setPreviewImgSrc(null);
      } else {
        setPreviewPdfSrc(null);
        setPreviewImgSrc(src);
      }
    }
  }

  const {render: renderForm, reset, setValue} = useForm({
    fields: [
      {field: 'partyClassificationId', type: 'hidden'},
      {type: 'custom', className: 'md:col-12', body: <Contents detail={detail} reload={reload} onFileSelect={onFileSelect}/>},
      {
        type: 'custom', className: 'md:col-12 mb-3', body: <b>Thông tin tổ chức doanh nghiệp</b>, displayDependency(item: any): boolean {
          return item?.partyClassificationId === 'PcltCertPersonalEnterprise'
        }
      },
      {
        field: 'relatedPartyName', header: 'Tên TCDN', displayDependency(item: any): boolean {
          return item?.partyClassificationId === 'PcltCertPersonalEnterprise';
        }, className: 'md:col-9'
      },
      {
        field: 'relatedIdValue', header: detail?.relatedIdTypeEnum, displayDependency(item: any): boolean {
          return item?.partyClassificationId === 'PcltCertPersonalEnterprise';
        }, className: 'md:col-3'
      },
      {type: 'custom', className: 'md:col-12 mb-3', body: <b>Thông tin giao dịch và biểu mẫu</b>},
      {field: 'productCategory', header: 'Thiết bị', className: 'md:col-6'},
      {field: 'requestTypeEnum', header: 'Loại giao dịch', className: 'md:col-6'},
      {field: 'itemDescription', header: 'Gói cước', className: 'md:col-6'},
      {field: 'productFeature', header: 'Loại Token', className: 'md:col-6'},

      {type: 'custom', className: 'md:col-12 mb-3', body: <b>Giao dịch viên</b>},
      {field: 'filedByPartyName', header: 'Giao dịch viên', className: 'md:col-5'},
      {field: 'description', header: 'Ghi chú', className: 'md:col-7'}
    ],
    formClassName: 'detail',
    readOnly: true
  });

  return (
    <div className="col-12">
      <Panel header={`Yêu cầu chứng thư số mã ${detail?.requestPseudoId} - ngày ${FormatDisplay.date(detail?.requestDate)}`}>
        {renderForm()}
      </Panel>
    </div>
  );
}

const Contents = ({detail, reload, onFileSelect}) => {

  const [expandedRows, setExpandedRows] = useState(null);

  const {render: renderDialogReceivedDate, update: updateReceivedDate} = useDialogCrup({
    header: '!Cập nhật ngày nhận của hồ sơ',
    dataKey: 'requestContentId',
    width: '40rem',
    fields: [
      {field: 'requestId', type: 'hidden'},
      {field: 'receivedDate', header: 'Ngày nhận', type: 'Calendar', className: 'md:col-12'}
    ],
    updateItem(id: string, item: any): Promise<any> {
      const requestId = item.requestId;
      delete item.requestId;
      return Service.updateContentReceivedDate(requestId, id, item.receivedDate);
    },
    reloadLazyData: reload
  });

  const viewContent = content => {
    const url = `${Service.baseURL}/${Service.entity}/${content.requestId}/contents/${content.requestContentId}/download`;
    onFileSelect({
      key: content.requestContentId,
      contentType: content.contentType,
      src: url
    }).then();
  }

  const deleteContent = content => {
    ConfirmDialogService.confirm('Xóa file', 'Xác nhận xóa file này?', () => {
      Service.deleteContent(content.requestId, content.requestContentId).then(() => {
        ToastService.success();
        reload();
      });
    });
  }

  const rowExpansionTemplate = data => <Content data={data} updateReceivedDate={updateReceivedDate} viewContent={viewContent} deleteContent={deleteContent}/>

  return <div className="grid">
    <div className="col-12 un-fluid text-right">
      <UploadContent requestId={detail?.requestId} reload={reload}/>
    </div>
    <div className="col-12 mb-3">
      <DataTable value={detail?.contents} expandedRows={expandedRows} onRowToggle={e => setExpandedRows(e.data)}
                 responsiveLayout="scroll"
                 rowExpansionTemplate={rowExpansionTemplate} dataKey="contentTypeEnumId">
        <Column expander style={{width: '3em'}}/>
        <Column field="description" header="Loại hồ sơ"/>
      </DataTable>

      {renderDialogReceivedDate()}
    </div>
  </div>
}

const Content = ({data, updateReceivedDate, viewContent, deleteContent}) => {

  const doUpdate = rowData => {
    if (rowData.hardCopyReceivedDate) {
      rowData.receivedDate = new Date(rowData.hardCopyReceivedDate);
    } else {
      rowData.receivedDate = new Date();
    }
    updateReceivedDate(rowData);
  }

  const {render} = useDataTableBasic({
    columns: [
      {field: 'fileName', header: 'Tên file', minWidth: 150},
      {field: 'contentDate', header: 'Ngày tạo', width: 145, dataType: 'date-time'},
      {field: 'formTypeDesc', header: 'Hình thức HS', width: 130},
      {field: 'hardCopyRequire', header: 'Yêu cầu HS cứng', width: 150, dataType: 'boolean'},
      {field: 'hardCopyReceivedDate', header: 'Ngày nhận HS cứng', width: 165, dataType: 'date'},
      {
        field: 'upload', width: 110, sortable: false, dataType: 'custom', customCell(rowData: any): any {
          return <div>
            <Button icon="pi pi-eye" rounded severity="info" size="small" onClick={() => viewContent(rowData)}/>
            {rowData.hardCopyRequire === 'N' && <Button icon="pi pi-pencil" rounded severity="warning" size="small" onClick={() => doUpdate(rowData)}/>}
            <Button icon="pi pi-trash" rounded severity="danger" size="small" onClick={() => deleteContent(rowData)}/>
          </div>
        }
      }
    ],
    items: data?.items
  });

  return (
    <div className="grid card p-0">
      <div className="col-12">
        {render()}
      </div>
    </div>
  );
}