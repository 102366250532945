import {Route, Routes} from 'react-router-dom';

import {DanhSach} from './danh-sach';

export const NhanVien = () => {

    return (
        <Routes>
            <Route path="danh-sach" element={<DanhSach/>}/>
        </Routes>
    );
}