import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {SelectButton} from 'primereact/selectbutton';
import {ToastService, useDataTable, useDataTableBasic, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {CustomerService as Service} from '../../khach-hang/CustomerService';
import {AgentService} from '../../dai-ly/danh-sach/AgentService';
import {CommonService} from '../../../service/CommonService';
import {PartyService} from '../../../service/PartyService';
import {IamPermission} from '../../../shared/utils/Permission';


const dataKey = 'userId';
const statuses = [
  {value: 'N', label: 'Đang hoạt động'},
  {value: 'Y', label: 'Ngừng hoạt động'}
];

export const TaiKhoan = ({me}) => {
  const [statusId, setStatusId] = useState('N');

  useEffect(() => {
    if (statusId) {
      reloadLazyData();
    } else {
      setStatusId(statuses[0].value);
    }
  }, [statusId]);

  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    dataKey,
    columns: [
      {field: 'userId', header: 'Mã người dùng', minWidth: 100, matchMode: 'contains'},
      {field: 'username', header: 'Tên đăng nhập', minWidth: 160, matchMode: 'contains'},
      {field: 'userFullName', header: 'Họ và tên', minWidth: 180, matchMode: 'contains'},
      {field: 'roleType', header: 'Vai trò', minWidth: 100, matchMode: 'contains'},
      {field: 'emailAddress', header: 'Email', minWidth: 180, matchMode: 'contains'},
      {field: 'ownerPseudoId', header: 'Mã đại lý', minWidth: 120, matchMode: 'contains'},
      {field: 'ownerPartyName', header: 'Đại lý', minWidth: 200, matchMode: 'contains'},
      {field: 'ownerPartyTaxId', header: 'Mã số thuế', minWidth: 180, matchMode: 'contains'},
      // {field: 'disabled', header: 'khóa?', minWidth: 180, matchMode: 'contains'},

    ],
    getList: lazyLoadEvent => {
      if (statusId) {
        return Service.getAccountList(lazyLoadEvent, statusId);
      } else {
        return Promise.resolve({});
      }
    }
  });
  const doDisable = () => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.disableAccount(selectedItem[dataKey]).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    }
  }
  const doEnable = () => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.enableAccount(selectedItem[dataKey]).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    }
  }
  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const {render: renderDialogResetPassword, update: resetPassword} = useDialogCrup({
    header: '!Đặt lại mật khẩu',
    dataKey,
    width: '40rem',
    fields: [
      {field: 'password', header: 'Mật khẩu mới', required: true, type: 'Password', className: 'md:col-12'}
    ],
    updateItem: (id, item) => {
      return Service.resetPassword(id, item);
    }
  });

  const {renderToolbar} = useToolbar({
    leftButtons: <Fragment>
      <Button label="Đặt lại mật khẩu" icon="pi pi-history" severity="warning" size="small" onClick={() => resetPassword(selectedItem)} disabled={!selectedItem}/>
      {statusId === statuses[0].value && <Button label="Khoá" icon="pi pi-lock" severity="danger" size="small" onClick={doDisable} disabled={!selectedItem}/>}
      {statusId === statuses[1].value && <Button label="Mở khoá" icon="pi pi-lock" severity="success" size="small" onClick={doEnable} disabled={!selectedItem}/>}
    </Fragment>
  });

  return (
    <div className="grid">
      <div className="col-12 pb-0">
        <SelectButton value={statusId} options={statuses} onChange={e => setStatusId(e.value)}/>
      </div>
      <div className="col-12">
        {renderToolbar()}
      </div>
      <div className="col-12 py-0" style={{maxHeight: 'calc(100vh - 15.5rem)'}}>
        {renderDataTable()}
        {renderDialogResetPassword()}
      </div>
    </div>
  );
}

