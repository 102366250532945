import {Fragment, useEffect, useState} from 'react';
import {classNames} from 'primereact/utils';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {FormatDisplay, useDataTableBasic} from '@iamsoftware/react-hooks';

import {TokenService} from "../TokenService";

export const TokenHistory = ({display, setDisplay}) => {

  const [items, setItems] = useState([]);

  useEffect(() => {
    if (display) {
      TokenService.get(display.snToken).then(({listData}) => {
        setItems(listData);
      });
    }
  }, [display]);

  const {render} = useDataTableBasic({
    dataKey:'certificateSn',
    columns: [
      {field: 'certificateSn', header: 'CertSn', width: 150, matchMode: 'contains'},
      {field: 'vendorPseudoId', header: 'CA', width: 150, matchMode: 'contains'},
      {field: 'productName', header: 'Gói dịch vụ', width: 150, matchMode: 'contains'},
      {field: 'status', header: 'Trạng thái', width: 150, matchMode: 'contains'},
      {field: 'customerPseudoId', header: 'Mã KH', width: 150, matchMode: 'contains'},
      {field: 'customerPartyName', header: 'Khách hàng', width: 150, matchMode: 'contains'},
      {field: 'effectiveDate', header: 'Ngày hiệu lực', width: 150, dataType:'date'},
      {field: 'expireDate', header: 'Thời hạn CTS', width: 150, dataType:'date'},
      {
        field: 'isCancelled', header: 'Đã hủy', width: 150, dataType: 'custom', customCell(rowData: any): any {
          if (rowData.isCancelled) {
            let innerText;
            if (rowData.isCancelled === 'Y') {
              innerText = <Fragment>
                {rowData.cancelReasonEnum && <p className="mb-1">Lý do hủy: {rowData.cancelReasonEnum}</p>}
              </Fragment>;
            }
            return <div >
              <i className={classNames('pi', 'true-icon pi-check-circle', 'flex', ' justify-content-center')}/>
              {innerText}
            </div>
          } else {
            return <i className={classNames ('pi',  'false-icon pi-times-circle', 'flex', ' justify-content-center' )}/>;
          }
        }, style: {justifyContent: 'center'}
      },
    ],
    items
  });


  const footer = (
    <div>
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(false)}/>
    </div>
  );
  const header = (
    <div>
      <h4> Lịch sử sử dụng Token</h4>
    </div>
  );

  return (
    <Dialog header={header} footer={footer} visible={!!display} style={{width: '100'}} position="top" onHide={() => setDisplay(false)}>
      {render()}
    </Dialog>
  );
}