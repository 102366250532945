import {Fragment, useEffect, useState} from 'react';
import {Button} from 'primereact/button';
import {SelectButton} from 'primereact/selectbutton';
import {ConfirmDialogService, FormatDisplay, ToastService, useDataTable, useDataTableBasic, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {TokenService as Service} from '../TokenService';
import {TokenHistory} from './TokenHistory';


const dataKey = 'snToken';
export const TokenList = () => {
  const [displayTokenHistory, setDisplayTokenHistory] = useState(null)

  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    dataKey,
    columns: [
      {field: 'snToken', header: 'snToken', minWidth: 150, matchMode: 'contains'},
      {field: 'certificateSn', header: 'certSN', minWidth: 300, matchMode: 'contains'},
      {field: 'pseudoId', header: 'Mã khách hàng', minWidth: 150, matchMode: 'contains'},
      {field: 'partyName', header: 'Tên khách hàng', minWidth: 250, matchMode: 'contains'},
      {field: 'productName', header: 'Gói dịch vụ',  minWidth: 145, matchMode: 'contains'},
      {field: 'effectiveDate', header: 'Ngày hiệu lực', minWidth: 130, dataType:'date'},
      {field: 'expireDate', header: 'Thời hạn CTS', minWidth: 130, dataType:'date'},
      {field: 'expireContractDate', header: 'Thời hạn dịch vụ', minWidth: 130, dataType:'date'},
      {field: 'certificateAuthorityName', header: 'CA', minWidth: 150, matchMode: 'contains'},
      {field: 'status', header: 'Trạng thái CTS', minWidth: 150, matchMode: 'contains'},
    ],
    getList: lazyLoadEvent => {
        return Service.getList(lazyLoadEvent);
    }
  });



  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const {render: renderDialogCrup, create, view, update} = useDialogCrup({
    header: '!Lịch sử sử dụng Token',
    dataKey,
    width: '80rem',
    fields: [
      {field: 'pseudoId', header: 'Mã nhân viên', required: true, className: 'md:col-4'},
    ],
    createItem: item => {
      return Service.create(item);
    },
    updateItem : (id, item) => {
      return Service.update(id, item);
    },
    reloadLazyData
  });


  const doCreate = () => {
    create();
  }
  const doUpdate = (doView?: boolean) => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.get(selectedItem[dataKey]).then(data => {
        data.birthDate = FormatDisplay.date(data.birthDate, 'YYYY-MM-DD');
        if (doView === true) {
          view(data);
        } else {
          update(data);
        }
      });
    }
  }



  const {renderToolbar} = useToolbar({
    hasSelectedItem: selectedItem,
    leftButtons: <Fragment>
      <Button label="Lịch sử dùng Token" icon="pi pi-history" severity="warning" size="small" onClick={() => setDisplayTokenHistory(selectedItem)} disabled={!selectedItem}/>
    </Fragment>
  });

  return (
    <div className="grid">
      <div className="col-12">
        {renderToolbar()}
      </div>
      <div className="col-12 py-0" style={{maxHeight: 'calc(100vh - 15.5rem)'}}>
        {renderDataTable()}
      </div>
      {renderDialogCrup()}
      <TokenHistory display={displayTokenHistory} setDisplay={setDisplayTokenHistory}/>
    </div>
  );
}
