import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {Slider} from 'primereact/slider';

import {useDialogCrup, useForm} from '@iamsoftware/react-hooks';

import {ProfileService as Service} from './ProfileService';
import {CommonService} from '../../../service/CommonService';

import {RequestDetail} from './RequestDetail';
import {IamPermission} from './../../../shared/utils/Permission';
import {NoteHistory} from './NoteHistory';


export const Detail = ({display, setDisplay, selectedItem, refreshLazyData, doCompleteDocument, me}) => {
  const [detail, setDetail] = useState(null);
  const [previewPdfSrc, setPreviewPdfSrc] = useState(null);
  const [previewImgSrc, setPreviewImgSrc] = useState(null);
  const [previewImgWidth, setPreviewImgWidth] = useState(null);
  const [previewImgMaxWidth, setPreviewImgMaxWidth] = useState(null);
  const [displayNoteHistory, setDisplayNoteHistory] = useState(null);

  const [provinces, setProvinces] = useState([]);
  const [counties, setCounties] = useState([]);

  useEffect(() => {
    CommonService.getProvinces().then(data => {
      setProvinces(data.listData.map(item => {
        return {value: item.toGeoId, label: item.geoName}
      }));
    });
  }, []);

  useEffect(() => {
    const body = document.getElementsByTagName('BODY')[0];
    if (display) {
      body.classList.add('has-dialog-maximized');
    } else {
      body.classList.remove('has-dialog-maximized');
    }
  }, [display]);

  useEffect(() => {
    if (display && selectedItem) {
      clear();
      load();
    }
  }, [display, selectedItem]); // eslint-disable-line react-hooks/exhaustive-deps

  const load = () => {
    setDetail(null);
    Service.getDetail(selectedItem.partyCertId, selectedItem.requestId).then(data => {
      if (data.effectiveDate) {
        data.effectiveDate = new Date(data.effectiveDate);
      }
      if (data.expireDate) {
        data.expireDate = new Date(data.expireDate);
      }
      if (data.documentCompletedDate) {
        data.documentCompletedDate = new Date(data.documentCompletedDate);
      }
      resetFormDetail();
      setValueFormDetail(data);

      setDetail(data);
    });
  }

  const clear = () => {
    setPreviewPdfSrc(null);
    setPreviewImgSrc(null);
  }

  const reload = () => {
    load();
    refreshLazyData();
  }

  const {render: renderFormDetail, reset: resetFormDetail, setValue: setValueFormDetail} = useForm({
    fields: [
      {field: 'partyClassificationId', type: 'hidden'},
      {field: 'partyClassification', header: 'Loại chứng thư', className: 'md:col-12'},
      {type: 'custom', className: 'md:col-12 mt-2', body: <b>Thông tin khách hàng</b>},
      {field: 'partyName', header: 'Tên thuê bao', className: 'md:col-7'},
      {field: 'idValue', header: detail?.partyIdTypeEnum, className: 'md:col-5'},
      {field: 'stateProvinceGeoName', header: 'Tỉnh/Thành phố', className: 'md:col-3'},
      {field: 'countyGeoName', header: 'Quận/Huyện', className: 'md:col-3'},
      {field: 'emailAddress', header: 'Email', className: 'md:col-3'},
      {field: 'phoneNumber', header: 'Số điện thoại', className: 'md:col-3'},
      {type: 'custom', className: 'md:col-12 mt-2', body: <b>Thông tin CTS</b>},
      {field: 'productName', header: 'Gói dịch vụ', className: 'md:col-6'},
      {field: 'effectiveDate', header: 'Ngày có hiệu lực', type: 'Calendar', CalendarProps: {showTime: true, showSeconds: true}, className: 'md:col-3'},
      {field: 'expireDate', header: 'Ngày hết hạn', type: 'Calendar', CalendarProps: {showTime: true, showSeconds: true}, className: 'md:col-3'},
      {field: 'profileStatus', header: 'Trạng thái hồ sơ', className: 'md:col-6'},
      {field: 'status', header: 'Trạng thái CTS', className: 'md:col-6'},
      {field: 'certCode', header: 'Mã cấp CTS', className: 'md:col-3'},
      {field: 'snToken', header: 'SN Token', className: 'md:col-3'},
      {field: 'certificateSn', header: 'Certificate SN', className: 'md:col-6'},
      {field: 'documentCompletedDate', header: 'Ngày nộp đủ hồ sơ', type: 'Calendar', className: 'md:col-6'},
      {
        field: 'relatedPartyName', header: 'Người đại diện', displayDependency(item: any): boolean {
          return item?.partyClassificationId === 'PcltCertEnterprise';
        }, className: 'md:col-6'
      },
      {
        field: 'comments', header: 'Ghi chú', className: 'md:col-6'
      },
      {
        field: 'updateComments', type: 'custom',
        body: <Fragment>
          <div className="flex justify-content-center ml-5">
            <Button icon="pi pi-pencil" rounded severity="warning" size="small" onClick={() => updateNote(selectedItem)}/>
            <Button icon="pi pi-history" className="ml-2" rounded severity="info" size="small" onClick={() => setDisplayNoteHistory(selectedItem)}/>
          </div>
        </Fragment>
        , className: 'md:col-1 pl-0 pt-4'
      },
    ],
    formClassName: 'detail',
    readOnly: true
  });

  const {render: renderDialogUpdateInfo, update: updateInfo, form} = useDialogCrup({
    header: 'Thông tin khách hàng',
    dataKey: 'requestId',
    width: '60rem',
    fields: [
      {field: 'partyTaxId', header: 'MST', disabled: true, className: 'md:col-4'},
      {field: 'partyName', header: 'Tên khách hàng', disabled: true, className: 'md:col-8'},
      {field: 'stateProvinceGeoId', header: 'Tỉnh/Thành phố', required: true, type: 'Dropdown', DropdownProps: {options: provinces, filter: true}, className: 'md:col-6'},
      {field: 'countyGeoId', header: 'Quận/Huyện', required: true, type: 'Dropdown', DropdownProps: {options: counties, filter: true}, className: 'md:col-6'},
      {field: 'emailAddress', header: 'Email', required: true, InputTextProps: {maxLength: 50}, className: 'md:col-6'},
      {field: 'phoneNumber', header: 'Số điện thoại', required: true, InputTextProps: {maxLength: 17, keyfilter: 'int'}, className: 'md:col-6'},
      {field: 'relatedPartyName', header: 'Người đại diện', className: 'md:col-6'}
    ],
    updateItem(id: string, item: any): Promise<any> {
      return Service.updateInfo(id, item);
    },
    reloadLazyData: reload
  });

  const _value = form.getValue();

  const {render: renderUpdateNote, update: updateNote} = useDialogCrup({
    header: '!Thay đổi ghi chú',
    dataKey: 'requestId',
    width: '40rem',
    fields: [
      {field: 'comments', header: 'Ghi chú', className: 'md:col-12'}
    ],
    updateItem(id: string, item: any): Promise<any> {
      return Service.updateNote(id, item);
    },
    reloadLazyData: reload
  });


  useEffect(() => {
    if (_value.stateProvinceGeoId) {
      CommonService.getCounties(_value.stateProvinceGeoId).then(data => {
        setCounties(data.listData.map(item => {
          return {value: item.toGeoId, label: item.geoName}
        }));
      });
    }
  }, [_value.stateProvinceGeoId]);

  const doUpdateInfo = () => {
    const data = Object.assign({}, detail);
    data.partyTaxId = detail.idValue;
    updateInfo(data);
  }

  const onPreviewImgLoad = e => {
    setPreviewImgWidth(Math.min(500, e.target.naturalWidth));
    setPreviewImgMaxWidth(e.target.naturalWidth);
  }

  const downloadDK02 = () => {
    window.open(`${Service.baseURL}/${Service.entity}/${detail.requestId}/dk02-download?partyCertId=${detail.partyCertId}`);
  }

  const footer = (
    <div>
      {detail?.dk02Download === 'Y' && <Button label="Tải pdf DK02" icon="pi pi-download" type="button" severity="success" size="small" onClick={downloadDK02}/>}
      {(IamPermission.has('UPDATE') || ['Administrator', 'Agent', 'Employee'].includes(me.roleTypeId)) && ['PpcDocumentIncomplete'].includes(selectedItem?.profileStatusId) && <Button label="Đã nhận đủ hồ sơ" icon="pi pi-check" type="button" severity="info" size="small" onClick={() => doCompleteDocument(detail)}/>}
      {(IamPermission.has('UPDATE') || ['Administrator', 'Agent', 'Employee'].includes(me.roleTypeId)) && <Button label="Chỉnh sửa thông tin khách hàng" icon="pi pi-pencil" type="button" severity="warning" size="small" onClick={doUpdateInfo}/>}
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(false)}/>
    </div>
  );

  return (
    <Fragment>
      <Dialog header="Chi tiết CTS" footer={footer} visible={display} maximized={true} draggable={false} onHide={() => setDisplay(false)}>
        <div className="grid pt-2">
          <div className="col-7">
            <div className="grid">
              <div className="col-12">
                {renderFormDetail()}
                {/*<Button label="Cập nhật ghi chú" icon="pi pi-pencil" severity="warning" size="small" onClick={()=>updateNote(selectedItem)} ></Button>*/}
                {/*<Button label="Lịch sử ghi chú" className="ml-3" icon="pi pi-history" severity="secondary" size="small" onClick={()=>setDisplayNoteHistory(selectedItem)} ></Button>*/}
              </div>
              <RequestDetail detail={detail} reload={reload} setPreviewPdfSrc={setPreviewPdfSrc} setPreviewImgSrc={setPreviewImgSrc}/>
            </div>
          </div>
          <div className="col-5">
            <div className="card-container blue-container pr-2" style={{position: 'fixed', width: '39.5vw', height: '80vh'}}>
              {previewPdfSrc &&
								<iframe className="iframe-full" src={previewPdfSrc} title="template-preview"/>
              }
              {previewImgSrc &&
								<div className="grid w-full h-full">
									<div className="col-12">
										<Slider value={previewImgWidth} min={300} max={previewImgMaxWidth} onChange={e => setPreviewImgWidth(e.value)}/>
									</div>
									<div className="col-12 iam-scroll" style={{width: '100%', height: 'calc(100% - 30px)', overflow: 'auto'}}>
										<img src={previewImgSrc} alt="" style={{width: `${previewImgWidth}px`, maxWidth: previewImgMaxWidth}} onLoad={onPreviewImgLoad}/>
									</div>
								</div>
              }
            </div>
          </div>
        </div>
      </Dialog>
      {renderDialogUpdateInfo()}
      {renderUpdateNote()}
      <NoteHistory display={displayNoteHistory} setDisplay={setDisplayNoteHistory}/>

    </Fragment>
  );
}