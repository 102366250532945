import {Service} from './Service';

export class PartyService extends Service {

  static entity = 'vinca-party';

  static getByTaxCode(partyTaxId: string, includeRepresentative: string): Promise<any> {
    return this.axios.get(`${this.entity}/info/by-tax-code`, {params: {partyTaxId, includeRepresentative}}).then(res => res.data);
  }

}
