import {EntityService} from '../../../service/EntityService';

export class CertificationService extends EntityService {

  static entity = 'vinca-request/certification';

  static getList(status: string, lazyLoadEvent: string, params?: any): Promise<any> {
    return this.axios.get(`${this.entity}/${status}`, {params}).then(res => res.data);
  }

  static createAndSend(data: any): Promise<any> {
    return this.axios.post(`${this.entity}/create-and-send`, data).then(res => res.data);
  }

  static getStatusHistories(requestId: string): Promise<any> {
    return this.axios.get(`${this.entity}/${requestId}/status-histories`).then(res => res.data);
  }

  static update(requestId: string, data: any): Promise<any> {
    return this.axios.post(`${this.entity}/${requestId}/update`, data).then(res => res.data);
  }

  static send(requestId: string, lastUpdatedStamp: number): Promise<any> {
    return this.axios.put(`${this.entity}/${requestId}/send`, {lastUpdatedStamp}).then(res => res.data);
  }

  static accept(requestId: string, lastUpdatedStamp: number): Promise<any> {
    return this.axios.put(`vinca-request/approval-cert/accept`, {requestId, lastUpdatedStamp}).then(res => res.data);
  }

  static reject(data: any): Promise<any> {
    return this.axios.put(`vinca-request/approval-cert/reject`, data).then(res => res.data);
  }

  static getCategories(): Promise<any> {
    return this.axios.get(`${this.entity}/categories`).then(res => res.data);
  }

  static getRequestTypes(): Promise<any> {
    return this.axios.get(`${this.entity}/types`).then(res => res.data);
  }

  static getVendors(): Promise<any> {
    return this.axios.get(`vinca-party/vendors/list`).then(res => res.data);
  }

  static getRequestStatus(): Promise<any> {
    return this.axios.get(`vinca-common/request-statuses`).then(res => res.data);
  }

  static getRequestType(): Promise<any> {
    return this.axios.get(`vinca-request/certification/types`).then(res => res.data);
  }

  static getProducts(params: any): Promise<any> {
    return this.axios.get(`${this.entity}/products`, {params}).then(res => res.data);
  }

  static getFeatures(): Promise<any> {
    return this.axios.get(`${this.entity}/features`).then(res => res.data);
  }

  static cancel(requestId: string, data: any): Promise<any> {
    return this.axios.put(`vinca-request/revoke`, {requestId, data}).then(res => res.data);
  }

  static reApprove(data: any): Promise<any> {
    return this.axios.put(`vinca-request/approval-cert/approve-lcs`, data).then(res => res.data);
  }

  static accountActiveCTS(requestId: string): Promise<any> {
    return this.axios.get(`${this.entity}/${requestId}/detail`).then(res => res.data);
  }

  static syncStatus(requestId: string): Promise<any> {
    return this.axios.put(`${this.entity}/${requestId}/sync-status`).then(res => res.data);
  }



}
