import {useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {useDataTableBasic} from '@iamsoftware/react-hooks';

import {MessageService} from "../MessageService";

export const Histories = ({display, setDisplay}) => {

  const [items, setItems] = useState([]);

  useEffect(() => {
    if (display) {
      MessageService.GetMessageHistory(display.workEffortId, display.partyCertId).then(({listData}) => {
        setItems(listData);
      });
    }
  }, [display]);

  const {render} = useDataTableBasic({
    columns: [
      {field: 'entryDate', header: 'Ngày gửi', width: 150, dataType: 'date-time'},
      {field: 'toContactNumber', header: 'Sđt nhận', width: 150, matchMode: 'contains'},
      {field: 'externalId', header: 'Mã tin nhắn', minWidth: 150, matchMode: 'contains'},
      {field: 'subject', header: 'Nội dung', minWidth: 150, matchMode: 'contains'},
      {field: 'errorCode', header: 'Mã lỗi', minWidth: 100, matchMode: 'contains'},
      {field: 'errorMsg', header: 'Thông báo lỗi', minWidth: 150, matchMode: 'contains'},
      {
        field: '', header: 'Kết quả', width: 200, dataType: 'custom', customCell(rowData: any): any {
          let access;
          if (rowData.errorCode === '0') {
            access = <p>
              <i className="true-icon pi pi-check-circle mr-1"></i>
              <span>Thành công</span>
            </p>
          } else {
            access = <p>
              <i className="false-icon pi pi-times-circle mr-1"></i>
              <span>Thất bại</span>
            </p>
          }
          return <div>
            {access}
          </div>
        }
      }
    ],
    items
  });


  const footer = (
    <div>
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(false)}/>
    </div>
  );
  const header = (
    <div>
      <h4> Lịch sử gửi tin nhắn đến {display?.partyName}</h4>
    </div>
  );

  return (
    <Dialog header={header} footer={footer} visible={!!display} style={{width: '80rem'}} position="top" onHide={() => setDisplay(false)}>
      {render()}
    </Dialog>
  );
}