import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {SelectButton} from 'primereact/selectbutton';
import {ToastService, useDataTable, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {CategoryService as Service} from './CategoryService';

import {Products} from './Products';

const statuses = [
  {value: 'N', label: 'Đang hoạt động'},
  {value: 'Y', label: 'Ngừng hoạt động'}
];

export const NhomSanPham = () => {

  const header = 'Nhóm sản phẩm';
  const dataKey = 'productCategoryId';

  const [statusId, setStatusId] = useState(statuses[0].value);

  const [requiredParams, setRequiredParams] = useState(null);

  const [displayProducts, setDisplayProducts] = useState(null);

  useEffect(() => {
    if (statusId) {
      setRequiredParams({disabled: {value: statusId, matchMode: 'equals'}});
    } else {
      setRequiredParams(null);
    }
  }, [statusId]);

  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    dataKey,
    columns: [
      {field: 'pseudoId', header: `Mã ${header}`, width: 250, matchMode: 'contains'},
      {field: 'categoryName', header: `Tên ${header}`, minWidth: 250, matchMode: 'contains'}
    ],
    indexColumnWidth: 45,
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    },
    requiredParams
  });

  const {render: renderDialogCrup, create, update} = useDialogCrup({
    header,
    dataKey,
    width: '50rem',
    fields: [
      {field: 'pseudoId', header: `Mã ${header}`, className: 'md:col-12'},
      {field: 'categoryName', header: `Tên ${header}`, required: true, className: 'md:col-12'}
    ],
    createItem: item => {
      return Service.create(item);
    },
    updateItem: (id, item) => {
      return Service.update(id, item);
    },
    reloadLazyData
  });

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const doDisable = () => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.disable(selectedItem[dataKey]).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    }
  }
  const doEnable = () => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.enable(selectedItem[dataKey]).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    }
  }

  const {renderToolbar} = useToolbar({
    doCreate: create,
    hasSelectedItem: selectedItem,
    doUpdate() {
      update(selectedItem);
    },
    leftButtons: <Fragment>
      {statusId === statuses[0].value && <Button label="Khoá" icon="pi pi-lock" severity="danger" size="small" onClick={doDisable} disabled={!selectedItem}/>}
      {statusId === statuses[1].value && <Button label="Mở khoá" icon="pi pi-lock" severity="success" size="small" onClick={doEnable} disabled={!selectedItem}/>}
      <Button label="Danh sách sản phẩm" icon="pi pi-sitemap" severity="help" size="small" onClick={() => setDisplayProducts(selectedItem[dataKey])} disabled={!selectedItem}/>
    </Fragment>
  });

  return (
    <div className="grid">
      <div className="col-12 pb-0">
        <SelectButton value={statusId} options={statuses} onChange={e => setStatusId(e.value)}/>
      </div>
      <div className="col-12">
        {renderToolbar()}
      </div>
      <div className="col-12 py-0" style={{maxHeight: 'calc(100vh - 20rem)'}}>
        {renderDataTable()}
      </div>

      {renderDialogCrup()}

      <Products display={displayProducts} setDisplay={setDisplayProducts}/>
    </div>
  );
}