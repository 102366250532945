import {useEffect, useState} from 'react';

import {Dialog} from 'primereact/dialog';
import {useDataTableBasic} from '@iamsoftware/react-hooks';

import {CustomerService} from '../../khach-hang/CustomerService';

export const SelectCert = ({display, setDisplay, setCert}) => {

  const [items, setItems] = useState([]);

  useEffect(() => {
    if (display?.partyId && display?.requestTypeEnumId && (display?.idValue || display?.partyTaxId) && display?.fromPartyId) {
      setSelectedItems(null);

      CustomerService.getCertificates(display.partyId.value || display.partyId, {
        requestTypeEnumId: display.requestTypeEnumId,
        idValue: display.idValue || display.partyTaxId,
        vendorPartyId: display.fromPartyId
      }).then(data => {
        setItems(data.listData);
      });
    }
  }, [display]); // eslint-disable-line react-hooks/exhaustive-deps

  const {render, selectedItems, setSelectedItems} = useDataTableBasic({
    columns: [
      {field: 'certificateSn', header: 'Certificate SN', minWidth: 180, matchMode: 'contains'},
      {field: 'snToken', header: 'SN Token', width: 200, matchMode: 'contains'},
      {field: 'effectiveDate', header: 'Ngày có hiệu lực', dataType: 'date-time', width: 150},
      {field: 'expireDate', header: 'Ngày hết hạn', dataType: 'date-time', width: 150}
    ],
    items
  });

  useEffect(() => {
    if (selectedItems) {
      setCert(selectedItems);
      setDisplay(null);
    }
  }, [selectedItems]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog header="Chọn Certificate" visible={!!display} style={{width: '80rem'}} onHide={() => setDisplay(null)}>
      <div className="pb-3">
        {render()}
      </div>
    </Dialog>
  );
}