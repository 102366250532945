import {createRoot} from 'react-dom/client';
import {HashRouter, Route, Routes} from 'react-router-dom';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import {AppConfirmDialog, AppToast, DefaultConf, ScrollToTop} from '@iamsoftware/react-hooks';

import '@iamsoftware/react-hooks/assets/style.scss';

import './App.scss';

import {ConfirmPopup} from 'primereact/confirmpopup';

import {AppLoading} from './AppLoading';

import {Login} from './pages/Login';
import {Error} from './pages/Error';
import {NotFound} from './pages/NotFound';
import {Access} from './pages/Access';
import {AppWrapper} from './app/AppWrapper';

DefaultConf.DataTable.ItemsField = 'listData';

const root = createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <HashRouter>
    <ScrollToTop>
      <Routes>
        <Route path="/login" element={<Login/>}/>
        <Route path="/error" element={<Error/>}/>
        <Route path="/notfound" element={<NotFound/>}/>
        <Route path="/access" element={<Access/>}/>
        <Route path="*" element={<AppWrapper/>}/>
      </Routes>
      <AppLoading/>
      <AppToast/>
      <AppConfirmDialog/>
      <ConfirmPopup/>
    </ScrollToTop>
  </HashRouter>
);
