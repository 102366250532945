import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {OverlayPanel} from 'primereact/overlaypanel';

import {Button} from 'primereact/button';
import {classNames} from 'primereact/utils';
import {FormatDisplay, ToastService, useDataTable, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {ProfileService as Service} from './ProfileService';

import {Detail} from './Detail';
import {certStatusAlert} from '../yeu-cau';
import {IamPermission} from './../../../shared/utils/Permission';

export const DanhSach = ({me}) => {

  const dataKey = 'requestId';
  const {status} = useParams();
  const op = useRef(null);
  const [docList, setDocList] = useState(null)
  const [docListMissing, setDocListMissing] = useState(null)

  const [requiredParams, setRequiredParams] = useState(null);
  const [displayDetail, setDisplayDetail] = useState(null);


  useEffect(() => {
    if (status) {
      setRequiredParams({profileStatusId: {value: status.split(','), matchMode: 'in'}});
    } else {
      setRequiredParams({});
    }
  }, [status]);


  const {render: renderDataTable, selectedItems, refreshLazyData, reloadLazyData} = useDataTable({
    dataKey,
    columns: [
      {
        field: 'Giấy tờ', header: 'Giấy tờ', width: 85, dataType: 'custom', customCell(rowData: any): any {
          return <Fragment>
              <div className="flex justify-content-center">
                <i className="pi pi-file" onClick={(e) =>
                  doLoadDocData(rowData.requestId, rowData.partyCertId, e)
                }></i>
              </div>
          </Fragment>
        },
      },

      {field: 'requestPseudoId', header: 'Mã yêu cầu', minWidth: 130, matchMode: 'contains'},
      {field: 'partyIdent', header: 'MST/CCCD/CMND/HC', minWidth: 180, matchMode: 'contains'},
      {field: 'partyName', header: 'Tên thuê bao', minWidth: 150, matchMode: 'contains'},
      {field: 'partyIdTypeEnum', header: 'Loại khách hàng', minWidth: 150, matchMode: 'contains'},
      {field: 'productName', header: 'Gói dịch vụ', minWidth: 150, matchMode: 'contains'},
      {
        field: 'profileStatus', header: 'Trạng thái hồ sơ', minWidth: 180, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          let _className, innerText;
          switch (rowData.profileStatusId) {
            case 'PpcDocumentIncomplete':
              _className = 'alert alert-danger';
              break;
            case 'PpcDocumentCompleted':
              _className = 'alert alert-success';
              if (rowData.documentCompletedDate) {
                innerText = <p>Ngày {FormatDisplay.date(rowData.documentCompletedDate)}</p>
              }
              break;
            default:
              _className = 'alert alert-light';
          }
          let documentReceived;
          if (rowData.documentReceived === 'Y') {
            documentReceived = <p className="mt-1 mb-0">
              <i className="true-icon pi pi-check-circle mr-1"></i>
              <span>Đã nộp hồ sơ cứng</span>
            </p>
          } else {
            documentReceived = <p className="mt-1 mb-0">
              <i className="false-icon pi pi-times-circle mr-1"></i>
              <span>Chưa nộp hồ sơ cứng</span>
            </p>
          }
          let certAuthoritySent;
          if (rowData.certAuthoritySent === 'Y') {
            certAuthoritySent = <p className="mb-0">
              <i className="true-icon pi pi-check-circle mr-1"></i>
              <span>Đã gửi NCC</span>
            </p>
          } else {
            certAuthoritySent = <p className="mb-0">
              <i className="false-icon pi pi-times-circle mr-1"></i>
              <span>Chưa gửi NCC</span>
            </p>
          }
          return <div>
            <div className={_className}>{rowData.profileStatus}</div>
            {innerText}
            {documentReceived}
            {certAuthoritySent}
          </div>
        }
      },
      {
        field: 'status', header: 'Trạng thái CTS', minWidth: 150, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          if (!rowData.status) {
            return null;
          }
          return <span className={certStatusAlert(rowData.statusId)}>{rowData.status}</span>
        }
      },
      {field: 'certCode', header: 'Mã cấp CTS', minWidth: 220, matchMode: 'contains'},
      {field: 'snToken', header: 'SN Token', minWidth: 160, matchMode: 'contains'},
      {field: 'certificateSn', header: 'SN Cert', minWidth: 260, matchMode: 'contains'},
      {
        field: 'isCancelled', header: 'Đã hủy', width: 150, matchMode: 'equals', filterType: 'boolean-char', dataType: 'custom', customCell(rowData: any): any {
          if (rowData.isCancelled) {
            let innerText;
            if (rowData.isCancelled === 'Y') {
              innerText = <Fragment>
                {rowData.cancelledDate && <p className="mb-1">Ngày huỷ: {FormatDisplay.date(rowData.cancelledDate)}</p>}
                {rowData.cancelReasonEnum && <p className="mb-1">{rowData.cancelReasonEnum}</p>}
              </Fragment>;
            }
            return <div>
              <i className={classNames('pi', {'true-icon pi-check-circle': rowData.isCancelled === 'Y', 'false-icon pi-times-circle': rowData.isCancelled === 'N'})}/>
              {innerText}
            </div>
          } else {
            return null;
          }
        }, style: {justifyContent: 'center'}
      },
      {field: 'effectiveDate', header: 'Ngày có hiệu lực', minWidth: 150, dataType: 'date-time'},
      {field: 'expireDate', header: 'Ngày hết hạn', minWidth: 150, dataType: 'date-time'},
      {field: 'comments', header: 'Ghi chú', minWidth: 150,}
    ],
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    },
    requiredParams
  });

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const viewPartyCert = () => {
    setDisplayDetail(true);
  }
  const doLoadDocData = (requestId, partyCertId, e) => {
    Service.getProfileList(requestId, partyCertId).then(data => {
      op.current.toggle(e)
      setDocList(data.listData)
      setDocListMissing(data.listDataMissing)
    })
  }

  const {render: renderDialogCompleteDocument, update: completeDocument} = useDialogCrup({
    header: '!Đã nhận đủ hồ sơ',
    dataKey: 'requestId',
    width: '40rem',
    fields: [
      {field: 'documentCompletedDate', header: 'Ngày nộp đủ hồ sơ', type: 'Calendar', className: 'md:col-12'}
    ],
    updateItem(id: string, item: any): Promise<any> {
      return Service.completeDocument(id, item.documentCompletedDate);
    },
    reloadLazyData
  });


  const doCompleteDocument = request => {
    request.documentCompletedDate = new Date();
    completeDocument(request);
  }

  const incompleteDocument = request => {
    Service.incompleteDocument(request[dataKey]).then(() => {
      ToastService.success();
      reloadLazyData();
    })
  }

  const receivedDocument = request => {
    Service.receivedDocument(request[dataKey]).then(() => {
      ToastService.success();
      reloadLazyData();
    })
  }
  const unreceivedDocument = request => {
    Service.unreceivedDocument(request[dataKey]).then(() => {
      ToastService.success();
      reloadLazyData();
    })
  }

  const sentCertAuthority = request => {
    Service.sentCertAuthority(request[dataKey]).then(() => {
      ToastService.success();
      reloadLazyData();
    })
  }
  const unsentCertAuthority = request => {
    Service.unsentCertAuthority(request[dataKey]).then(() => {
      ToastService.success();
      reloadLazyData();
    })
  }

  const downloadExcel = () => {
    window.open(`${Service.baseURL}/${Service.entity}/excel`);
  }

  const {renderToolbar} = useToolbar({
    hasSelectedItem: selectedItem,
    doView: viewPartyCert,
    leftButtons: <Fragment>
      {(IamPermission.has('UPDATE') || ['Administrator', 'Agent', 'Employee'].includes(me.roleTypeId)) &&
				<Button label="Đã nhận đủ HS" icon="pi pi-check" severity="info" size="small" onClick={() => doCompleteDocument(selectedItem)} disabled={!(['PpcDocumentIncomplete'].includes(selectedItem?.profileStatusId))}/>}
      {(IamPermission.has('UPDATE') || ['Administrator', 'Agent', 'Employee'].includes(me.roleTypeId)) &&
				<Button label="Chưa nhận đủ HS" icon="pi pi-history" severity="secondary" size="small" onClick={() => incompleteDocument(selectedItem)} disabled={!(['PpcDocumentCompleted'].includes(selectedItem?.profileStatusId))}/>}
      {(IamPermission.has('UPDATE') || ['Administrator', 'Agent', 'Employee'].includes(me.roleTypeId)) &&
				<Button label="Đã nộp HS cứng" icon="pi pi-check" severity="info" size="small" onClick={() => receivedDocument(selectedItem)} disabled={selectedItem?.documentReceived === 'Y'}/>}
      {(IamPermission.has('UPDATE') || ['Administrator', 'Agent', 'Employee'].includes(me.roleTypeId)) &&
				<Button label="Chưa nộp HS cứng" icon="pi pi-history" severity="secondary" size="small" onClick={() => unreceivedDocument(selectedItem)} disabled={selectedItem?.documentReceived !== 'Y'}/>}
      {(IamPermission.has('UPDATE') || ['Administrator', 'Agent', 'Employee'].includes(me.roleTypeId)) &&
				<Button label="Đã gửi NCC" icon="pi pi-check" severity="info" size="small" onClick={() => sentCertAuthority(selectedItem)} disabled={selectedItem?.certAuthoritySent === 'Y'}/>}
      {(IamPermission.has('UPDATE') || ['Administrator', 'Agent', 'Employee'].includes(me.roleTypeId)) &&
				<Button label="Chưa gửi NCC" icon="pi pi-history" severity="secondary" size="small" onClick={() => unsentCertAuthority(selectedItem)} disabled={selectedItem?.certAuthoritySent !== 'Y'}/>}
      {['Administrator'].includes(me.roleTypeId) && <Button label="Xuất excel" icon="pi pi-file-excel" severity="help" size="small" onClick={downloadExcel}/>}
      <OverlayPanel ref={op}>
        <div className="p-datatable p-component p-datatable-gridlines p-datatable-sm" style={{width: '40rem'}}>
          <div className="p-datatable-wrapper">
            <table className="p-datatable-table">
              <tbody className="p-datatable-tbody">
              <tr>
                <td>Giấy tờ đã nộp:</td>
                <td><b><Fragment>
                  {docList?.map((parameter) =>
                    <div key={parameter} className='col-fixed'>
                      {parameter}
                    </div>
                  )}
                </Fragment></b></td>
              </tr>
              <tr>
                <td>Giấy tờ chưa nộp:</td>
                <td><b><Fragment>
                  {docListMissing?.map((parameter) =>
                    <div key={parameter} className='col-fixed'>
                      {parameter}
                    </div>
                  )}
                </Fragment></b></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </OverlayPanel>
    </Fragment>
  });

  return (
    <div className="grid">
      <div className="col-12">
        {renderToolbar()}
      </div>
      <div className="col-12 py-0" style={{maxHeight: 'calc(100vh - 15.5rem)'}}>
        {renderDataTable()}
      </div>

      {renderDialogCompleteDocument()}

      <Detail display={displayDetail} setDisplay={setDisplayDetail} selectedItem={selectedItem} refreshLazyData={refreshLazyData} doCompleteDocument={doCompleteDocument} me={me}/>
    </div>
  );
}
