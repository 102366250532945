import {EntityService} from '../../../service/EntityService';

export class ArtifactHitService extends EntityService {

  static entity = 'vinca-system/access-logs';

  static getListAccess(lazyLoadEvent: string, params?: any): Promise<any> {
    return this.axios.get(`${this.entity}/?lazyLoadEvent=${lazyLoadEvent}`, {params}).then(res => res.data);
  }

}