import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {ToastService, useDataTable, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {PricingRuleService as Service} from './PricingRuleService';
import {CategoryService} from '../../danh-muc/nhom-san-pham/CategoryService';
import {AgentService} from '../danh-sach/AgentService';

export const ChinhSachGia = ({me}) => {

  const header = 'Chính sách giá';
  const dataKey = 'agreementId';

  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [agentParties, setAgentParties] = useState([]);

  useEffect(() => {
    CategoryService.getProductsNotInCategory('A', '{}').then(({listData}) => {
      const _products = [];
      listData.forEach(item => {
        _products.push({value: item.productId, label: `[${item.pseudoId}] - ${item.productName}`});
      });
      setProducts(_products);
    });
    CategoryService.getList('{}').then(({listData}) => {
      const _products = [];
      listData.forEach(item => {
        _products.push({value: item.productCategoryId, label: `[${item.pseudoId}] - ${item.categoryName}`});
      });
      setCategories(_products);
    });
    AgentService.getTreeList('Y').then(data => {
      const _agentParties = Service.dataTreeTableToOptions(data.orgTree, data => {
        return `[${data.pseudoId}] - ${data.partyName}`;
      });
      setAgentParties(_agentParties);
    });
  }, []);

  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    dataKey,
    columns: [
      {field: 'agreementNo', header: 'Mã CSG', width: 150, matchMode: 'contains'},
      {field: 'agreementName', header: `Tên ${header}`, minWidth: 200, matchMode: 'contains'},
      {field: 'fromDate', header: 'Ngày hiệu lực', width: 130, dataType: 'date'},
      {field: 'thruDate', header: 'Đến ngày', width: 130, dataType: 'date'},
      {field: 'description', header: 'Mô tả', minWidth: 200, matchMode: 'contains'},
      {
        field: 'status', header: 'Trạng thái', width: 150, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          let _className;
          switch (rowData.statusId) {
            case 'AgrPrRlCreated':
              _className = 'alert alert-secondary';
              break;
            case 'AgrPrRlApproved':
              _className = 'alert alert-success';
              break;
            case 'AgrPrRlRejected':
              _className = 'alert alert-danger';
              break;
            default:
          }
          return <div className={_className}>{rowData.status}</div>
        }
      }
    ],
    indexColumnWidth: 45,
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    }
  });

  const {render: renderDialogCrup, create, view, update} = useDialogCrup({
    header,
    dataKey,
    width: '50rem',
    fields: [
      {field: 'discountPercentTermId', type: 'hidden'},
      {field: 'agreementNo', header: `Mã ${header}`, className: 'md:col-4'},
      {field: 'agreementName', header: `Tên ${header}`, required: true, className: 'md:col-8'},
      {field: 'discountPercent', header: 'Tỷ lệ chiết khấu', required: true, type: 'InputNumber', InputNumberProps: {min: 0, max: 100}, className: 'md:col-4'},
      {field: 'fromDate', header: 'Ngày hiệu lực', type: 'Calendar', className: 'md:col-4'},
      {field: 'thruDate', header: 'Đến ngày', type: 'Calendar', className: 'md:col-4'},
      {
        field: 'productIdList', header: 'Sản phẩm', type: 'MultiSelect',
        MultiSelectProps: {options: products, display: 'chip', filter: true},
        className: 'md:col-12'
      },
      {
        field: 'productCategoryIdList', header: 'Nhóm sản phẩm', type: 'MultiSelect',
        MultiSelectProps: {options: categories, display: 'chip', filter: true},
        className: 'md:col-12'
      },
      {
        field: 'agentPartyIdList', header: 'Đại lý', type: 'MultiSelect',
        MultiSelectProps: {options: agentParties, display: 'chip', filter: true},
        className: 'md:col-12'
      },
      {field: 'description', header: 'Mô tả', className: 'md:col-12'},
    ],
    createItem: item => {
      return Service.create(item);
    },
    updateItem: (id, item) => {
      return Service.update(id, item);
    },
    reloadLazyData
  });

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const doView = () => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.get(selectedItem[dataKey]).then(data => {
        view(data);
      });
    }
  }
  const doUpdate = () => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.get(selectedItem[dataKey]).then(data => {
        if (data.fromDate) {
          data.fromDate = new Date(data.fromDate);
        }
        if (data.thruDate) {
          data.thruDate = new Date(data.thruDate);
        }
        update(data);
      });
    }
  }

  const setStatus = statusId => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.setStatus(selectedItem[dataKey], statusId).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    }
  }

  const downloadExcel=()=>{
    window.open(`${Service.baseURL}/${Service.entity}/download`);
  }

  const {renderToolbar} = useToolbar({
    doCreate: ['Administrator'].includes(me.roleTypeId) && create,
    hasSelectedItem: selectedItem,
    doView,
    leftButtons: ['Administrator'].includes(me.roleTypeId) && <Fragment>
			<Button label="Chỉnh sửa" icon="pi pi-pencil" severity="warning" size="small" onClick={doUpdate} disabled={!['AgrPrRlCreated'].includes(selectedItem?.statusId)}/>
			<Button label="Khởi tạo" icon="pi pi-undo" severity="success" size="small" onClick={() => setStatus('AgrPrRlCreated')} disabled={!['AgrPrRlApproved', 'AgrPrRlRejected'].includes(selectedItem?.statusId)}/>
			<Button label="Duyệt" icon="pi pi-check" size="small" onClick={() => setStatus('AgrPrRlApproved')} disabled={!['AgrPrRlCreated'].includes(selectedItem?.statusId)}/>
			<Button label="Từ chối" icon="pi pi-times" severity="danger" size="small" onClick={() => setStatus('AgrPrRlRejected')} disabled={!['AgrPrRlCreated', 'AgrPrRlApproved'].includes(selectedItem?.statusId)}/>
		</Fragment>,
    rightButtons: <Fragment>
      <Button label="Xuất excel" icon="pi pi-file-excel" severity="help" size="small" onClick={downloadExcel}/>
    </Fragment>
  });

  return (
    <div className="grid">
      <div className="col-12">
        {renderToolbar()}
      </div>
      <div className="col-12 py-0" style={{maxHeight: 'calc(100vh - 20rem)'}}>
        {renderDataTable()}
      </div>

      {renderDialogCrup()}
    </div>
  );
}