import {Fragment} from 'react';

import {Button} from 'primereact/button';
import {useDataTable, useToolbar} from '@iamsoftware/react-hooks';

export const HopDong = () => {

  const dataKey = 'agreementId';

  const {render: renderDataTable, selectedItems} = useDataTable({
    dataKey,
    headerColumnGroups: [
      {header: '', colSpan: 5},
      {header: 'Bên mua', colSpan: 3},
      {header: 'Bên bán', colSpan: 2}
    ],
    columns: [
      {field: 'agreementId', header: 'Mã', width: 120, matchMode: 'contains'},
      {field: 'agreementTypeEnum', header: 'Loại hợp đồng', width: 150, matchMode: 'contains'},
      {field: 'agreementNo', header: 'Số hợp đồng', minWidth: 150, matchMode: 'contains'},
      {field: 'status', header: 'Trạng thái', width: 120, matchMode: 'contains'},
      {field: 'agreementDate', header: 'Ngày tạo', dataType: 'date', width: 100},
      {field: 'otherPartyName', header: 'Tên', minWidth: 150, matchMode: 'contains'},
      {field: 'otherPartyTaxId', header: 'Mã số thuế', width: 120, matchMode: 'contains'},
      {field: 'otherRepName', header: 'Người đại diện', width: 150, matchMode: 'contains'},
      {field: 'organizationPartyName', header: 'Tên', minWidth: 150, matchMode: 'contains'},
      {field: 'organizationPartyTaxId', header: 'Mã số thuế', width: 120, matchMode: 'contains'}
    ],
    getList: lazyLoadEvent => {
      return new Promise<any>(resolve => {
        resolve({
          listData: [
            {
              "agreementId": "111833",
              "statusId": "AgrtApproved",
              "status": "Chấp nhận",
              "agreementTypeEnumId": "AgrElectronicInvoiceNew",
              "agreementTypeEnum": "Hợp đồng mới",
              "agreementDate": 1671007888365,
              "agreementNo": "00002700/HĐĐT/VININVOICE",
              "organizationPartyAddr": "Số 10 ngõ 123/47 Thụy Phương, Thụy Phương, Bắc Từ Liêm, Hà Nội",
              "organizationPartyTaxId": "0109282176",
              "organizationPartyName": "CÔNG TY CP GIẢI PHÁP HÓA ĐƠN ĐIỆN TỬ VININVOICE",
              "organizationPartyId": "IAM_ORG",
              "otherPartyId": "118669",
              "otherPartyName": "CÔNG TY TNHH THIẾT KẾ ĐẦU TƯ XÂY DỰNG ĐẠI HOÀNG SƠN",
              "otherPartyAddr": "Số 10, đường ĐT743, Khu phố Thống Nhất 2, Phường Dĩ An, Thành phố Dĩ An, Tỉnh Bình Dương, Việt Nam",
              "otherPartyTaxId": "3702458798",
              "otherRepName": "Trần Đình Chiến",
              "requestId": "103719"
            },
            {
              "agreementId": "111832",
              "statusId": "AgrtApproved",
              "status": "Chấp nhận",
              "agreementTypeEnumId": "AgrElectronicInvoiceNew",
              "agreementTypeEnum": "Hợp đồng mới",
              "agreementDate": 1671007803755,
              "agreementNo": "00002699/HĐĐT/VININVOICE",
              "organizationPartyAddr": "Số 10 ngõ 123/47 Thụy Phương, Thụy Phương, Bắc Từ Liêm, Hà Nội",
              "organizationPartyTaxId": "0109282176",
              "organizationPartyName": "CÔNG TY CP GIẢI PHÁP HÓA ĐƠN ĐIỆN TỬ VININVOICE",
              "organizationPartyId": "IAM_ORG",
              "otherPartyId": "118666",
              "otherPartyName": "CÔNG TY TNHH HOÀN KHANG GIA PHÁT",
              "otherPartyAddr": "BN4, LK31-32, KP 7, Phường Thống Nhất, Thành phố Biên Hoà, Tỉnh Đồng Nai, Việt Nam",
              "otherPartyTaxId": "3603885936",
              "otherRepName": "Trần Đình Chiến",
              "requestId": "103718"
            },
            {
              "agreementId": "111781",
              "statusId": "AgrtCreated",
              "status": "Mới tạo",
              "agreementTypeEnumId": "AgrElectronicInvoiceNew",
              "agreementTypeEnum": "Hợp đồng mới",
              "agreementDate": 1671006959677,
              "agreementNo": "00002698/HĐĐT/VININVOICE",
              "organizationPartyAddr": "Số 10 ngõ 123/47 Thụy Phương, Thụy Phương, Bắc Từ Liêm, Hà Nội",
              "organizationPartyTaxId": "0109282176",
              "organizationPartyName": "CÔNG TY CP GIẢI PHÁP HÓA ĐƠN ĐIỆN TỬ VININVOICE",
              "organizationPartyId": "IAM_ORG",
              "otherPartyId": "118615",
              "otherPartyName": "CÔNG TY TNHH DỊCH VỤ VÀ THƯƠNG MẠI NÔNG SẢN THÁI SƠN",
              "otherPartyAddr": "Số 55 phố Tam Giang, Phường Trần Hưng Đạo, Thành phố Hải Dương, Tỉnh Hải Dương, Việt Nam",
              "otherPartyTaxId": "0801380603",
              "otherRepName": "NGUYỄN THỊ NGOAN",
              "requestId": "103707"
            },
            {
              "agreementId": "111731",
              "statusId": "AgrtCreated",
              "status": "Mới tạo",
              "agreementTypeEnumId": "AgrElectronicInvoiceNew",
              "agreementTypeEnum": "Hợp đồng mới",
              "agreementDate": 1671005155089,
              "agreementNo": "00002697/HĐĐT/VININVOICE",
              "organizationPartyAddr": "Số 10 ngõ 123/47 Thụy Phương, Thụy Phương, Bắc Từ Liêm, Hà Nội",
              "organizationPartyTaxId": "0109282176",
              "organizationPartyName": "CÔNG TY CP GIẢI PHÁP HÓA ĐƠN ĐIỆN TỬ VININVOICE",
              "organizationPartyId": "IAM_ORG",
              "otherPartyId": "118567",
              "otherPartyName": "CÔNG TY CỔ PHẦN SẢN XUẤT THƯƠNG MẠI ĐẠI TẤN PHÁT",
              "otherPartyAddr": "5 Đường Số 15A, Phường Bình Trị Đông B, Quận Bình Tân, Thành phố Hồ Chí Minh, Việt Nam",
              "otherPartyTaxId": "0317555988",
              "otherRepName": "TRỊNH BÍCH THỦY",
              "requestId": "103697"
            },
            {
              "agreementId": "111730",
              "statusId": "AgrtApproved",
              "status": "Chấp nhận",
              "agreementTypeEnumId": "AgrElectronicInvoiceNew",
              "agreementTypeEnum": "Hợp đồng mới",
              "agreementDate": 1671004016989,
              "agreementNo": "00002696/HĐĐT/VININVOICE",
              "organizationPartyAddr": "Số 10 ngõ 123/47 Thụy Phương, Thụy Phương, Bắc Từ Liêm, Hà Nội",
              "organizationPartyTaxId": "0109282176",
              "organizationPartyName": "CÔNG TY CP GIẢI PHÁP HÓA ĐƠN ĐIỆN TỬ VININVOICE",
              "organizationPartyId": "IAM_ORG",
              "otherPartyId": "118564",
              "otherPartyName": "Công ty Luật TNHH The Mes",
              "otherPartyAddr": "Tầng 7, tòa 389 Đê La Thành, Phường Thành Công, Quận Ba Đình, Thành phố Hà Nội, Việt Nam",
              "otherPartyTaxId": "0110202778",
              "otherRepName": "Trần Đình Chiến",
              "requestId": "103696"
            },
            {
              "agreementId": "111680",
              "statusId": "AgrtCreated",
              "status": "Mới tạo",
              "agreementTypeEnumId": "AgrElectronicInvoiceNew",
              "agreementTypeEnum": "Hợp đồng mới",
              "agreementDate": 1670917555147,
              "agreementNo": "00002695/HĐĐT/VININVOICE",
              "organizationPartyAddr": "Số 10 ngõ 123/47 Thụy Phương, Thụy Phương, Bắc Từ Liêm, Hà Nội",
              "organizationPartyTaxId": "0109282176",
              "organizationPartyName": "CÔNG TY CP GIẢI PHÁP HÓA ĐƠN ĐIỆN TỬ VININVOICE",
              "organizationPartyId": "IAM_ORG",
              "otherPartyId": "118517",
              "otherPartyName": "CÔNG TY TNHH HEALIVERSE",
              "otherPartyAddr": "116 Nguyễn Văn Thủ, Phường Đa Kao, Quận 1, Thành phố Hồ Chí Minh, Việt Nam",
              "otherPartyTaxId": "0317596409",
              "otherRepName": "LÂM NGỌC HẢI SƠN",
              "requestId": "103686"
            },
            {
              "agreementId": "111628",
              "statusId": "AgrtRejected",
              "status": "Từ chối",
              "agreementTypeEnumId": "AgrElectronicInvoiceNew",
              "agreementTypeEnum": "Hợp đồng mới",
              "agreementDate": 1669869771203,
              "agreementNo": "00002694/HĐĐT/VININVOICE",
              "organizationPartyAddr": "Số 10 ngõ 123/47 Thụy Phương, Thụy Phương, Bắc Từ Liêm, Hà Nội",
              "organizationPartyTaxId": "0109282176",
              "organizationPartyName": "CÔNG TY CP GIẢI PHÁP HÓA ĐƠN ĐIỆN TỬ VININVOICE",
              "organizationPartyId": "IAM_ORG",
              "otherPartyId": "118360",
              "otherPartyName": "test mail",
              "otherPartyAddr": "Cự Khê, Thanh Oai, Hà Nội",
              "otherPartyTaxId": "123112312",
              "requestId": "103675"
            },
            {
              "agreementId": "111579",
              "statusId": "AgrtRejected",
              "status": "Từ chối",
              "agreementTypeEnumId": "AgrElectronicInvoiceNew",
              "agreementTypeEnum": "Hợp đồng mới",
              "agreementDate": 1669793591310,
              "agreementNo": "00002693/HĐĐT/VININVOICE",
              "organizationPartyAddr": "Số 10 ngõ 123/47 Thụy Phương, Thụy Phương, Bắc Từ Liêm, Hà Nội",
              "organizationPartyTaxId": "0109282176",
              "organizationPartyName": "CÔNG TY CP GIẢI PHÁP HÓA ĐƠN ĐIỆN TỬ VININVOICE",
              "organizationPartyId": "IAM_ORG",
              "otherPartyId": "118314",
              "otherPartyName": "khach hang",
              "otherPartyAddr": "123, Cần Giờ, Hồ Chí Minh",
              "otherPartyTaxId": "123",
              "requestId": "103665"
            },
            {
              "agreementId": "111578",
              "statusId": "AgrtCreated",
              "status": "Mới tạo",
              "agreementTypeEnumId": "AgrElectronicInvoiceNew",
              "agreementTypeEnum": "Hợp đồng mới",
              "agreementDate": 1669792149607,
              "agreementNo": "00002692/HĐĐT/VININVOICE",
              "organizationPartyAddr": "Số 10 ngõ 123/47 Thụy Phương, Thụy Phương, Bắc Từ Liêm, Hà Nội",
              "organizationPartyTaxId": "0109282176",
              "organizationPartyName": "CÔNG TY CP GIẢI PHÁP HÓA ĐƠN ĐIỆN TỬ VININVOICE",
              "organizationPartyId": "IAM_ORG",
              "otherPartyId": "118312",
              "otherPartyName": "test 3",
              "otherPartyAddr": "test, Lục Nam, Bắc Giang",
              "otherPartyTaxId": "217461287",
              "requestId": "103664"
            },
            {
              "agreementId": "111577",
              "statusId": "AgrtCreated",
              "status": "Mới tạo",
              "agreementTypeEnumId": "AgrElectronicInvoiceNew",
              "agreementTypeEnum": "Hợp đồng mới",
              "agreementDate": 1669791260155,
              "agreementNo": "00002691/HĐĐT/VININVOICE",
              "organizationPartyAddr": "Số 10 ngõ 123/47 Thụy Phương, Thụy Phương, Bắc Từ Liêm, Hà Nội",
              "organizationPartyTaxId": "0109282176",
              "organizationPartyName": "CÔNG TY CP GIẢI PHÁP HÓA ĐƠN ĐIỆN TỬ VININVOICE",
              "organizationPartyId": "IAM_ORG",
              "otherPartyId": "118310",
              "otherPartyName": "test 2",
              "otherPartyAddr": "Hà Đông, Hà Nội",
              "otherPartyTaxId": "12345678",
              "requestId": "103663"
            }
          ], totalRecords: 10, ...lazyLoadEvent
        });
      });
    }
  });

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const doFly = () => {

  }

  const {renderToolbar} = useToolbar({
    doCreate: doFly,
    hasSelectedItem: selectedItem,
    doUpdate: doFly,
    doView: doFly,
    leftButtons: <Fragment>
      <Button label="Ký số" icon="pi pi-file-edit" onClick={doFly} disabled={!['ReqCreated', 'ReqRejected', 'ReqSentFailed', 'ReqSentRejected', 'ReqSentDataInvalid'].includes(selectedItem?.statusId)}/>
    </Fragment>
  });

  return (
    <div className="grid">
      <div className="col-12">
        {renderToolbar()}
      </div>
      <div className="col-12 py-0" style={{maxHeight: 'calc(100vh - 15.5rem)'}}>
        {renderDataTable()}
      </div>
    </div>
  );
}