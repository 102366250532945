import {Route, Routes} from 'react-router-dom';

import {LogDuLieu} from './log-du-lieu';
import {LogTruyCap} from './log-truy-cap';

export const HeThong = () => {

  return (
    <Routes>
      <Route path="log-du-lieu" element={<LogDuLieu/>}/>
      <Route path="log-truy-cap" element={<LogTruyCap/>}/>
    </Routes>
  );
}