import {Route, Routes} from 'react-router-dom';

import {YeuCau} from './yeu-cau';
import {DanhSach} from './danh-sach';

export const ChungThuSo = ({me}) => {

  return (
    <Routes>
      <Route path="yeu-cau/tao-moi" element={<YeuCau me={me} beCreate={true}/>}/>
      <Route path="yeu-cau/trang-thai/:status" element={<YeuCau me={me}/>}/>
      <Route path="yeu-cau" element={<YeuCau me={me}/>}/>
      <Route path="danh-sach/trang-thai/:status" element={<DanhSach me={me}/>}/>
      <Route path="danh-sach" element={<DanhSach me={me}/>}/>
    </Routes>
  );
}