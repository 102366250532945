import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';

import {InvoiceService as Service} from './../InvoiceService';

export const PDFFile = ({display, setDisplay}) => {
  const footer = (
    <div className="flex justify-content-between">
      <div>
        <Button label="Đóng" icon="pi pi-times" className="p-button-outlined p-button-secondary p-button-sm" type="button" onClick={() => setDisplay(false)}/>
      </div>
    </div>
  );

  return (
    <Dialog header={`Hóa đơn - ${display?.itemDescription || display?.invoiceId}`} footer={footer} visible={!!display} style={{width: '80rem', height: '100%',}} position="top" onHide={() => setDisplay(false)}>
      {display?.invoiceId &&
				<iframe src={`${Service.baseURL}/vinca-invoice/cert-invoices/${display.invoiceId}/pdf`} title="Hợp đồng"
								style={{width: '100%', height: '70vh', border: 'none'}}/>
      }
    </Dialog>
  );

}