import {useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {useDataTableBasic} from '@iamsoftware/react-hooks';

export const useContactParties = ({readOnly}) => {

    const dataKey = 'dataKey';

    const [contactParties, setContactParties] = useState([]);

    useEffect(() => {
        if (contactParties?.length) {
            setContactParties(prevContactParties => {
                prevContactParties.forEach((prevContactParty, index) => {
                    if (!prevContactParty[dataKey]) {
                        prevContactParty[dataKey] = `${index}`;
                    }
                });
                return [...prevContactParties];
            });
        } else {
            addBlankItem();
        }
    }, [JSON.stringify(contactParties)]); // eslint-disable-line react-hooks/exhaustive-deps

    const onEditorChange = (rowData, column, newValue) => {
        setContactParties(prevContactParties => prevContactParties.map(prevContactParty => {
            if (prevContactParty[dataKey] === rowData[dataKey]) {
                return {
                    ...prevContactParty,
                    [column.field]: newValue
                }
            }
            return prevContactParty;
        }));
    }

    const addBlankItem = () => {
        setContactParties(prevContactParties => {
            prevContactParties.push({});
            return [...prevContactParties];
        });
    }

    const header = (
        <div className="flex flex-wrap align-items-center justify-content-between gap-2">
            <span>Người liên hệ</span>
        </div>
    );

    const {render: renderContactParties} = useDataTableBasic({
        tableHeader: header,
        dataKey,
        columns: [
            {field: 'partyName', header: 'Tên người liên hệ', minWidth: 200,  dataType: 'editor', editorConfig: {onEditorChange, readOnly}},
            {field: 'jobTitle', header: 'Chức vụ', width: 180, dataType: 'editor', editorConfig: {onEditorChange, readOnly}},
            {field: 'emailAddress', header: 'Email', width: 200, dataType: 'editor', editorConfig: {onEditorChange, readOnly}},
            {field: 'phoneNumber', header: 'Số điện thoại', width: 180, dataType: 'editor', editorConfig: {onEditorChange, readOnly}}
        ],
        indexColumnWidth: 45,
        items: contactParties
    });

    const getContactParties = () => {
        return contactParties.filter(contactParty => contactParty.partyName);
    }

    return {renderContactParties, getContactParties, setContactParties};
}