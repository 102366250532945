import {useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {useDataTableBasic} from '@iamsoftware/react-hooks';

import {CertificationService} from './CertificationService';

export const Histories = ({display, setDisplay}) => {

  const [items, setItems] = useState([]);

  useEffect(() => {
    if (display) {
      CertificationService.getStatusHistories(display.requestId).then(({listData}) => {
        setItems(listData);
      });
    }
  }, [display]);

  const {render} = useDataTableBasic({
    columns: [
      {field: 'changedDate', header: 'Ngày thay đổi', width: 150, dataType: 'date-time'},
      {field: 'changedByUserName', header: 'Người thay đổi', width: 150, matchMode: 'contains'},
      {field: 'newValue', header: 'Trạng thái', minWidth: 150, matchMode: 'contains'},
      {field: 'changeReason', header: 'Lý do', minWidth: 150, matchMode: 'contains'}
    ],
    items
  });

  const footer = (
    <div>
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(false)}/>
    </div>
  );

  return (
    <Dialog header="Lịch sử phê duyệt" footer={footer} visible={!!display} style={{width: '80rem'}} position="top" onHide={() => setDisplay(false)}>
      {render()}
    </Dialog>
  );
}