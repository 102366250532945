import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {ToastService, useForm} from '@iamsoftware/react-hooks';

import {InvoiceService as Service} from "../InvoiceService";
import {useContactParties} from "../../../shared/utils/ContactParties";
import {useItemProducts} from "./ItemProducts";

export const useCrupRequest = ({reloadLazyData}) => {

  const [action, setAction] = useState('');
  const [provinces, setProvinces] = useState([]);
  const [counties, setCounties] = useState([]);
  const [taxAuthority, setTaxAuthority] = useState([])
  const [customerPartyId, setCustomerPartId] = useState([])
  const [itemProductDelIds, setItemProductDelIds] = useState([])

  const [requestId, setRequestId] = useState(null);
  const [display, setDisplay] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [update, setUpdate] = useState(false)
  const [create, setCreate] = useState(false)
  const [requestTypeEnumId, setRequestTypeEnumId] = useState(null)


  useEffect(() => {
    Service.getProvinces().then(data => {
      setProvinces(data.listData.map(item => {
        return {value: item.toGeoId, label: item.geoName}
      }));
    });
  }, []);


  const getByTaxCode = () => {
    const _value = form.getValue();
    if (_value.partyTaxId) {
      Service.getInfoByTaxCode(_value.partyTaxId).then(({data}) => {
        if (data?.partyName) {
          _value.partyId = '';
          _value.partyName = data.partyName;
          _value.stateProvinceGeoId = data.stateProvinceGeoId;
          _value.countyGeoId = data.countyGeoId;
          _value.address1 = data.address1;
          _value.taxAuthorityId = data.taxAuthorityId;
          _value.representativeName = data.representativeName;
          form.setValue(_value);
        }
      });
    }
  }


  const {renderContactParties, getContactParties, setContactParties} = useContactParties({readOnly: action === 'View'});
  const {renderProductItems, getProductItems, setProductItems} = useItemProducts({readOnly: true}, {update: update === true},{create:create===true});


  const form = useForm({
    fields: [
      {
        field: 'partyTaxId', header: 'Mã số thuế', display: (requestTypeEnumId !== 'RqtExtensionAgreement'), required: true, className: 'md:col-4'
      },
      {
        field: 'getByTaxCode', type: 'custom', display: (requestTypeEnumId !== 'RqtExtensionAgreement'), body: <Button icon="pi pi-search" rounded severity="success" size="small" onClick={getByTaxCode}/>, className: 'md:col-1 pl-0 pt-4'
      },
      {field: 'partyName', header: 'Tên khách hàng', display: (requestTypeEnumId !== 'RqtExtensionAgreement'), required: true, className: 'md:col-7'},
      {field: 'stateProvinceGeoId', header: 'Tỉnh/Thành phố', display: (requestTypeEnumId !== 'RqtExtensionAgreement'), required: true, type: 'Dropdown', DropdownProps: {options: provinces, filter: true}, className: 'md:col-3'},
      {field: 'countyGeoId', header: 'Quận/Huyện', display: (requestTypeEnumId !== 'RqtExtensionAgreement'), required: true, type: 'Dropdown', DropdownProps: {options: counties, filter: true}, className: 'md:col-3'},
      {field: 'address1', header: 'Địa chỉ đăng ký thuế', required:true, display: (requestTypeEnumId !== 'RqtExtensionAgreement'), className: 'md:col-6'},
      {field: 'taxAuthorityId', header: 'Cơ quan thuế', display: (requestTypeEnumId !== 'RqtExtensionAgreement'), type: 'Dropdown', DropdownProps: {options: taxAuthority, filter: true}, className: 'md:col-3'},
      {
        field: 'customerPartyId', header: 'Khách hàng', required: true, display: (requestTypeEnumId === 'RqtExtensionAgreement'),
        type: 'Dropdown', DropdownProps: {options: customerPartyId, filter: true}, className: 'md:col-12'
      },
      {field: 'description', header: 'Ghi chú', display: (requestTypeEnumId === 'RqtExtensionAgreement'), InputTextProps: {maxLength: 50}, className: 'md:col-3'},
      {field: 'emailAddress', header: 'Email', display: (requestTypeEnumId !== 'RqtExtensionAgreement'), InputTextProps: {maxLength: 50}, className: 'md:col-3'},
      {field: 'phoneNumber', header: 'Số điện thoại', display: (requestTypeEnumId !== 'RqtExtensionAgreement'), InputTextProps: {maxLength: 17, keyfilter: 'int'}, className: 'md:col-3'},
      {field: 'faxNumber', header: 'Số fax', display: (requestTypeEnumId !== 'RqtExtensionAgreement'), InputTextProps: {maxLength: 17, keyfilter: 'int'}, className: 'md:col-3'},
      {field: 'webAddress', header: 'Địa chỉ Website', display: (requestTypeEnumId !== 'RqtExtensionAgreement'), className: 'md:col-3'},
      {field: 'bankAccount', header: 'Tài khoản ngân hàng', display: (requestTypeEnumId !== 'RqtExtensionAgreement'), className: 'md:col-3'},
      {field: 'bankName', header: 'Tên ngân hàng', display: (requestTypeEnumId !== 'RqtExtensionAgreement'), className: 'md:col-3'},
      {field: 'representativeName', header: 'Tên người đại diện', display: (requestTypeEnumId !== 'RqtExtensionAgreement'), className: 'md:col-3'},
      {field: 'representativeEmail', header: 'Email người đại diện', display: (requestTypeEnumId !== 'RqtExtensionAgreement'), className: 'md:col-3'},
      {field: 'representativeJobTitle', header: 'Chức vụ người đại diện', display: (requestTypeEnumId !== 'RqtExtensionAgreement'), className: 'md:col-3'},
      {field: 'representativePhoneNumber', header: 'Số điện thoại người đại diện', display: (requestTypeEnumId !== 'RqtExtensionAgreement'), className: 'md:col-3'},
      {type: 'custom', display: (requestTypeEnumId !== 'RqtExtensionAgreement'), body: renderContactParties()},
      {type: 'custom', body: renderProductItems()}
    ],
    disabled
  });
  const item = form.getValue();

  useEffect(() => {
    if (item.stateProvinceGeoId) {
      Service.getCounties(item.stateProvinceGeoId).then(data => {
        setCounties(data.listData.map(item => {
          return {value: item.toGeoId, label: item.geoName}
        }));
      });
    }
  }, [item.stateProvinceGeoId]);

  useEffect(() => {
    if (item.stateProvinceGeoId) {
      Service.getTaxAuthority(item.stateProvinceGeoId).then(data => {
        setTaxAuthority(data.listData.map(item => {
          return {value: item.taxAuthorityId, label: item.description}
        }));
      });
    }
  }, [item.stateProvinceGeoId]);


  const doCreate = () => {
    setUpdate(false)
    setCreate(true)
    setRequestTypeEnumId(null)
    setDisabled(false);
    setContactParties([]);
    setProductItems([])
    setDisplay(Date.now());
    form.reset();
  }

  const doUpdate = (requestId: string, data: any, doView: boolean) => {
    setCreate(false)
    setRequestTypeEnumId(data.requestTypeEnumId)
    setRequestId(data.requestId)
    form.setValue(data);
    setContactParties(data.contactParties);
    setProductItems(data.itemProducts);
    let orderItemSeqId = {
      prodItem: data.itemProducts.map(item => {
        return item.orderItemSeqId
      })
    }
    setItemProductDelIds(orderItemSeqId.prodItem)
    setUpdate(true)
    if (doView === true) {
      setDisabled(true);
      setDisplay(Date.now());
      setAction('View')
      setUpdate(false)
    } else {
      setDisabled(false);
      setDisplay(Date.now());
      setAction('Update')
      Service.customerList().then(data => {
        setCustomerPartId(data.listData.map(item => {
          return {value: item.partyId, label: `[${item.pseudoId}] - ${item.partyName}`}
        }));
      })
    }
  }

  const onSubmit = event => {
    if (form.valid()) {
      if (update === false) {
        item.contactParties = getContactParties();
        item.itemProducts = getProductItems()
        Service.createAgreement(item).then(() => {
          setDisplay(null);
          ToastService.success();
          reloadLazyData();
        })
        event.preventDefault()
      } else {
        item.itemProductDelIds = itemProductDelIds
        item.contactParties = getContactParties();
        item.itemProducts = getProductItems();
        Service.updateRequest(requestId, item).then(() => {
          setDisplay(null);
          ToastService.success();
          reloadLazyData();
        })
        event.preventDefault();

      }
    }
  }

  const footer = (
    <div className="flex justify-content-center">
      {!disabled && <Button label="Lưu" icon="pi pi-check" size="small" onClick={onSubmit}/>}
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(null)}/>
    </div>
  );

  const render = () => {
    return (
      <Fragment>
        <Dialog header="Yêu cầu" footer={footer} visible={!!display} maximized={true} draggable={false} onHide={() => setDisplay(null)}>
          <div className="grid pt-2">
            <div className="col-12">
              {form.render()}
            </div>
            <div className="col-12">
            </div>
          </div>
        </Dialog>
      </Fragment>
    );
  }

  return {renderCrupRequest: render, doCreateRequest: doCreate, doUpdateRequest: doUpdate};
}