import {useEffect, useState} from 'react';

import {DashboardService} from './dashboard/DashboardService';

import MenuComponent from './components/MenuComponent';
import {IamPermission} from '../shared/utils/Permission';

const AppMenu = (props) => {

  const [requestCreatedCount, setRequestCreatedCount] = useState(0);
  const [requestSubmittedCount, setRequestSubmittedCount] = useState(0);
  const [requestRejectedCount, setRequestRejectedCount] = useState(0);

  const [profileIncompleteCount, setProfileIncompleteCount] = useState(0);
  const [profileCompletedCount, setProfileCompletedCount] = useState(0);

  // const [issueIncompleteCount, setIssueIncompleteCount] = useState(0);

  useEffect(() => {
    if (['Administrator', 'Agent'].includes(props.me.roleTypeId)) {
      DashboardService.getRequestsCreated().then(({number}) => {
        setRequestCreatedCount(number);
      });
      DashboardService.getRequestsInProgress().then(({number}) => {
        setRequestSubmittedCount(number);
      });
      DashboardService.getRequestsRejected().then(({number}) => {
        setRequestRejectedCount(number);
      });
    }

    if (['Administrator', 'Agent', 'Employee'].includes(props.me.roleTypeId)) {
        DashboardService.getProfileCompleted().then(({number})=>{
          setProfileCompletedCount(number)
        });
        DashboardService.getProfileIncomplete().then(({number})=>{
          setProfileIncompleteCount(number)
        });
    }

    // if (['Administrator', 'Agent'].includes(props.me.roleTypeId)) {
      // let _event = {
      //   filters: {status: {value: ['1', '2'], matchMode: 'in'}}
      // };
      // IssuesService.getList(JSON.stringify(_event)).then(({totalRecords}) => {
      //   setIssueIncompleteCount(totalRecords);
      // });
    // }
  }, [props.me.roleTypeId]);

  const menus = [
    {
      icon: 'pi pi-fw pi-home',
      items: [
        {label: 'Tổng quan', icon: 'pi pi-fw pi-home', to: '/dashboard'}
      ]
    },
    {
      label: 'Chứng thư số',
      icon: 'pi pi-fw pi-unlock',
      items: [
        {label: 'Tạo mới yêu cầu', icon: 'pi pi-fw pi-plus-circle', to: '/chung-thu-so/yeu-cau/tao-moi', visible: ['Administrator', 'Agent'].includes(props.me.roleTypeId) ||(IamPermission.has('VIEW', 'CreateCertRequest')&&IamPermission.has('CREATE', 'CreateCertRequest'))},
        {label: 'Yêu cầu mới', icon: 'pi pi-fw pi-check-square', to: '/chung-thu-so/yeu-cau/trang-thai/initial', badge: `${requestCreatedCount}`, visible: ['Administrator', 'Agent'].includes(props.me.roleTypeId) || IamPermission.has('VIEW', 'NewCertRequest')},
        {label: 'Yêu cầu chờ duyệt', icon: 'pi pi-fw pi-file-edit', to: '/chung-thu-so/yeu-cau/trang-thai/in-progressing', badge: `${requestSubmittedCount}`, visible: ['Administrator', 'Agent'].includes(props.me.roleTypeId) || IamPermission.has('VIEW', 'WaitApprCertRequest')},
        {label: 'Yêu cầu bị từ chối', icon: 'pi pi-fw pi-times', to: '/chung-thu-so/yeu-cau/trang-thai/rejecting', badge: `${requestRejectedCount}`, visible: ['Administrator', 'Agent'].includes(props.me.roleTypeId) || IamPermission.has('VIEW', 'RejectedCertRequest')},
        {label: 'CTS chờ triển khai', icon: 'pi pi-fw pi-clock', to: '/chung-thu-so/yeu-cau/trang-thai/waiting-deployment', visible: ['Administrator', 'Agent'].includes(props.me.roleTypeId) || IamPermission.has('VIEW', 'InProgressCertRequest')},
        {label: 'Tất cả yêu cầu', icon: 'pi pi-fw pi-id-card', to: '/chung-thu-so/yeu-cau', visible: ['Administrator', 'Agent'].includes(props.me.roleTypeId) || IamPermission.has('VIEW', 'CertRequests')},
      ],
      // visible: ['Administrator', 'Agent', 'AgentEmployee'].includes(props.me.roleTypeId)
    },
    {
      label: 'Quản lý Token',
      items: [
        {label: 'Danh sách Token', icon: 'pi pi-fw pi-id-card',to:'/quan-ly-token/danh-sach-token'},
      ],
      visible: (['Administrator'].includes(props.me.roleTypeId)||(['Agent'].includes(props.me.roleTypeId) && [1].includes(props.me.partyRank)))
    },
    {
      label: 'Hóa đơn điện tử',
      items: [
        {label: 'CTS cần xuất HĐ', icon: 'pi pi-fw pi-id-card',to:'/hoa-don-dien-tu/cts-can-xuat-hd',visible: (['Administrator'].includes(props.me.roleTypeId)||(['Agent'].includes(props.me.roleTypeId) && [1].includes(props.me.partyRank)))},
        {label: 'CTS đã xuất HĐ', icon: 'pi pi-fw pi-id-card',to:'/hoa-don-dien-tu/cts-da-xuat-hd',visible: (['Administrator'].includes(props.me.roleTypeId)||(['Agent'].includes(props.me.roleTypeId) && [1].includes(props.me.partyRank)))},
        {label: 'Tất cả yêu cầu', icon: 'pi pi-fw pi-id-card',to:'/hoa-don-dien-tu/yeu-cau'},
        {label: 'Tài khoản khách hàng', icon: 'pi pi-fw pi-credit-card', to: '/hoa-don-dien-tu/tai-khoan',visible: ['Administrator'].includes(props.me.roleTypeId)}
      ]
    },
    {
      label: 'Quản lý Hồ sơ CTS',
      icon: 'pi pi-fw pi-key',
      items: [
        {label: 'Chưa đủ hồ sơ', icon: 'pi pi-fw pi-stopwatch', to: '/chung-thu-so/danh-sach/trang-thai/PpcDocumentIncomplete', badge: `${profileIncompleteCount}`, visible: ['Administrator', 'Agent', 'Employee'].includes(props.me.roleTypeId) || IamPermission.has('VIEW', 'CertRequestProfileIncomplete')},
        {label: 'Đã nộp đủ hồ sơ', icon: 'pi pi-fw pi-check-square', to: '/chung-thu-so/danh-sach/trang-thai/PpcDocumentCompleted', badge: `${profileCompletedCount}`, visible: ['Administrator', 'Agent', 'Employee'].includes(props.me.roleTypeId) || IamPermission.has('VIEW', 'CertRequestProfileComplete')},
        {label: 'Danh sách CTS', icon: 'pi pi-fw pi-list', to: '/chung-thu-so/danh-sach',  visible: ['Administrator', 'Agent', 'Employee'].includes(props.me.roleTypeId) || IamPermission.has('VIEW', 'CertRequestProfile')},
        {label: 'Cảnh báo', icon: 'pi pi-fw pi-info-circle', to: '/canh-bao/tin-nhan',visible: ['Administrator'].includes(props.me.roleTypeId)}
      ],
      // visible: ['Administrator', 'Agent', 'Employee', 'AgentEmployee'].includes(props.me.roleTypeId)
    },
    // {
    //   label: 'Chứng từ điện tử',
    //   icon: 'pi pi-fw pi-credit-card',
    //   items: [
    //     {label: 'Quản lý hợp đồng', icon: 'pi pi-fw pi-id-card', to: '/chung-tu/hop-dong'}
    //   ],
    //   visible: ['Administrator'].includes(props.me.roleTypeId)
    // },
    // {
    //   label: 'Quản lý Issue',
    //   icon: 'pi pi-fw pi-info-circle',
    //   items: [
    //     {label: 'Tạo mới Issue', icon: 'pi pi-fw pi-plus-circle', to: '/issue/tao-moi'},
    //     {label: 'Chưa hoàn thành', icon: 'pi pi-fw pi-stopwatch', to: '/issue/trang-thai/1,2', badge: `${issueIncompleteCount}`},
    //     {label: 'Danh sách Issue', icon: 'pi pi-fw pi-list', to: '/issue/danh-sach'}
    //   ],
    //   visible: ['Administrator'].includes(props.me.roleTypeId)
    // },
    // {
    //   label: 'Quản lý khuyến mãi',
    //   icon: 'pi pi-fw pi-tags',
    //   items: [
    //     {label: 'Tạo mới khuyến mãi', icon: 'pi pi-fw pi-plus-circle', to: '/khuyen-mai/tao-moi'},
    //     {label: 'Danh sách khuyến mãi', icon: 'pi pi-fw pi-list', to: '/khuyen-mai/danh-sach'}
    //   ],
    //   visible: ['Administrator'].includes(props.me.roleTypeId)
    // },
    {
      label: 'Quản lý Khách hàng',
      icon: 'pi pi-fw pi-users',
      items: [
        {label: 'Danh sách khách hàng', icon: 'pi pi-fw pi-users', to: '/khach-hang/danh-sach',visible: ['Administrator', 'Agent', 'Employee'].includes(props.me.roleTypeId) || IamPermission.has('VIEW', 'CustomerMgr')},
      ],
      // visible: ['Administrator', 'Agent', 'Employee'].includes(props.me.roleTypeId)
    },
    {
      label: 'Quản lý Đại lý',
      icon: 'pi pi-fw pi-sitemap',
      items: [
        {label: 'Tạo mới đại lý', icon: 'pi pi-fw pi-plus-circle', to: '/dai-ly/tao-moi',visible: ['Administrator', 'Agent'].includes(props.me.roleTypeId) || (IamPermission.has('VIEW', 'CreateAgentMgr')&& IamPermission.has('CREATE', 'CreateAgentMgr'))},
        {label: 'Danh sách đại lý', icon: 'pi pi-fw pi-sitemap', to: '/dai-ly', visible: ['Administrator', 'Agent'].includes(props.me.roleTypeId) || IamPermission.has('VIEW', 'AgentMgr')},
        {label: 'Chính sách giá', icon: 'pi pi-fw pi-dollar', to: '/dai-ly/chinh-sach-gia', visible: ['Administrator'].includes(props.me.roleTypeId)},
        {label: 'Quản lý Tài khoản', icon: 'pi pi-fw pi-list', to: '/dai-ly/quan-ly-tai-khoan',visible:  ['Administrator', 'Agent'].includes(props.me.roleTypeId) ||  IamPermission.has('VIEW', 'UserAccountAgentMgr')}
      ],
      // visible: ['Administrator', 'Agent'].includes(props.me.roleTypeId)
    },
    {
      label:'Quản lý nhân viên',
      icon: 'pi pi-fw pi-users',
      items: [
        {label: 'Danh sách nhân viên', icon: 'pi pi-fw pi-users', to: '/nhan-vien/danh-sach'}
      ],
      visible: ['Administrator', 'Agent'].includes(props.me.roleTypeId)
    },
    {
      label: 'Thanh toán',
      icon: 'pi pi-fw pi-dollar',
      items: [
        {label: 'Danh sách thanh toán', icon: 'pi pi-fw pi-dollar', to: '/thanh-toan/danh-sach'},
        {label: 'Lịch sử công nợ đại lý', icon: 'pi pi-fw pi-sitemap', to: '/thanh-toan/cong-no-qua-han'},
        // {label: 'Lịch sử công nợ với ĐL cấp trên', icon: 'pi pi-fw pi-sitemap', to: '/thanh-toan/cong-no-cap-tren-qua-han'}
      ],
      visible: ['Administrator', 'Agent'].includes(props.me.roleTypeId)
    },
    {
      label: 'Danh mục sản phẩm',
      icon: 'pi pi-fw pi-file',
      items: [
        {label: 'Gói chứng thư số', icon: 'pi pi-fw pi-file', to: '/danh-muc/goi-chung-thu-so'},
        {label: 'Nhóm sản phẩm', icon: 'pi pi-fw pi-box', to: '/danh-muc/nhom-san-pham', visible: ['Administrator'].includes(props.me.roleTypeId)}
      ],
      visible: ['Administrator'].includes(props.me.roleTypeId)
    },
    {
      label: 'Hệ thống',
      icon: 'pi pi-fw pi-server',
      items: [
        {label: 'Log truy cập', icon: 'pi pi-fw pi-sliders-h', to: '/he-thong/log-truy-cap'},
        {label: 'Log dữ liệu', icon: 'pi pi-fw pi-sort-alt', to: '/he-thong/log-du-lieu'}
      ],
      visible: ['Administrator'].includes(props.me.roleTypeId)
    }
  ];

  return (
    <MenuComponent
      items={menus}
      className="layout-menu"
      menuActive={props.active}
      onRootMenuItemClick={props.onRootMenuItemClick}
      mobileMenuActive={props.mobileMenuActive}
      onMenuItemClick={props.onMenuItemClick}
      root
      layoutMode={props.layoutMode}
      parentMenuItemActive
    />
  );
};

export default AppMenu;
