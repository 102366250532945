import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {useDataTable, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {IssueTypeService as Service} from './IssueTypeService';

export const IssueTypes = ({display, setDisplay}) => {

  const header = 'Loại Issue';
  const dataKey = '_id';

  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    dataKey,
    columns: [
      {field: 'name', header, minWidth: 250, matchMode: 'contains'}
    ],
    indexColumnWidth: 45,
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    }
  });

  const {render: renderDialogCrup, create, update} = useDialogCrup({
    header,
    dataKey,
    width: '40rem',
    fields: [
      {field: 'name', header, required: true, className: 'md:col-12'}
    ],
    createItem: item => {
      return Service.create(item);
    },
    updateItem: (id, item) => {
      return Service.update(id, item);
    },
    reloadLazyData
  });

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const doUpdate = () => {
    if (selectedItem && selectedItem[dataKey]) {
      update(selectedItem);
    }
  }

  const {renderToolbar} = useToolbar({
    doCreate: create,
    hasSelectedItem: selectedItem,
    doUpdate
  });

  const footer = (
    <div>
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(false)}/>
    </div>
  );

  return (
    <Dialog header={header} footer={footer} visible={display} style={{width: '70rem'}} position="top" onHide={() => setDisplay(false)}>
      <div className="grid">
        <div className="col-12">
          {renderToolbar()}
        </div>
        <div className="col-12 py-0" style={{maxHeight: 'calc(100vh - 29rem)'}}>
          {renderDataTable()}
        </div>

        {renderDialogCrup()}
      </div>
    </Dialog>
  );
}