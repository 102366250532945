import {Fragment} from 'react';

import {Button} from 'primereact/button';
import {ConfirmDialogService, ToastService, useDataTable, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {InvoiceService as Service} from './InvoiceService';

export const DanhSach = () => {

  const dataKey = 'invoiceId';

  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    dataKey,
    tableHeader: 'Thanh toán',
    columns: [
      {field: dataKey, header: 'Mã', minWidth: 120, matchMode: 'contains'},
      {field: 'placedDate', header: 'Ngày yêu cầu', width: 130, dataType: 'date'},
      {field: 'invoiceDate', header: 'Ngày phát sinh', width: 135, dataType: 'date'},
      {
        field: 'status', header: 'Trạng thái', width: 140, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          if (!rowData.status) {
            return null;
          }
          let _className;
          switch (rowData.statusId) {
            case 'InvoiceInProcess':
              _className = 'alert alert-dark';
              break;
            case 'InvoiceFinalized':
              _className = 'alert alert-success';
              break;
            case 'InvoiceCancelled':
              _className = 'alert alert-danger';
              break;
            default:
              _className = 'alert alert-light ' + rowData.statusId;
          }
          return <div className={_className}>{rowData.status}</div>
        }
      },
      {field: 'fromPartyName', header: 'Đại lý cấp 1', minWidth: 150, matchMode: 'contains'},
      {field: 'agentPartyName', header: 'Đại lý tạo', minWidth: 150, matchMode: 'contains'},
      {field: 'toPartyName', header: 'Khách hàng', minWidth: 180, matchMode: 'contains'},
      {field: 'itemDescription', header: 'Dịch vụ', width: 150, matchMode: 'contains'},
      {field: 'vendorPseudoId', header: 'NCC CTS', width: 130, matchMode: 'contains'},
      {field: 'amount', header: 'Tổng tiền', width: 130, dataType: 'number'},
      {field: 'discountAmount', header: 'Chiết khấu', width: 130, dataType: 'number'},
      {field: 'discountPercent', header: 'Tỷ lệ chiết khấu', width: 130},
      {field: 'invoiceTotal', header: 'Tổng thanh toán', width: 135, dataType: 'number'},
      {field: 'dueDate', header: 'Hạn TT', width: 110, dataType: 'date'},
      {field: 'paidDate', header: 'Ngày TT', width: 110, dataType: 'date'},
      {field: 'description', header: 'Ghi chú', minWidth: 150, matchMode: 'contains'}
    ],
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    }
  });

  const {render: renderDialogUpdate, update} = useDialogCrup({
    header: '!Cập nhật thông tin công nợ',
    dataKey,
    width: '40rem',
    fields: [
      {field: 'lastUpdatedStamp', type: 'hidden'},
      {field: 'amount', header: 'Số tiền', type: 'InputNumber', className: 'md:col-12'},
      {field: 'dueDate', header: 'Hạn thanh toán', type: 'Calendar', className: 'md:col-12'},
      {field: 'description', header: 'Ghi chú', className: 'md:col-12'}
    ],
    updateItem: (id, item) => {
      return Service.update(id, item);
    },
    reloadLazyData
  });

  const {render: renderDialogPaid, update: paid} = useDialogCrup({
    header: '!Thanh toán hóa đơn',
    dataKey,
    width: '40rem',
    fields: [
      {field: 'lastUpdatedStamp', type: 'hidden'},
      {field: 'paidDate', header: 'Hạn thanh toán', type: 'Calendar', className: 'md:col-12'}
    ],
    updateItem: (id, item) => {
      return Service.paid(id, item);
    },
    reloadLazyData
  });

  const {render: renderDialogCancel, update: cancel} = useDialogCrup({
    header: '!Hủy thanh toán',
    dataKey,
    width: '40rem',
    fields: [
      {field: 'lastUpdatedStamp', type: 'hidden'},
      {field: 'description', header: 'Lý do hủy', required: true, className: 'md:col-12'}
    ],
    updateItem: (id, item) => {
      return Service.cancel(id, item);
    },
    reloadLazyData
  });

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const doUpdate = () => {
    if (selectedItem && selectedItem[dataKey]) {
      const data = Object.assign({}, selectedItem);
      if (data.dueDate) {
        data.dueDate = new Date(data.dueDate);
      }
      update(data);
    }
  }

  const doPaid = () => {
    if (selectedItem && selectedItem[dataKey]) {
      const data = Object.assign({}, selectedItem);
      data.paidDate = new Date();
      paid(data);
    }
  }

  const doCancel = () => {
    if (selectedItem && selectedItem[dataKey]) {
      const data = Object.assign({}, selectedItem);
      delete data.description;
      cancel(data);
    }
  }
  const doCalculate = () => {
    if (selectedItem && selectedItem[dataKey]) {
      ConfirmDialogService.confirm('Tính lại chiết khấu', 'Tính lại chiết khấu?', () => {
          Service.calculate(selectedItem[dataKey]).then(() => {
            ToastService.success();
            reloadLazyData();
          })
      })

    }
  }

  const downloadExcel=()=>{
    window.open(`${Service.baseURL}/${Service.entity}/download`);
  }

  const {renderToolbar} = useToolbar({
    hasSelectedItem: selectedItem,
    leftButtons: <Fragment>
      <Button label="Cập nhật công nợ" icon="pi pi-pencil" severity="warning" size="small" onClick={doUpdate} disabled={!selectedItem}/>
      <Button label="Thanh toán" icon="pi pi-check" size="small" onClick={doPaid} disabled={!['InvoiceInProcess', 'InvoiceCancelled'].includes(selectedItem?.statusId)}/>
      <Button label="Hủy thanh toán" icon="pi pi-times" severity="danger" size="small" onClick={doCancel} disabled={!selectedItem}/>
      <Button label="Tính lại chiết khấu" icon="pi pi-calculator" severity="info" size="small" onClick={doCalculate} disabled={!selectedItem}/>
      <Button label="Xuất excel" icon="pi pi-file-excel" severity="help" size="small" onClick={downloadExcel}/>
    </Fragment>
  });

  return (
    <div className="grid">
      <div className="col-12">
        {renderToolbar()}
      </div>
      <div className="col-12 py-0" style={{maxHeight: 'calc(100vh - 15.5rem)'}}>
        {renderDataTable()}
      </div>

      {renderDialogUpdate()}
      {renderDialogPaid()}
      {renderDialogCancel()}
    </div>
  );
}