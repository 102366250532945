import {Fragment, useEffect, useState} from 'react';
import {Button} from 'primereact/button';
import {SelectButton} from 'primereact/selectbutton';
import {ConfirmDialogService, FormatDisplay, ToastService, useDataTable, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {EmployeeService as Service} from '../EmployeeService';
import {Permissions} from './Permissions';

const statuses = [
  {value: 'N', label: 'Đang hoạt động'},
  {value: 'Y', label: 'Đã khóa'}
];

const dataKey = 'partyId';
export const DanhSach = () => {

  const [displayPermissions, setDisplayPermissions] = useState(null);

  const [statusId, setStatusId] = useState(null);

  useEffect(() => {
    if (statusId) {
      reloadLazyData();
    } else {
      setStatusId(statuses[0].value);
    }
  }, [statusId]); // eslint-disable-line react-hooks/exhaustive-deps

  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    dataKey,
    columns: [
      {field: 'pseudoId', header: 'Mã', minWidth: 120, matchMode: 'contains'},
      {field: 'partyName', header: 'Tên nhân viên', minWidth: 180, matchMode: 'contains'},
      {field: 'birthDate', header: 'Ngày sinh', dataType: 'date', minWidth: 145, matchMode: 'contains'},
      {field: 'username', header: 'Tài khoản đăng nhập', minWidth: 150, matchMode: 'contains'},
      {field: 'emailAddress', header: 'Email', minWidth: 170, matchMode: 'contains'},
      {field: 'phoneNumber', header: 'Số điện thoại', minWidth: 150, matchMode: 'contains'},
    ],
    getList: lazyLoadEvent => {
      if (statusId) {
        return Service.getList(lazyLoadEvent, {disabled: statusId});
      } else {
        return new Promise(resolve => resolve({}));
      }
    }
  });

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const {render: renderDialogCrup, create, view, update} = useDialogCrup({
    header: 'Nhân viên',
    dataKey,
    width: '80rem',
    fields: [
      {field: 'pseudoId', header: 'Mã nhân viên', required: true, className: 'md:col-4'},
      {field: 'partyName', header: 'Tên nhân viên', required: true, className: 'md:col-8'},
      {field: 'username', header: 'Tài khoản', required: true, className: 'md:col-12'},
      {field: 'password', header: 'Mật khẩu', required: true, display: 'Create', type: 'Password', className: 'md:col-12'},
      {field: 'birthDate', header: 'Ngày sinh', InputTextProps: {type: 'date'}, className: 'md:col-4'},
      {field: 'emailAddress', header: 'Email', InputTextProps: {maxLength: 50}, className: 'md:col-4'},
      {field: 'phoneNumber', header: 'Số điện thoại', InputTextProps: {maxLength: 17, keyfilter: 'int'}, className: 'md:col-4'}
    ],
    createItem: item => {
      return Service.create(item);
    },
    updateItem : (id, item) => {
      return Service.update(id, item);
    },
    reloadLazyData
  });

  const {render: renderDialogResetPassword, update: resetPassword} = useDialogCrup({
    header: '!Đặt lại mật khẩu',
    dataKey,
    width: '40rem',
    fields: [
      {field: 'resetPassword', header: 'Mật khẩu mới', required: true, type: 'Password', className: 'md:col-12'}
    ],
    updateItem: (id, item) => {
      return Service.resetPassword(id, item.resetPassword);
    }
  });

  const doCreate = () => {
    create();
  }
  const doUpdate = (doView?: boolean) => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.get(selectedItem[dataKey]).then(data => {
        data.birthDate = FormatDisplay.date(data.birthDate, 'YYYY-MM-DD');
        if (doView === true) {
          view(data);
        } else {
          update(data);
        }
      });
    }
  }

  const doDelete = () => {
    if (selectedItem && selectedItem[dataKey]) {
      ConfirmDialogService.confirm('Xóa nhân viên', 'Xác nhận xóa nhân viên này?', () => {
        Service.delete(selectedItem[dataKey]).then(() => {
          ToastService.success();
          reloadLazyData();
        });
      });
    }
  }
  const doDisable = () => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.disable(selectedItem[dataKey]).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    }
  }
  const doEnable = () => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.enable(selectedItem[dataKey]).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    }
  }

  const {renderToolbar} = useToolbar({
    doCreate,
    hasSelectedItem: selectedItem,
    doView: () => doUpdate(true),
    doUpdate,
    leftButtons: <Fragment>
      <Button label="Xóa" icon="pi pi-trash" severity="danger" size="small" onClick={doDelete} disabled={!selectedItem}/>
      <Button label="Phân quyền" icon="pi pi-key" severity="help" size="small" onClick={() => setDisplayPermissions(selectedItem)} disabled={!selectedItem}/>
      <Button label="Đặt lại mật khẩu" icon="pi pi-history" severity="warning" size="small" onClick={() => resetPassword(selectedItem)} disabled={!selectedItem}/>
      {statusId === statuses[0].value && <Button label="Khoá" icon="pi pi-lock" severity="danger" size="small" onClick={doDisable} disabled={!selectedItem}/>}
      {statusId === statuses[1].value && <Button label="Mở khoá" icon="pi pi-lock" severity="success" size="small" onClick={doEnable} disabled={!selectedItem}/>}
    </Fragment>
  });

  return (
    <div className="grid">
      <div className="col-12">
        <SelectButton value={statusId} options={statuses} onChange={e => setStatusId(e.value)}/>
      </div>
      <div className="col-12">
        {renderToolbar()}
      </div>
      <div className="col-12 py-0" style={{maxHeight: 'calc(100vh - 15.5rem)'}}>
        {renderDataTable()}
      </div>
      {renderDialogCrup()}
      {/*{renderDialogCrup2()}*/}
      {renderDialogResetPassword()}
      <Permissions display={displayPermissions} setDisplay={setDisplayPermissions}/>
    </div>
  );
}
