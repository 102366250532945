import {useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {ToastService, useForm} from '@iamsoftware/react-hooks';

import {InvoiceService as Service} from "../InvoiceService";
import {useItemProducts} from "./ItemProducts";

export const useCrupExtend = () => {

  const [display, setDisplay] = useState(null);
  const [partyTaxId, setPartyTaxId] = useState(null)


  const {renderProductItems, getProductItems} = useItemProducts({readOnly: true},{update:true}, {create:true});


  const form = useForm({
    fields: [
      {field: 'customerPartyId', header: 'Khách hàng', required: true, type: 'Dropdown', DropdownProps: {options: partyTaxId, filter: true}, className: 'md:col-12'},
      {field: 'description', header: 'Ghi chú', required: true, className: 'md:col-12'},
      {field: 'isCreateAgreement', header: 'Tạo hợp đồng', type: "Checkbox", className: 'text-center'},
      {type: 'custom', body: renderProductItems()}
    ],
  });

  const doCreate = () => {
    Service.customerList().then(data => {
      setPartyTaxId(data.listData.map(item => {
        return {value: item.partyId, label: `[${item.pseudoId}] - ${item.partyName}`}
      }));
    });
    setDisplay(Date.now());
  }
  const onSubmit = () => {
    if (form.valid()) {
      const item = form.getValue();
      item.itemProducts = getProductItems();
      if (item.isCreateAgreement === true) {
        item.isCreateAgreement = 'Y'
      } else if (item.isCreateAgreement === false) {
        item.isCreateAgreement = 'N'
      }
      Service.renew(item).then(() => {
        setDisplay(null);
        ToastService.success()
      })
    }
  }

  const footer = (
    <div className="flex justify-content-center">
      <Button label="Tạo mới" icon="pi pi-check" size="small" onClick={onSubmit}/>
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(null)}/>
    </div>
  );

  const renderExtend = () => {
    return (
      <Dialog header="Gia hạn" footer={footer} visible={!!display} style={{width: '80rem '}} position="top" onHide={() => setDisplay(null)}>
        {form.render()}
      </Dialog>
    );
  }
  return {renderExtend, CreateExtend: doCreate};
}