import {Button} from 'primereact/button';
import {useDataTable, useForm} from '@iamsoftware/react-hooks';

import {InvoiceService as Service} from '../danh-sach/InvoiceService';

export const CongNoQuaHan = ({type}) => {

  const dataKey = 'fromPartyId';

  const {render: renderDataTable, reloadLazyData} = useDataTable({
    dataKey,
    columns: [
      {field: 'fromPseudoId', header: 'Mã đại lý cấp 1', width: 150, matchMode: 'contains'},
      {field: 'fromPartyName', header: 'Tên đại lý cấp 1', minWidth: 150, matchMode: 'contains'},
      {field: 'agentPseudoId', header: 'Mã đại lý', width: 150, matchMode: 'contains'},
      {field: 'agentPartyName', header: 'Tên đại lý', minWidth: 150, matchMode: 'contains'},
      {field: 'debtTotal', header: 'Số tiền', width: 200, dataType: 'number'}
    ],
    getList: lazyLoadEvent => {
      return Service.getOutstandingDebt(lazyLoadEvent, getValue());
    }
  });

  const {render: renderForm, getValue} = useForm({
    fields: [
      {field: 'lookBackDays', header: 'Số ngày quá hạn', type: 'InputNumber', className: 'md:col-4', labelClassName: 'md:col-4', inputClassName: 'md:col-8'},
      {
        type: 'custom', body: <Button icon="pi pi-search" rounded severity="success" size="small" onClick={reloadLazyData}/>, className: 'md:col-1'
      }
    ],
    layout: 'Horizontal'
  });

  return (
    <div className="grid">
      <div className="col-12">
        {renderForm()}
      </div>
      <div className="col-12 py-0" style={{maxHeight: 'calc(100vh - 15.5rem)'}}>
        {renderDataTable()}
      </div>
    </div>
  );
}