import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {SelectButton} from 'primereact/selectbutton';
import {ToastService, useDataTable, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {ProductService as Service} from './ProductService';

const statuses = [
  {value: 'listEnable', label: 'Đang hoạt động'},
  {value: 'listDisable', label: 'Ngừng hoạt động'}
];

export const GoiChungThuSo = () => {

  const header = 'Chứng thư số';
  const dataKey = 'productId';

  const [statusId, setStatusId] = useState(null);

  const [vendors, setVendors] = useState([])
  const [categories, setCategories] = useState([])

  useEffect(() => {
    setStatusId(statuses[0].value);
  }, []);

  useEffect(() => {
    if (statusId) {
      reloadLazyData();
    }
  }, [statusId]); // eslint-disable-line react-hooks/exhaustive-deps


  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    dataKey,
    columns: [
      {field: 'pseudoId', header: 'Mã SP', width: 170, matchMode: 'contains'},
      {field: 'productName', header: 'Tên SP', minWidth: 150, matchMode: 'contains'},
      {field: 'ownerPartyId', header: 'NCC', width: 150, matchMode: 'contains'},
      {
        field: 'amountFixed', header: 'Thời hạn', width: 100, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          let amountUomName = "";
          for (let amountUom of amountUoms) {
            if (amountUom.value === rowData.amountUomId) {
              amountUomName = amountUom.label;
            }
          }
          return `${rowData.amountFixed} ${amountUomName}`;
        }
      },
      {field: 'price', header: 'Giá', width: 120, matchMode: 'contains', dataType: 'number'},
      {field: 'description', header: 'Mô tả', minWidth: 250, matchMode: 'contains'},
      {field: 'categoryName', header: 'Danh mục', minWidth: 200, matchMode: 'contains'}
    ],
    getList: lazyLoadEvent => {
      if (statusId) {
        return Service.getList(lazyLoadEvent, statusId);
      } else {
        return Promise.resolve({});
      }
    }
  })

  useEffect(() => {
    Service.getVendors().then(data => {
      setVendors(data.listData.map(item => {
        return {value: item.fromPartyId, label: item.pseudoId}
      }));
    });
  }, [])

  const {render: renderDialogCrup, create, update, view, form} = useDialogCrup({
    header,
    dataKey,
    width: '60rem',
    fields: [
      {field: 'pseudoId', header: 'Mã SP', required: true, className: 'md:col-4'},
      {field: 'productName', header: 'Tên SP', required: true, className: 'md:col-8'},
      {field: 'ownerPartyId', header: 'NCC', required: true, type: 'Dropdown', DropdownProps: {options: vendors}, className: 'md:col-6',},
      {field: 'productCategoryId', header: 'Danh mục', required: true, type: 'Dropdown', DropdownProps: {options: categories}, className: 'md:col-6',},
      {field: 'amountFixed', header: 'Thời hạn sử dụng', required: true, type: 'InputNumber', className: 'md:col-4'},
      {field: 'amountUomId', header: 'Đơn vị', required: true, type: 'Dropdown', DropdownProps: {options: amountUoms}, className: 'md:col-4'},
      {field: 'price', header: 'Giá', required: true, type: 'InputNumber', InputNumberProps: {suffix: ' VNĐ'}, className: 'md:col-4'},
      {field: 'description', header: 'Mô tả', type: 'InputTextarea', className: 'md:col-12'}
    ],
    createItem: item => {
      return Service.create(item);
    },
    updateItem: (id, item) => {
      return Service.update(id, item);
    },

    reloadLazyData
  });

  const {ownerPartyId} = form.getValue();

  useEffect(() => {
    if (ownerPartyId) {
      Service.getCategory(ownerPartyId).then(data => {
        setCategories(data.listData.map(item => {
          return {value: item.productCategoryId, label: item.categoryName};
        }));
      });
    }
  }, [ownerPartyId]);


  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const doUpdate = (doView?: boolean) => {
    if (selectedItem && selectedItem[dataKey]) {
      if (selectedItem.ownerPartyId) {
        Service.get(selectedItem[dataKey]).then(data => {
          if (doView === true) {
            view(data);
          } else {
            update(data);
          }
        });
      }

    }
  }

  const doDisable = () => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.disable(selectedItem[dataKey]).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    }
  }
  const doEnable = () => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.enable(selectedItem[dataKey]).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    }
  }

  const downloadExcel=()=>{
    window.open(`${Service.baseURL}/${Service.entity}/download`);
  }

  const {renderToolbar} = useToolbar({
    doCreate: create,
    hasSelectedItem: selectedItem,
    doView: () => doUpdate(true),
    doUpdate,
    leftButtons: <Fragment>
      {statusId === statuses[0].value && <Button label="Khoá" icon="pi pi-lock" severity="danger" size="small" onClick={doDisable} disabled={!selectedItem}/>}
      {statusId === statuses[1].value && <Button label="Mở khoá" icon="pi pi-lock" severity="success" size="small" onClick={doEnable} disabled={!selectedItem}/>}
      <Button label="Xuất excel" icon="pi pi-file-excel" severity="help" size="small" onClick={downloadExcel}/>
    </Fragment>
  });

  return (
    <div className="grid">
      <div className="col-12 pb-0">
        <SelectButton value={statusId} options={statuses} onChange={e => setStatusId(e.value)}/>
      </div>
      <div className="col-12">
        {renderToolbar()}
      </div>
      <div className="col-12 py-0" style={{maxHeight: 'calc(100vh - 20rem)'}}>
        {renderDataTable()}
      </div>

      {renderDialogCrup()}
    </div>
  );
}
const amountUoms = [
  {value: "TF_yr", label: "Năm"},
  {value: "TF_mon", label: "Tháng"}
]