import React, {Fragment} from 'react';

import {Button} from 'primereact/button';
import {
  ToastService,
  useDataTable,
  useToolbar
} from '@iamsoftware/react-hooks';
import {InvoiceService as Service} from '../InvoiceService';
import {useCrupRequest} from "./Crup";
import {useCrupExtend} from "./CrupExtend";
import {IamPermission} from '../../../shared/utils/Permission';

interface Props {
  me: any
  beCreate?: boolean
}

const dataKey = 'requestId';
export const YeuCau = (props: Props) => {
  const {me} = props;
  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    dataKey,
    columns: [
      {field: 'requestId', header: 'Mã', minWidth: 100, matchMode: 'contains'},
      {field: 'requestTypeEnum', header: 'Loại yêu cầu', minWidth: 100, matchMode: 'contains'},
      {field: 'customerName', header: 'Tên khách hàng', minWidth: 200, matchMode: 'contains'},
      {field: 'partyTaxId', header: 'Mã số thuế', minWidth: 150, matchMode: 'contains'},
      {field: 'customerContactName', header: 'Tên người liên hệ', minWidth: 170, matchMode: 'contains'},
      {field: 'requestDate', header: 'Ngày tạo', minWidth: 120, dataType: 'date'},
      {field: 'agentPseudoId', header: 'Mã đại lý', minWidth: 120, matchMode: 'contains'},
      {field: 'status', header: 'Trạng thái', minWidth: 120, matchMode: 'contains'},
      {field: 'totalQuantity', header: 'Số lượng đã mua', minWidth: 100, matchMode: 'contains'},
      {field: 'usedQuantity', header: 'Số lượng đã sử dụng', minWidth: 100, matchMode: 'contains'},
      {field: 'remainQuantity', header: 'Số lượng còn lại', minWidth: 100, matchMode: 'contains'},
      {field: 'totalPromoQuantity', header: 'Số lượng KM', minWidth: 100, matchMode: 'contains'},
      {field: 'usedPromoQuantity', header: 'Số lượng KM đã dùng', minWidth: 100, matchMode: 'contains'},
      {field: 'remainPromoQuantity', header: 'Số lượng KM còn lại', minWidth: 100, matchMode: 'contains'},
      // {field: '', header: 'Mô tả', minWidth: 150, matchMode: 'contains'},
    ],
    getList: lazyLoadEvent => {
      return Service.requestList(lazyLoadEvent);
    },

  });

  const {renderCrupRequest, doCreateRequest, doUpdateRequest} = useCrupRequest({reloadLazyData});
  const {renderExtend, CreateExtend} = useCrupExtend();


  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const doUpdate = (doView?: boolean) => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.requestDetail(selectedItem[dataKey]).then(data => {
        doUpdateRequest(selectedItem[dataKey], data.data, doView);
      });
    }

  }

  const doAccept = () => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.acceptRequest(selectedItem[dataKey]).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    }
  }
  const doReject = () => {
    if (selectedItem && selectedItem[dataKey]) {
      Service.rejectRequest(selectedItem[dataKey]).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    }
  }
  const doRenew = () => {
    CreateExtend();
    reloadLazyData();
  }

  const {renderToolbar} = useToolbar({
    doCreate: (IamPermission.has('CREATE') || ['Administrator', 'Agent'].includes(me.roleTypeId)) && doCreateRequest,
    hasSelectedItem: selectedItem,
    doView: () => doUpdate(true),
    // doUpdate,
    leftButtons: <Fragment>
      {(IamPermission.has('UPDATE') || ['Administrator', 'Agent'].includes(me.roleTypeId)) &&
				<Button label="Chỉnh sửa" icon="pi pi-pencil" severity="warning" size="small" onClick={() => doUpdate()}
								disabled={!selectedItem}/>}
      {(IamPermission.has('UPDATE') || ['Administrator', 'Agent'].includes(me.roleTypeId)) &&
				<Button label="Chấp nhận" icon="pi pi-check" severity="info" size="small" onClick={doAccept}
								disabled={!['ReqCreated'].includes(selectedItem?.statusId)}/>}
      {(IamPermission.has('UPDATE') || ['Administrator', 'Agent'].includes(me.roleTypeId)) &&
				<Button label="Từ chối" icon="pi pi-times" severity="warning" size="small" onClick={doReject}
								disabled={!['ReqCreated'].includes(selectedItem?.statusId)}/>}
      {(IamPermission.has('UPDATE') || ['Administrator', 'Agent'].includes(me.roleTypeId)) &&
				<Button label="Gia hạn" severity="info" size="small" onClick={doRenew}/>
      }
    </Fragment>
  });

  return (
    <div className="grid">
      <div className="col-12">
        {renderToolbar()}
      </div>
      <div className="col-12 py-0" style={{maxHeight: 'calc(100vh - 15.5rem)'}}>
        {renderDataTable()}
      </div>
      {renderCrupRequest()}
      {renderExtend()}
    </div>
  );
}
