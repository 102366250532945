import {useState} from 'react';
import {Administrator} from './Administrator';
import {Agent} from './Agent';



export const Dashboard = ({me}) => {


  return (
    <div className="grid layout-dashboard">
      <Administrator />
      {/*{me.roleTypeId === 'Administrator' && <Administrator period={period}/>}*/}
      {/*{me.roleTypeId !== 'Administrator' && <Agent/>}*/}
    </div>
  );
}