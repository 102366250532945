import {useDataTable} from '@iamsoftware/react-hooks';

import {EntityAuditLogService as Service} from './EntityAuditLogService';

export const LogDuLieu = () => {

  const {render: renderDataTable} = useDataTable({
    columns: [
      {field: 'screenDescription', header: 'Tính năng', width: 150, matchMode: 'contains'},
      {field: 'actionType', header: 'Thao tác', width: 100, matchMode:'contains'},
      {field: 'username', header: 'User thực hiện', width: 100, matchMode: 'contains'},
      {field: 'startDateTime', header: 'Ngày giờ', width: 100, dataType: 'date-time'},
      {field: 'parameterString', header: 'Dữ liệu', minWidth: 500, matchMode: 'contains'},
    ],
    indexColumnWidth: 45,
    getList: lazyLoadEvent => {
      return Service.getListData(lazyLoadEvent);
    }
  });

  return (
    <div className="grid">
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 9rem)'}}>
        {renderDataTable()}
      </div>
    </div>
  );
}