import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Checkbox} from 'primereact/checkbox';
import {Dialog} from 'primereact/dialog';
import {FormatDisplay, ToastService} from '@iamsoftware/react-hooks';
import {EmployeeService as Service} from '../EmployeeService';

export const Permissions = ({display, setDisplay}) => {

    const header = `Phân quyền #${display?.username}`;

    const {renderScreen, footer} = useScreen({partyId: display?.partyId, setDisplay});

    return (
        <Dialog header={header} footer={footer} visible={!!display} style={{width: '70rem'}} position="top" onHide={() => setDisplay(false)}>
            {renderScreen()}
        </Dialog>
    );
}

const useScreen = ({partyId, setDisplay}) => {

    const [submitting, setSubmitting] = useState(false);
    const [normalPerms, setnormalPerms] = useState([]);
    const [checkAll, setCheckAll] = useState({} as any);


    useEffect(() => {
            if (partyId) {
                Service.getPermissions(partyId).then(data => {
                    setnormalPerms(data.normalPerms);
                })
            }
    }, [partyId]);

    const onCheckAllChange = (permissionType, checked) => {
        setCheckAll(prevState => {
            prevState[permissionType] = checked;
            return {...prevState};
        });
        setnormalPerms(prevState => {
            return prevState.map(normalPerm => {
                normalPerm.permissions.forEach(permission => {
                    if (permission.permissionType === permissionType) {
                        permission.granted = checked;
                    }
                });
                return normalPerm;
            });
        });
    }

    const setChecked = (userGroupId, granted) => {
        setnormalPerms(prevState => {
            return prevState.map(normalPerm => {
                normalPerm.permissions.forEach(permission => {
                    if (permission.userGroupId === userGroupId) {
                        permission.granted = granted;
                        if (!granted) {
                            setCheckAll(prevState => {
                                prevState[permission.permissionType] = granted;
                                return {...prevState};
                            });
                        }
                    }
                });
                return normalPerm;
            });
        });
    }

    const Setter = ({normalPerm, permissionType}) => {
        return (
            <Fragment>
                {normalPerm.permissions
                    ?.filter(permission => permission.permissionType === permissionType)
                    ?.map((permission, permissionIndex) => <Checkbox key={permissionIndex} checked={permission.granted} onChange={e => setChecked(permission.userGroupId, e.checked)}/>)}
            </Fragment>
        );
    }
    const renderScreen = () => {
        return (
            <div className="grid">
                <div className="col-12">
                    <div className="p-datatable p-component p-datatable-gridlines p-datatable-sm">
                        <div className="p-datatable-wrapper">
                            <table className="p-datatable-table">
                                <thead className="p-datatable-thead">
                                <tr>
                                    <th className="text-center" style={{width: '45px'}}>#</th>
                                    <th>Màn hình</th>
                                    <th className="text-center" style={{width: '90px'}}>Tạo mới</th>
                                    <th className="text-center" style={{width: '90px'}}>Xóa</th>
                                    <th className="text-center" style={{width: '90px'}}>Sửa</th>
                                    <th className="text-center" style={{width: '90px'}}>Xem</th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th className="text-center"><Checkbox checked={checkAll.CREATE} onChange={e => onCheckAllChange('CREATE', e.checked)}/></th>
                                    <th className="text-center"><Checkbox checked={checkAll.DELETE} onChange={e => onCheckAllChange('DELETE', e.checked)}/></th>
                                    <th className="text-center"><Checkbox checked={checkAll.UPDATE} onChange={e => onCheckAllChange('UPDATE', e.checked)}/></th>
                                    <th className="text-center"><Checkbox checked={checkAll.VIEW} onChange={e => onCheckAllChange('VIEW', e.checked)}/></th>
                                </tr>
                                </thead>
                                <tbody className="p-datatable-tbody">
                                {normalPerms.map((normalPerm, permissionIndex) => <tr key={permissionIndex}>

                                    <td className="text-center">{FormatDisplay.number(permissionIndex + 1)}</td>
                                    <td>
                                        {normalPerm.artifacts.map((artifact, artifactIndex) => <p key={artifactIndex}>{artifact.artifactName}</p>)}
                                    </td>
                                    <td className="text-center">
                                        <Setter normalPerm={normalPerm} permissionType="CREATE"/>
                                    </td>
                                    <td className="text-center">
                                        <Setter normalPerm={normalPerm} permissionType="DELETE"/>
                                    </td>
                                    <td className="text-center">
                                        <Setter normalPerm={normalPerm} permissionType="UPDATE"/>
                                    </td>
                                    <td className="text-center">
                                        <Setter normalPerm={normalPerm} permissionType="VIEW"/>
                                    </td>
                                </tr>)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const onSubmit = () => {
        if (!submitting) {
            setSubmitting(true);
            const permissions = normalPerms.reduce((accumulator, currentValue) => [...accumulator, ...currentValue.permissions], []);
            let x = {userGroups: permissions.map(item=>{
                    return item})}
            Service.setPermissions(partyId, x).then(() => {
                ToastService.success();
                setDisplay(false);
            }).finally(() => setSubmitting(false));
        }
    }

    const footer = (
        <div>
            <Button label="Lưu" icon="pi pi-check" size="small" onClick={onSubmit} loading={submitting}/>
            <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(false)}/>
        </div>
    );

    return {renderScreen, footer};
}