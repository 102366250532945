import {EntityService} from '../../service/EntityService';

export class CustomerService extends EntityService {

  static entity = 'vinca-party/customers';

  static find(term: string): Promise<any> {
    return this.axios.get(`${this.entity}/find`, {params: {term}}).then(res => res.data);
  }

  // static getByIdentificationType(identificationTypeEnumId: string, idValue: string): Promise<any> {
  //   return this.axios.get(`${this.entity}/by-identification-type`, {params: {identificationTypeEnumId, idValue}}).then(res => res.data);
  // }

  static getCertificates(partyId: string, params: any): Promise<any> {
    return this.axios.get(`${this.entity}/${partyId}/certificateSn`, {params}).then(res => res.data);
  }

  static getAccountList(lazyLoadEvent: string, disabled: string): Promise<any> {
    return this.axios.get(`${this.entity}/accounts/list?lazyLoadEvent=${lazyLoadEvent}`,{params: {disabled}}).then(res => res.data);
  }

  static resetPassword(userId: string, data: any): Promise<any> {
    return this.axios.put(`${this.entity}/accounts/${userId}/reset-password`, data).then(res => res.data);
  }

  static disableAccount(id: string): Promise<any> {
    return this.axios.put(`${this.entity}/accounts/${id}/lock`).then(res => res.data);
  }
  static enableAccount(id: string): Promise<any> {
    return this.axios.put(`${this.entity}/accounts/${id}/unlock`).then(res => res.data);
  }

  static updateInfo(partyId: string, data: any): Promise<any> {
    return this.axios.put(`${this.entity}/${partyId}/update-info`, data).then(res => res.data);
  }

}
