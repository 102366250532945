import {Service} from '../../service/Service';

export class LcsAuthService extends Service {

  static entity = 'vinca-auth/third-party/lcs-auth';

  static get(): Promise<any> {
    return this.axios.get(`${this.entity}`).then(res => res.data);
  }

  static set(data: any): Promise<any> {
    return this.axios.post(`${this.entity}`, data).then(res => res.data);
  }
  static setVininvoice(data: any): Promise<any> {
    return this.axios.post(`vinca-auth/third-party/vininvoice-auth`, data).then(res => res.data);
  }
  static getVininvoice(): Promise<any> {
    return this.axios.get(`vinca-auth/third-party/vininvoice-auth`).then(res => res.data);
  }

}