import {useDataTable, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';
import {Button} from 'primereact/button';

import React, {Fragment, useEffect, useState} from "react";

import {MessageService as Service} from "../MessageService";
import {CrupCertList} from "./CertList"
import {ConfigHistory} from "./ConfigHistory";

export const TinNhan = () => {
  const dataKey = 'workEffortId'
  const [timePeriod, setTimePeriod] = useState([]);
  const [description, setDescription] = useState(null)
  const [config, setConfig] = useState(null)
  const [displayConfigHistory, setDisplayConfigHistory] = useState(null)
  const [displayCertList, setDisplayCertList] = useState(null)
  const historyTooltip = 'Lịch sử cấu hình';


  useEffect(() => {
    Service.GetTimePeriod().then(data => {
      setTimePeriod(data.listData.map(item => {
        return {value: item.uomId, label: item.description}
      }));
    });
  }, []);


  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    columns: [
      {field: 'workEffortName', header: 'Mô tả', width: 150, matchMode: 'contains'},
      {field: 'configDesc', header: 'Cấu hình', width: 150, matchMode: 'contains'},
      {field: 'estimatedCost', header: 'Cần gửi', width: 150, matchMode: 'contains', dataType: 'number'},
      {field: 'actualCost', header: 'Đã gửi', width: 150, matchMode: 'contains', dataType: 'number'},
      {field: 'entryDate', header: 'Ngày tạo', width: 150, dataType: 'date'},
    ],
    indexColumnWidth: 45,
    getList: lazyLoadEvent => {
      return Service.getMessageList(lazyLoadEvent);
    }
  });
  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const {render: renderConfig, create: updateInfo} = useDialogCrup({
    header: '!Thông tin cấu hình',
    dataKey: 'requestId',
    width: '40rem',
    fields: [
      {field: 'expireBeforeTimePeriod', header: 'Khoảng thời gian', required: true, className: 'md:col-12'},
      {field: 'timePeriodUomId', header: 'Đơn vị thời gian', required: true, type: 'Dropdown', DropdownProps: {options: timePeriod}, className: 'md:col-12'},
    ],
    createItem: item => {
      setConfig(item)
      return Service.updateConfig(item);
    },
    reloadLazyData
  });
  useEffect(() => {
    Service.configDetail().then(data => {
      if (!data.description) {
        setDescription('Hiện tại chưa có cấu hình thời gian')
      } else {
        setDescription(data.description)
      }
    });
  }, [config]);

  const doConfig = () => {
    Service.configDetail().then(data => {
      updateInfo(data);
    });
    reloadLazyData()
  }



  const {renderToolbar} = useToolbar({
    leftButtons: <Fragment>
      <Button label="Cấu hình" severity="warning" icon="pi pi-cog" size="small" onClick={doConfig}/>
      <Button label="Gửi tin nhắn" severity="info" size="small" icon="pi pi-send" onClick={()=>setDisplayCertList(selectedItem)} disabled={!selectedItem}/>
    </Fragment>
  });

  return (
    <div className="grid">
      <div className="col-12">
        {renderToolbar()}
      </div>
      <div className="col-12">
        Cấu hình hiện tại:<b className='mr-3'><i>{description}</i></b><Button tooltip={historyTooltip} severity='secondary'  icon='pi pi-history' size="small" onClick={setDisplayConfigHistory}/>

      </div>

      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 9rem)'}}>
        {renderDataTable()}
        {renderConfig()}
        <ConfigHistory display={displayConfigHistory} setDisplay={setDisplayConfigHistory}/>
        <CrupCertList display={displayCertList} setDisplay={setDisplayCertList}></CrupCertList>
      </div>
    </div>
  );
}