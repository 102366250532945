import {EntityService} from '../../../service/EntityService';

export class AgentService extends EntityService {

  static entity = 'vinca-party/agents';

  static getTreeList(includeAll?: string): Promise<any> {
    return this.axios.get(`${this.entity}/tree-list`, {params: {includeAll}}).then(res => res.data);
  }

}
