import {EntityService} from '../../../service/EntityService';

export class InvoiceService extends EntityService {

  static entity = 'vinca-invoice/invoices';

  static paid(id: string, data: any): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/paid`, data).then(res => res.data);
  }

  static cancel(id: string, data: any): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/cancel`, data).then(res => res.data);
  }
  static calculate(id: string): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/calculate-discount`).then(res => res.data);
  }

  static getOutstandingDebt(lazyLoadEvent: string, params?: any): Promise<any> {
    return this.axios.get(`${this.entity}/outstanding-debt?lazyLoadEvent=${lazyLoadEvent}`, {params}).then(res => res.data);
  }


}