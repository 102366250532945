import {Route, Routes} from 'react-router-dom';

import {DanhSach} from './danh-sach';
import {ChinhSachGia} from './chinh-sach-gia';
import {QuanLyTaiKhoan} from './quan-ly-tai-khoan';

export const DaiLy = ({me}) => {

  return (
    <Routes>
      <Route path="" element={<DanhSach me={me}/>}/>
      <Route path="tao-moi" element={<DanhSach beCreate={true} me={me}/>}/>
      <Route path="chinh-sach-gia" element={<ChinhSachGia me={me}/>}/>
      <Route path="quan-ly-tai-khoan" element={<QuanLyTaiKhoan/>}/>
    </Routes>
  );
}