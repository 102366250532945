import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Column} from 'primereact/column';
import {TreeTable} from 'primereact/treetable';

import {useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {AgentService as Service} from './AgentService';
import {PartyService} from '../../../service/PartyService';
import {CommonService} from '../../../service/CommonService';
import {IamPermission} from "../../../shared/utils/Permission";

interface Props {
  me: any
  beCreate?: boolean
}

export const DanhSach = (props: Props) => {

  const [nodes, setNodes] = useState([]);
  const [selectedNodeKey, setSelectedNodeKey] = useState(null);

  const [partyTypes, setPartyTypes] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [counties, setCounties] = useState([]);
  const [parents, setParents] = useState([]);

  useEffect(() => {
    CommonService.getPartyTypes().then(data => {
      setPartyTypes(data.listData.map(item => {
        return {value: item.enumId, label: item.description}
      }));
    });
    CommonService.getProvinces().then(data => {
      setProvinces(data.listData.map(item => {
        return {value: item.toGeoId, label: item.geoName}
      }));
    });

    load();
  }, []);

  useEffect(() => {
    if (props.beCreate && IamPermission.has('CREATE')) {
      create();
    }
  }, [props.beCreate]); // eslint-disable-line react-hooks/exhaustive-deps

  const load = () => {
    Service.getTreeList().then(data => {
      setNodes(data.orgTree);
    });
    Service.getTreeList('N').then(data => {
      const _parents = Service.dataTreeTableToTreeSelect(data.orgTree, data => {
        return `[${data.pseudoId}] - ${data.partyName}`;
      });
      setParents(_parents);
    });
  }

  const getByTaxCode = () => {
    const _value = form.getRawValue();
    if (_value.partyTaxId) {
      PartyService.getByTaxCode(_value.partyTaxId, 'Y').then(({data}) => {
        if (data?.partyName) {
          _value.partyName = data.partyName;
          _value.address1 = data.address1;
          _value.stateProvinceGeoId = data.stateProvinceGeoId;
          _value.countyGeoId = data.countyGeoId;
          form.setValue(_value);
        }
      });
    }
  }

  const {render: renderDialogCrup, create, view, update, form} = useDialogCrup({
    header: 'Đại lý',
    dataKey: 'partyId',
    width: '60rem',
    fields: [
      {field: 'partyTypeEnumId', header: 'Loại đại lý', required: true, type: 'Dropdown', DropdownProps: {options: partyTypes}, className: 'md:col-5'},
      {field: 'pseudoId', header: 'Mã đại lý', required: true, InputTextProps: {maxLength: 50}, className: 'md:col-7'},
      {field: 'partyTaxId', header: 'Mã số thuế', InputTextProps: {maxLength: 15, keyfilter: 'int'}, className: 'md:col-4'},
      {field: 'getByTaxCode', type: 'custom', body: <Button icon="pi pi-search" rounded severity="success" onClick={getByTaxCode}/>, className: 'md:col-1 pt-4 pl-0'},
      {field: 'partyName', header: 'Tên đại lý', required: true, InputTextProps: {maxLength: 500}, className: 'md:col-7'},
      {field: 'address1', header: 'Địa chỉ', required: true, InputTextProps: {maxLength: 500}, className: 'md:col-12'},
      {field: 'stateProvinceGeoId', header: 'Tỉnh/Thành phố', required: true, type: 'Dropdown', DropdownProps: {options: provinces, filter: true}, className: 'md:col-6'},
      {field: 'countyGeoId', header: 'Quận/Huyện', required: true, type: 'Dropdown', DropdownProps: {options: counties, filter: true}, className: 'md:col-6'},
      {field: 'phoneNumber', header: 'Số điện thoại', InputTextProps: {maxLength: 17, keyfilter: 'int'}, className: 'md:col-6'},
      {field: 'emailAddress', header: 'Email', required: true, InputTextProps: {maxLength: 50}, className: 'md:col-6'},
      {field: 'username', header: 'Tài khoản', required: true, InputTextProps: {maxLength: 50, keyfilter: /\S/}, className: 'md:col-6'},
      {field: 'useDefaultPassword', header: 'Dùng mật khẩu mặc định', type: 'Checkbox', className: 'md:col-6 pt-5'},
      {field: 'parentPartyId', header: 'Đại lý cha', type: 'TreeSelect', TreeSelectProps: {options: parents, filter: true}, className: 'md:col-12'},
      {field: 'representativeName', header: 'Người đại diện', InputTextProps: {maxLength: 500}, className: 'md:col-6'},
      {field: 'representativeGender', header: 'Giới tính', type: 'Dropdown', DropdownProps: {options: CommonService.Genders}, className: 'md:col-2'},
      {field: 'representativeBirthDate', header: 'Ngày sinh', type: 'Calendar', className: 'md:col-4'}
    ],
    createItem: item => {
      item.useDefaultPassword = item.useDefaultPassword ? 'Y' : 'N';
      return Service.create(item);
    },
    updateItem: (id, item) => {
      item.useDefaultPassword = item.useDefaultPassword ? 'Y' : 'N';
      return Service.update(id, item);
    },
    reloadLazyData: load
  });

  const {stateProvinceGeoId} = form.getRawValue();
  useEffect(() => {
    if (stateProvinceGeoId) {
      CommonService.getCounties(stateProvinceGeoId).then(data => {
        setCounties(data.listData.map(item => {
          return {value: item.toGeoId, label: item.geoName}
        }));
      });
    }
  }, [stateProvinceGeoId]);

  const doUpdate = () => {
    if (selectedNodeKey) {
      Service.get(selectedNodeKey).then(data => {
        if (data.representativeBirthDate) {
          data.representativeBirthDate = new Date(data.representativeBirthDate);
        }
        data.useDefaultPassword = false;
        update(data);
      });
    }
  }
  const doView = () => {
    if (selectedNodeKey) {
      Service.get(selectedNodeKey).then(data => {
        if (data.representativeBirthDate) {
          data.representativeBirthDate = new Date(data.representativeBirthDate);
        }
        view(data);
      });
    }
  }

  const downloadExcel = () => {
    window.open(`${Service.baseURL}/${Service.entity}/excel`);
  }

  const {renderToolbar} = useToolbar({
    doCreate: (IamPermission.has('CREATE') || ['Administrator', 'Agent'].includes(props.me.roleTypeId) ) && (() => create({parentPartyId: selectedNodeKey})),
    hasSelectedItem: selectedNodeKey,
    doView,
    doUpdate: (IamPermission.has('UPDATE') || ['Administrator', 'Agent'].includes(props.me.roleTypeId)) && doUpdate,
    leftButtons: <Fragment>
      <Button label="Xuất excel" icon="pi pi-file-excel" severity="help" size="small" onClick={downloadExcel}/>
    </Fragment>
  });

  return (
    <div className="grid">
      <div className="col-12">
        {renderToolbar()}
      </div>
      <div className="col-12 py-0" style={{maxHeight: ''}}>
        <TreeTable value={nodes} selectionMode="single" selectionKeys={selectedNodeKey} onSelectionChange={e => setSelectedNodeKey(e.value)}
                   scrollable scrollHeight="calc(100vh - 24.5rem)">
          <Column field="pseudoId" header="Mã đại lý" expander filter></Column>
          <Column field="partyName" header="Tên đại lý" filter></Column>
          <Column field="partyRank" header="Cấp đại lý"></Column>
          <Column field="debit" header="Công nợ"></Column>
        </TreeTable>
      </div>

      {renderDialogCrup()}

    </div>
  );
}