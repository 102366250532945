import {EntityService} from '../../service/EntityService';

export class InvoiceService extends EntityService {

  static entity = 'vinca-integrate/vininvoice';

  static getProducts(lazyLoadEvent: string, params?: any): Promise<any> {
    return this.axios.get(`${this.entity}/products?lazyLoadEvent=${lazyLoadEvent}`, {params}).then(res => res.data);
  }

  static getProvinces(): Promise<any> {
    return this.axios.get(`${this.entity}/geos/provinces`).then(res => res.data);
  }

  static getCounties(toGeoId: string): Promise<any> {
    return this.axios.get(`${this.entity}/geos/${toGeoId}/assocs`).then(res => res.data);
  }

  static getTaxAuthority(toGeoId: string): Promise<any> {
    return this.axios.get(`${this.entity}/geos/${toGeoId}/tax-authority`).then(res => res.data);
  }

  static getInfoByTaxCode(partyTaxId: string): Promise<any> {
    return this.axios.get(`${this.entity}/by-tax-code?partyTaxId=${partyTaxId}`).then(res => res.data);
  }

  static createAgreement(data: any): Promise<any> {
    return this.axios.post(`${this.entity}/requests/new-agreement`, data).then(res => res.data);
  }

  static customerList(): Promise<any> {
    return this.axios.get(`${this.entity}/customers/list`).then(res => res.data);
  }

  static renew(data: any): Promise<any> {
    return this.axios.post(`${this.entity}/requests/renew`, data).then(res => res.data);
  }

  static updateRequest(requestId: string, data: any): Promise<any> {
    return this.axios.put(`${this.entity}/requests/${requestId}/update`, data).then(res => res.data);
  }

  static requestList(lazyLoadEvent: string, params?: any): Promise<any> {
    return this.axios.get(`${this.entity}/requests/list?lazyLoadEvent=${lazyLoadEvent}`, {params}).then(res => res.data);
  }

  static requestDetail(requestId: string): Promise<any> {
    return this.axios.get(`${this.entity}/requests/${requestId}/detail`).then(res => res.data);
  }

  static acceptRequest(requestId: string): Promise<any> {
    return this.axios.put(`${this.entity}/requests/${requestId}/accept`).then(res => res.data);
  }

  static rejectRequest(requestId: string): Promise<any> {
    return this.axios.put(`${this.entity}/requests/${requestId}/reject`).then(res => res.data);
  }

  static setVininvoice(data: any): Promise<any> {
    return this.axios.post(`vinca-auth/third-party/vininvoice-auth`, data).then(res => res.data);
  }

  static certInvoiceList(status: string, lazyLoadEvent: string, params?: any): Promise<any> {
    return this.axios.get(`vinca-invoice/cert-invoices/list?status=${status}&lazyLoadEvent=${lazyLoadEvent}`, {params}).then(res => res.data);
  }

  static certSyncHeader(invoiceId: string): Promise<any> {
    return this.axios.get(`vinca-invoice/cert-invoices/sync-header-info?invoiceId=${invoiceId}`).then(res => res.data);
  }

  static getDetail(invoiceId: string): Promise<any> {
    return this.axios.get(`vinca-invoice/cert-invoices/${invoiceId}/detail`).then(res => res.data);
  }

  static getInvoiceFormList(): Promise<any> {
    return this.axios.get(`vinca-invoice/cert-invoices/hilo-invoice-form-serial`).then(res => res.data);
  }

  static getPaymentFormTypes(): Promise<any> {
    return this.axios.get(`vinca-common/payment-form-types`).then(res => res.data);
  }

  static getTaxRateTypes(): Promise<any> {
    return this.axios.get(`vinca-common/tax-rate-types`).then(res => res.data);
  }

  static invoiceUpdate(data: any): Promise<any> {
    return this.axios.put(`vinca-invoice/cert-invoices/update`, data).then(res => res.data);
  }
  static invoiceUpdateAndIssue(data: any): Promise<any> {
    return this.axios.put(`vinca-invoice/cert-invoices/update-and-issue`, data).then(res => res.data);
  }
  static invoiceIssue(data: any): Promise<any> {
    return this.axios.put(`vinca-invoice/cert-invoices/issue`, data).then(res => res.data);
  }

}
