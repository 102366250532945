import {Route, Routes} from 'react-router-dom';

import {Dashboard} from './dashboard';
import {ChungThuSo} from './chung-thu-so';
import {ChungTu} from './chung-tu';
import {DaiLy} from './dai-ly';
import {Issue} from './issue';
import {KhachHang} from './khach-hang';
import {NhanVien} from './nhan-vien'
import {KhuyenMai} from './khuyen-mai';
import {ThanhToan} from './thanh-toan';
import {DanhMuc} from './danh-muc';
import {HeThong} from './he-thong';
import {ThongTinTaiKhoan} from './thong-tin-tai-khoan';
import {HoaDonDienTu} from './hoa-don-dien-tu'
import {TinNhan} from "./canh-bao/tin-nhan";
import {QuanLyToken} from './quan-ly-token';

export const App = ({me}) => {

  return (
    <div className="card">
      <Routes>
        <Route path="chung-thu-so/*" element={<ChungThuSo me={me}/>}/>
        <Route path="quan-ly-token/*" element={<QuanLyToken/>}/>
        <Route path="hoa-don-dien-tu/*" element={<HoaDonDienTu me={{me}}/>}/>
        <Route path="chung-tu/*" element={<ChungTu/>}/>
        <Route path="dai-ly/*" element={<DaiLy me={me}/>}/>
        <Route path="issue/tao-moi" element={<Issue me={me} beCreate={true}/>}/>
        <Route path="issue/trang-thai/:status" element={<Issue me={me}/>}/>
        <Route path="issue/danh-sach" element={<Issue me={me}/>}/>
        <Route path="khach-hang/*" element={<KhachHang me={me}/>}/>
        <Route path="nhan-vien/*" element={<NhanVien/>}/>
        <Route path="khuyen-mai/tao-moi" element={<KhuyenMai me={me} beCreate={true}/>}/>
        <Route path="khuyen-mai/danh-sach" element={<KhuyenMai me={me}/>}/>
        <Route path="thanh-toan/*" element={<ThanhToan/>}/>
        <Route path="danh-muc/*" element={<DanhMuc/>}/>
        <Route path="he-thong/*" element={<HeThong/>}/>
        <Route path="canh-bao/*" element={<TinNhan/>}/>
        <Route path="thong-tin-tai-khoan" element={<ThongTinTaiKhoan/>}/>
        <Route path="*" element={<Dashboard me={me}/>}/>
      </Routes>
    </div>
  );
}