import {EntityService} from '../../../service/EntityService';

export class PricingRuleService extends EntityService {

  static entity = 'vinca-pricing-rule/agreements';

  static setStatus(id: string, statusId: string): Promise<any> {
    return this.axios.patch(`${this.entity}/${id}/status`, {statusId}).then(res => res.data);
  }

}
