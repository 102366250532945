import {Service} from './Service';

export class CommonService extends Service {

  static entity: string = 'vinca-common';

  static getMe(): Promise<any> {
    return this.axios.get(`${this.entity}/me`).then(res => res.data);
  }

  static getPartyTypes(): Promise<any> {
    return this.axios.get(`${this.entity}/party-type`).then(res => res.data);
  }

  static getProvinces(): Promise<any> {
    return this.axios.get(`${this.entity}/geos/provinces`).then(res => res.data);
  }

  static getCounties(stateProvinceGeoId: string): Promise<any> {
    return this.axios.get(`${this.entity}/geos/${stateProvinceGeoId}/assocs?toGeoTypeEnumId=GEOT_COUNTY`).then(res => res.data);
  }

  static getDigitalCertClassifications(): Promise<any> {
    return this.axios.get(`${this.entity}/digital-cert/classification`).then(res => res.data);
  }

  static getIdentifyTypes(partyClassificationId: string): Promise<any> {
    return this.axios.get(`${this.entity}/digital-cert/identify-type`, {params: {partyClassificationId}}).then(res => res.data);
  }

  static privilege(): Promise<any> {
    return this.axios.get(`${this.entity}/user-account/grant-privilege`).then(res => res.data);
  }

  static Genders = [
    {value: 'M', label: 'Nam'},
    {value: 'F', label: 'Nữ'}
  ];

}
